<app-background imgPath=" {{ bgUrl }}"></app-background>
<app-menu
  [logoPath]="'assets/img/bosch_logo.png'"
  [selectedProduct]="selectedProduct"
  [showBack]="true"
  [showShare]="!helperService.isMobile && !helperService.isTablet && !helperService.isTabletLandscape && !helperService.isTablet4k"
  [showHome]="true"
  [showSearch]="true"
></app-menu>
<ng-container *ngIf="!isLoading">
  <p *ngIf="helperService.isMobile && !helperService.isTablet" class="text-white text-small ml-4 mb-0">{{ selectedProduct.title }}</p>
  <app-product-menu
    [selectedProduct]="selectedProduct"
    [showMenu]="showMenuList"
    [bgNumber]="bgNumber"
    (selectedMenuEntryChanged)="updateSelectedMenuEntry($event)"
    (scrollToComponent)="scrollToComponent($event)"
  ></app-product-menu>
  <p
    *ngIf="(helperService.isMobile && helperService.isTablet) || !helperService.isMobile"
    [ngClass]="helperService.isTablet ? 'text-xs mb-4 mt-4 ml-8' : 'text-xl mb-8 mt-4 ml-16'"
    class="text-white"
  >
    {{ selectedProduct.title }}
  </p>
  <router-outlet *ngIf="!helperService.isMobileDevice"></router-outlet>

  <!--   On mobile mode all product components are displayed in one page  -->
  <div
    [ngClass]="helperService.isMobile ? 'gap-8 pb-20' : helperService.isTabletLandscape ? 'pb-40' : 'gap-16 pb-20'"
    class="flex flex-col"
    *ngIf="helperService.isMobileDevice"
  >
    <div *ngIf="!highlightOverlayIsOpen" #overview>
      <app-product-overview
        (hideComponentsWhenOverlayIsOpen)="hideWhenSallyOverlay()"
        (click)="closeMenuOnClick()"
        [bgUrl]="bgUrl"
      ></app-product-overview>
    </div>
    <div *ngIf="!sallyOverlayIsOpen && !highlightOverlayIsOpen" #details>
      <app-product-details (click)="closeMenuOnClick()"></app-product-details>
    </div>
    <div *ngIf="!sallyOverlayIsOpen" #highlights>
      <app-product-highlights (hideComponents)="hideWhenHighlightOverlay()" (click)="closeMenuOnClick()"></app-product-highlights>
    </div>
    <div *ngIf="!sallyOverlayIsOpen && !highlightOverlayIsOpen" #relatedProducts>
      <app-related-products (click)="closeMenuOnClick()"> </app-related-products>
    </div>
  </div>

  <!-- </ng-container> -->
</ng-container>
