import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IProductDetail } from 'src/app/models/product-details.model';
import { IProduct } from 'src/app/models/product.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  selectedProduct!: IProduct;
  detailsFiltered: IProductDetail[] = [];
  detailMobileVersion: IProductDetail[] = [];

  selectedCategory: string = '';
  toggledCategories: string[] = [];

  productSubscription!: Subscription;

  constructor(public helperService: HelperService) {}

  ngOnInit(): void {
    this.getProduct();
  }

  private getProduct() {
    this.productSubscription = this.helperService.prodSubject.subscribe((product: IProduct) => {
      this.selectedProduct = product;
      this.filterByCategory(this.selectedProduct.details[0].headline);
    });
  }

  /**
   * filters the array by the clicked headline and swaps out content shown
   * also handles the styling of the selected headline
   * @param headline clicked headline
   */
  filterByCategory(headline: string) {
    this.getCategories().forEach((el) => {
      document.getElementById(el)?.classList.remove('font-bold');
    });
    document.getElementById(headline)?.classList.add('font-bold');

    if (this.helperService.isMobile) this.toggleAccordion(headline);
    else this.detailsFiltered = this.selectedProduct.details.filter((el) => el.headline === headline);
  }

  detailsFilteredByCategory(category: string) {
    const detailsFilteredByCategory = (this.detailMobileVersion = this.selectedProduct.details.filter((el) => el.headline === category));
    return detailsFilteredByCategory;
  }

  toggleAccordion(toggledHeadline: string) {
    if (this.toggledCategories.includes(toggledHeadline)) this.toggledCategories = this.toggledCategories.filter((item) => item !== toggledHeadline);
    else this.toggledCategories.push(toggledHeadline);
  }

  /**
   * gets all unique categories for the list of details
   * @returns new array with only unique values filtered by key 'category'
   */
  getCategories(): string[] {
    const categoryTitles = this.selectedProduct.details.map((x) => x.headline);
    const uniqueCategories = [...new Set(categoryTitles)];
    return uniqueCategories;
  }

  ngOnDestroy() {
    if (this.productSubscription) this.productSubscription.unsubscribe();
  }
}
