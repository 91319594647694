import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductMenuEntry } from 'src/app/enums/product-menu-entries.model';
import { IProdApiResponse } from 'src/app/models/apiResponse.model';
import { IProduct } from 'src/app/models/product.model';
import { IVideo } from 'src/app/models/video.model';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss'],
})
export class ProductPageComponent {
  selectedMenuEntry: string = ProductMenuEntry.OVERVIEW;
  menuEntries = ProductMenuEntry;
  selectedEan: string = '';
  selectedProduct!: IProduct;
  isLoading: boolean = false;
  bgUrl: string = '';
  videos: IVideo[] = [];
  prodSub!: Subscription;
  showMenuList!: boolean;
  bgNumber!: string;
  sallyOverlayIsOpen = false;
  highlightOverlayIsOpen = false;

  productSub!: Subscription;

  @ViewChild('overview') overview!: ElementRef;
  @ViewChild('highlights') highlights!: ElementRef;
  @ViewChild('details') details!: ElementRef;
  @ViewChild('relatedProducts') relatedProducts!: ElementRef;

  constructor(private apiService: ApiService, public helperService: HelperService, public activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.selectedEan = params.get('ean')!;
      this.bgNumber = params.get('bg')!;

      this.bgUrl = `assets/img/background_images/category${this.bgNumber}.jpg`;
      this.helperService.bgUrl = `assets/img/background_images/category${this.bgNumber}.jpg`;

      this.checkIfProductisFetched();
    });
  }

  checkIfProductisFetched() {
    this.productSub = this.helperService.prodSubject.subscribe((product) => {
      if (product?.ean === this.selectedEan) {
        this.selectedProduct = product;
      } else {
        this.getProduct();
      }
    });
  }

  getProduct() {
    this.isLoading = true;
    this.prodSub = this.apiService.getProduct('', this.selectedEan).subscribe((res: IProdApiResponse) => {
      this.isLoading = false;
      this.selectedProduct = res.data;
      this.beautifyProductTitle();
      this.productSub.unsubscribe();
      this.helperService.prodSubject.next(this.selectedProduct);
    });
  }

  getSallysHints() {}

  beautifyProductTitle() {
    // product title always contains vib no. as well (redundant information)
    const titleElements = this.selectedProduct.title.split(' ');
    // remove last entry and create string afterwards
    titleElements.pop();
    let title = titleElements.join(' ');
    this.selectedProduct.title = title;
    return this.selectedProduct;
  }

  scrollToComponent(path: string) {
    const route = path.split('/');
    const subpage = route[route.length - 1];
    if (subpage === 'ueberblick') {
      this.overview.nativeElement.scrollIntoView({ behavior: 'smooth' });
    } else if (subpage === 'highlights') {
      this.highlights.nativeElement.scrollIntoView({ behavior: 'smooth' });
    } else if (subpage === 'details') {
      this.details.nativeElement.scrollIntoView({ behavior: 'smooth' });
    } else if (subpage === 'passende-produkte') {
      this.relatedProducts.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  hideWhenSallyOverlay() {
    this.sallyOverlayIsOpen = !this.sallyOverlayIsOpen;
  }

  hideWhenHighlightOverlay() {
    this.highlightOverlayIsOpen = !this.highlightOverlayIsOpen;
  }

  updateSelectedMenuEntry(selectedMenuEntry: string) {
    this.selectedMenuEntry = selectedMenuEntry;
  }

  closeMenuOnClick() {
    this.showMenuList = !this.showMenuList;
  }

  ngOnDestroy() {
    if (this.prodSub) this.prodSub.unsubscribe();
    if (this.productSub) this.productSub.unsubscribe();
  }
}
