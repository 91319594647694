import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(public helperService: HelperService) {}

  ngOnInit(): void {
    // clear local storage to delete the cat filter history but keeps cookie-collection status
    const cookieStatus = localStorage.getItem('cookieStatus');
    localStorage.clear();
    if (cookieStatus && cookieStatus === 'allow') this.helperService.setCookieStatus(true);
    else this.helperService.setCookieStatus(false);
  }

  getBackgroundImageUrl(): string {
    if (this.helperService.isMobile) return 'assets/img/background_images_low_res/category2';
    else return 'assets/img/background_images/home';
  }
}
