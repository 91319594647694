import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  checkNetworkInterval: any;

  public _networkState = new BehaviorSubject<boolean>(true);
  public networkState: Observable<boolean> = this._networkState.asObservable();

  constructor(private httpService: HttpClient) {}

  setNetworkState(state: boolean): void {
    this._networkState.next(state);
  }

  setCheckInterval() {
    if (!this.checkNetworkInterval) {
      // starts timer (15s) to check internet connectivity
      this.checkNetworkInterval = setInterval(async () => {
        this.checkInternetConnection();
      }, 15000);
    }
  }

  private checkInternetConnection() {
    // tries to fetch a placeholder json api that is designed for testing purposes online
    this.httpService.get(environment.inetCheckUrl, { responseType: 'json' }).subscribe({
      next: () => {
        // if there is a response from the test api, PoS has internet connection
        this._networkState.next(true);
        // stop interval once there is internet connection and reset interval
        clearInterval(this.checkNetworkInterval);
        this.checkNetworkInterval = null;
      },
    });
  }
}
