import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ICatApiResponse, IHighlightsApiResponse } from 'src/app/models/apiResponse.model';
import { ICategory } from 'src/app/models/category.model';
import { ICatFilter } from 'src/app/models/catFilter.model';
import { IHighlights } from 'src/app/models/highlights.model';
import { ISallyHint } from 'src/app/models/sally-hint.model';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { MockdataService } from 'src/app/services/mockdata.service';
// import { SpecialSliderComponent } from 'src/app/components/special-slider/special-slider.component';

interface ICatHelper {
  idx: number;
  category: ICategory;
  isSelectable: boolean;
}

@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss'],
})
export class CategoryFilterComponent implements OnInit {
  catData!: ICatFilter;
  menuBackSub: Subscription;
  highlightsSub!: Subscription;
  alignRight: boolean = false;
  endpoint?: string;
  isLoading: boolean = false;
  featuresLoading: boolean = true;
  allFeatures: IHighlights[] = [];
  apiFilterIdArray: string[] = [];
  apiFilterTitleArray: string[] = [];
  productsLength!: number;
  inputBlocked: boolean = false;
  filteredForOverlay: IHighlights[] = [];

  sallysHints?: ISallyHint[];
  sallysHint?: ISallyHint | undefined;
  showSallysHint: boolean = false;
  showSallyOverlay: boolean = false;

  titles: string[] = [];

  // a single highlight from all highlights to fill the slider if there are only 2 highlights left after filtering
  gapCloserFeature: IHighlights = {} as IHighlights;

  // @ViewChild(SpecialSliderComponent)
  // private SpecialSlider!: SpecialSliderComponent;

  // helper structure for category layout (2er and 3er rows)
  catLayout: Map<number, ICatHelper[]> = new Map<number, ICatHelper[]>();

  constructor(
    private router: Router,
    public helperService: HelperService,
    private location: Location,
    private apiService: ApiService,
    private mockService: MockdataService,
  ) {
    if (history.state.catData === undefined && this.helperService.getLastCatState().title === '') {
      this.router.navigateByUrl('/unsere-produkte');
    } else {
      if (!history.state.catData) {
        this.catData = this.helperService.getLastCatState();
        this.alignRight = this.catData.alignRight!;
      } else if (history.state.catData.alignRight) this.alignRight = history.state.catData.alignRight;

      // get transmitted category filter data
      if (this.helperService.getLastCatState().categories.length > 0) this.catData = this.helperService.getLastCatState();
      else this.catData = history.state.catData;

      // create rows and columns layout
      this.getCatColLayout();
    }

    // listen to back clicks to update the category filter data
    this.menuBackSub = this.helperService.catBackClick.subscribe(() => {
      if (this.helperService.getLastCatState().curCatLevel === 1 || this.helperService.getLastCatState().curCatLevel === 0) {
        // go back to product world page if user clicks "back" on penultimate (vorletztem) level
        this.location.back();
      } else {
        // otherwise just update category filter data
        this.getPreviousCatData();
      }
    });
  }

  ngOnInit(): void {
    this.getSallysHints();
    // only gets length and features where features can be selected
    if (this.catData.childCat[0].hasCheckbox) {
      this.getResultsLength();
      this.getFeatures();
    }
    // Fills titles array with title that was clicked on product world page
    this.titles.push(this.helperService.getLastCatTitile());
  }

  getSallysHints() {
    this.mockService.getSallysHints().then((res) => {
      this.sallysHints = res['sallys-hints'];
      this.getSallysHintsTitle(this.catData.title);
    });
  }

  getFeatures() {
    this.isLoading = true;
    if (this.catData.apiCatTitle)
      this.apiService.getHighlightsOfProductGroup(this.catData.apiCatTitle).subscribe((res: IHighlightsApiResponse) => {
        this.allFeatures = res.data;
        // defines a random gap closer feature for FREEZERS since there are only 2 selectable features
        this.gapCloserFeature = this.allFeatures[1];
        // removes unneccessary features for our Slider
        this.findFeaturesOfCategories();
        // filters out features with unsufficcient assets ( for example feature-icon )
        this.allFeatures = this.filterValidFeatures();
        if (this.allFeatures.length < 3) this.allFeatures.push(this.gapCloserFeature);
        // reset all unselectable categories
        this.searchForSelectableFeatures(this.allFeatures);
        this.setSelectableState(true);
        this.isLoading = false;
        this.featuresLoading = false;
        this.inputBlocked = false;
      });
  }

  findFeaturesOfCategories() {
    let filterIdArr: string[] = [];
    let filterTitleArr: string[] = [];
    this.catData.childCat.forEach((e) => {
      // fills the filter arrays with every childCat which has an endpointFilterId
      if (e.endpointFilterId) filterIdArr.push(e.endpointFilterId);
      if (e.endpointFilterTitle) filterTitleArr.push(e.endpointFilterTitle);
    });
    let newArr: IHighlights[] = [];
    filterTitleArr.forEach((e) => {
      let highlight = this.allFeatures.find((x) => {
        if (x.title) x.title.includes(e);
      });
      if (highlight) newArr.push(highlight);
    });
    // filters out all features except the ones which have the same Id / Title
    // as our clickable category checkboxes
    this.allFeatures = this.allFeatures.filter((e) => filterIdArr.includes(e.id.toString()));
    // combine the 2 filtered arrays to get all features into the slider
    this.allFeatures = [...this.allFeatures, ...newArr];
    this.filteredForOverlay = [...this.allFeatures];
  }

  filterValidFeatures() {
    // gets alls highlights that contains either a video or a relevant image
    return this.allFeatures.filter((e) => {
      return (
        e.assets.filter(
          (v) =>
            v.category.includes('illustration-drawing') ||
            v.category.includes('feature-video') ||
            v.category.includes('category.detail') ||
            v.category.includes('food.mood.styling') ||
            v.category.includes('product-image') ||
            v.category.includes('product-video'),
        ).length > 0
      );
    });
  }

  getCatColLayout() {
    // clear previous layout
    this.catLayout = new Map<number, ICatHelper[]>();
    // at first get the amount of 2er and 3er columns
    const twoColAmount = this.getTwoCols();
    const threeColAmount = this.getThreeCols(twoColAmount);
    // then build the helper structure by adding available categories row by row starting with 3er rows
    let rowCount = 0;
    let catIdx = 0;
    for (let i = 0; i < threeColAmount; i++) {
      // add three categories to row
      let tempCatArr: ICatHelper[] = [];
      for (let j = 0; j < 3; j++) {
        tempCatArr.push({ idx: catIdx, category: this.catData.childCat[catIdx], isSelectable: true });
        catIdx++;
      }
      this.catLayout.set(rowCount, tempCatArr);
      rowCount++;
    }
    // then go on with the 2er rows (same logic but only two categories per row)
    for (let i = 0; i < twoColAmount; i++) {
      // add two categories to row
      let tempCatArr: ICatHelper[] = [];
      for (let j = 0; j < 2; j++) {
        tempCatArr.push({ idx: catIdx, category: this.catData.childCat[catIdx], isSelectable: true });
        catIdx++;
      }
      this.catLayout.set(rowCount, tempCatArr);
      rowCount++;
    }
  }

  getTwoCols() {
    // gets amount of 2er columns by using modulo operator and following logic
    const temp = this.catData.childCat.length % 3;
    if (temp === 1) return 2;
    else if (temp === 2) return 1;
    else return 0;
  }

  getThreeCols(twoColAmount: number) {
    // gets amount of 3er colums by subtracting 2er column amount first and then rounding up after dividing by 3
    return Math.floor((this.catData.childCat.length - twoColAmount) / 3);
  }

  getPreviousCatData() {
    this.sallysHint = undefined;
    //reset all checked catboxes when going back
    this.resetCategoriesAndArrays();
    const prevCat: ICategory = this.catData.categories.filter((x) => x.id === this.catData.lastParentId)[0];
    // get previous child array
    let prevChilds: ICategory[] = this.catData.categories.filter((x) => x.parentId === prevCat.parentId);
    // removes previous title of array to display last one
    this.titles.pop();
    // decrease last level count and update cat filter data
    this.catData = {
      categories: this.catData.categories,
      childCat: prevChilds,
      lastParentId: prevCat.parentId,
      curCatLevel: --this.catData.curCatLevel,
      colAmount: 3,
      showVideo: prevChilds.filter((x) => x.showVideo).length > 0 ? true : false,
      showResultBtn: prevChilds[0].showChoiceBadge,
      showSally: false,
      bgUrl: this.getBackgroundUrlOnBack(prevChilds[0]),
      mobileImageUrl: this.getMobileImageOnBack(prevChilds[0]),
      apiCatTitle: this.catData.apiCatTitle,
      title: this.titles[this.titles.length - 1],
    };

    //update layout as well
    this.getCatColLayout();
    // update cat filter data at local storage
    this.helperService.updateLastCatState(this.catData);
  }

  resetCategoriesAndArrays() {
    this.catData.childCat.forEach((e) => (e.isChecked = false));
    this.catLayout.forEach((mapEntry: ICatHelper[]) =>
      mapEntry.forEach((e) => {
        e.isSelectable = true;
      }),
    );
    this.apiFilterIdArray = [];
    this.apiFilterTitleArray = [];
  }

  getNextCategories(cat: ICategory, childs: ICategory[]) {
    // pushes current title to array
    this.titles.push(cat.title);
    // get child categories of clicked category
    const catFilter = {
      categories: this.catData.categories,
      childCat: childs,
      lastParentId: cat.id,
      curCatLevel: ++this.catData.curCatLevel,
      colAmount: childs.length > 2 ? 3 : 2,
      showVideo: childs.filter((x) => x.showVideo).length > 0 ? true : false,
      showResultBtn: childs[0].showChoiceBadge,
      showSally: false,
      bgUrl: this.getBackgroundUrl(cat),
      mobileImageUrl: this.getMobileUrl(cat),
      apiCatTitle: cat.apiCatTitle,
      title: this.titles[this.titles.length - 1],
    };
    // update cat filter data and set new values to determine previously used category
    this.catData = catFilter;

    if (this.catData.childCat[0].hasCheckbox) this.getFeatures();
    this.helperService.updateLastCatState(this.catData);
  }

  onCategoryClick(cat: ICategory) {
    if (!this.inputBlocked) {
      if (cat.hasCheckbox) {
        this.checkCategory(cat);
      } else {
        // get child categories of clicked category
        const childs = this.catData.categories.filter((x) => x.parentId === cat.id);
        // if there are any childs, update view (no page refresh)
        if (childs.length) {
          this.getSallysHintsTitle(cat.title);
          this.getNextCategories(cat, childs);
          this.getCatColLayout();
        } else {
          this.goToResultPage(cat);
          return;
        }
      }
      this.getResultsLength();
    }
  }

  getSallysHintsTitle(title: string) {
    if (this.sallysHints && title === 'Kochen und Backen mit Dampf') {
      this.showSallysHint = true;
      this.sallysHint = this.sallysHints.find((e) => e.vibs === title);
    } else if (this.sallysHints && title === 'Konventionelle Backöfen') {
      this.showSallysHint = true;
      this.sallysHint = this.sallysHints.find((e) => e.vibs === title);
    }
  }

  openSallyOverlay() {
    this.showSallyOverlay = true;
    this.helperService.updatedBackgroundBlur(true);
  }

  closeSallyOverlay(): void {
    this.showSallyOverlay = false;
    this.helperService.updatedBackgroundBlur(false);
  }

  checkCategory(cat: ICategory) {
    // prevents fast clicking of several categories before the api response is received
    this.inputBlocked = true;
    this.isLoading = true;
    cat.isChecked = !cat.isChecked;
    if (cat.isChecked && cat.endpointFilterId) {
      this.apiFilterIdArray.push(cat.endpointFilterId);
      // setTimeout(() => {
      //   this.SpecialSlider.goToSlide(cat);
      // }, 100);
    } else if (cat.isChecked && cat.endpointFilterTitle) {
      this.apiFilterTitleArray.push(cat.endpointFilterTitle);
      // setTimeout(() => {
      //   this.SpecialSlider.goToSlide(cat);
      // }, 100);
    } else if (cat.endpointFilterId) this.apiFilterIdArray.splice(this.apiFilterIdArray.indexOf(cat.endpointFilterId), 1);
    else if (cat.endpointFilterTitle) this.apiFilterTitleArray.splice(this.apiFilterIdArray.indexOf(cat.endpointFilterTitle), 1);
    // at the end filter available features
    this.getFeaturesFiltered();
  }

  getFeaturesFiltered() {
    // since not every category has a clear filterId we need to use titles aswell, we need to check
    // if there is a title, an id, both or none and make an API call depending on the given information
    // any of those function returns an array of features, we need this array to search it with our
    // categories which are not yet selected if the not yet selected category is not in this list,
    // clicking this category would NOT return a valid product => that needs to be greyed out in advance
    if (this.apiFilterIdArray.length > 0 && this.apiFilterTitleArray.length === 0) this.getFeaturesById();
    else if (this.apiFilterIdArray.length === 0 && this.apiFilterTitleArray.length > 0) this.getFeaturesByTitle();
    else if (this.apiFilterIdArray.length > 0 && this.apiFilterTitleArray.length > 0) this.getFeaturesByIdAndTitle();
    else this.getFeatures();
  }

  getFeaturesById() {
    if (this.catData.apiCatTitle)
      this.apiService.getHighlightsById(this.catData.apiCatTitle, this.apiFilterIdArray.join(',')).subscribe((res) => {
        let filterArray: IHighlights[] = res.data;
        this.searchForSelectableFeatures(filterArray);
        this.isLoading = false;
        this.inputBlocked = false;
      });
  }

  getFeaturesByTitle() {
    if (this.catData.apiCatTitle)
      this.apiService.getHighlightsByTitle(this.catData.apiCatTitle, this.apiFilterTitleArray.join(',^')).subscribe((res) => {
        let filterArray: IHighlights[] = res.data;
        this.searchForSelectableFeatures(filterArray);
        this.isLoading = false;
        this.inputBlocked = false;
      });
  }

  getFeaturesByIdAndTitle() {
    if (this.catData.apiCatTitle)
      this.apiService
        .getHighlightsByIdAndTitle(this.catData.apiCatTitle, this.apiFilterIdArray.join(','), this.apiFilterTitleArray.join(',^'))
        .subscribe((res) => {
          let filterArray: IHighlights[] = res.data;
          this.searchForSelectableFeatures(filterArray);
          this.isLoading = false;
          this.inputBlocked = false;
        });
  }

  searchForSelectableFeatures(arr: IHighlights[]) {
    // uses the helper map which gets displayed in the html view
    this.catLayout.forEach((mapEntry: ICatHelper[]) => {
      mapEntry.forEach((cat: ICatHelper) => {
        // look for displayed categories that still can be clicked (if id is matching in remaining highlights)
        if (cat.category.isChecked) {
          cat.isSelectable = true;
        } else if (arr.find((x) => x.id === +cat.category?.endpointFilterId! || cat.category.endpointFilterTitle?.includes(x.title))) {
          cat.isSelectable = true;
        } else {
          cat.isSelectable = false;
        }
      });
    });
  }

  getResultsLength() {
    // since not every category has a clear filterId we need to use titles aswell, we need to check
    // if there is a title, an id, both or none and make an API call depending on the given information
    // any of those 3 functions returns a single product to get the length out of the meta data from the response
    //this.isLoading = true;
    if (this.apiFilterIdArray.length > 0 && this.apiFilterTitleArray.length > 0) this.getLengthByIdAndTitle();
    else if (this.apiFilterIdArray.length <= 0 && this.apiFilterTitleArray.length > 0) this.getLengthByTitle();
    else this.getLengthById();
  }

  getLengthByIdAndTitle() {
    if (this.catData.apiCatTitle) {
      this.apiService
        .getProductsLengthByIdAndTitle(this.catData.apiCatTitle, this.apiFilterIdArray.join(','), this.apiFilterTitleArray.join(',^'))
        .subscribe((res: ICatApiResponse) => {
          this.productsLength = res.meta.total;
          //this.isLoading = false;
        });
    }
  }

  getLengthByTitle() {
    if (this.catData.apiCatTitle) {
      this.apiService.getProductsLengthByTitle(this.catData.apiCatTitle, this.apiFilterTitleArray.join(',^')).subscribe((res: ICatApiResponse) => {
        this.productsLength = res.meta.total;
        //this.isLoading = false;}
      });
    }
  }

  getLengthById() {
    if (this.catData.apiCatTitle) {
      this.apiService.getProductsLengthById(this.catData.apiCatTitle, this.apiFilterIdArray.join(',')).subscribe((res: ICatApiResponse) => {
        this.productsLength = res.meta.total;
        //this.isLoading = false;}
      });
    }
  }

  goToResultPage(cat: ICategory): void {
    const bgURL = this.helperService.getBgNumber(this.catData.bgUrl);
    this.router.navigateByUrl(`/suchergebnisse?endpoint=${cat.endpoint}&bgUrl=${bgURL}`);
  }

  onShowResultsClick() {
    if (this.productsLength > 0 && !this.isLoading) {
      const bgURL = this.helperService.getBgNumber(this.catData.bgUrl);
      this.router.navigateByUrl(
        `/suchergebnisse?title=${this.catData.apiCatTitle}&filterTitle=${this.apiFilterTitleArray.join(',^')}&filterId=${this.apiFilterIdArray.join(
          ',',
        )}&bgUrl=${bgURL}`,
      );
    }
  }

  areCategoriesCheckable() {
    if (this.catData.childCat.find((e) => e.hasCheckbox)) return true;
    else return false;
  }

  isFeatureSelected() {
    if (this.catData.childCat.find((e) => e.isChecked)) return true;
    else return false;
  }

  getBackgroundUrl(cat: ICategory): string {
    if (cat.bgUrl) return cat.bgUrl;
    else return 'assets/img/background_images/category' + cat.parentId;
  }

  private getMobileUrl(cat: ICategory): string {
    if (cat.mobileImageUrl) return cat.mobileImageUrl;
    else return 'assets/img/background_images/category' + cat.parentId + '-mobile-header.jpg';
  }

  getBackgroundUrlOnBack(cat: ICategory): string {
    if (cat.bgUrl) return cat.bgUrl;
    else return 'assets/img/background_images/category' + cat.parentId;
  }

  private getMobileImageOnBack(cat: ICategory) {
    if (cat.mobileImageUrl) return cat.mobileImageUrl;
    else return 'assets/img/background_images/category' + cat.parentId + '-mobile-header.jpg';
  }

  setSelectableState(state: boolean) {
    this.catLayout.forEach((mapEntry: ICatHelper[]) => {
      mapEntry.forEach((e: ICatHelper) => (e.isSelectable = state));
    });
  }

  getBackgroundImageUrl(): string {
    if (this.helperService.isMobile) return 'assets/img/background_images_low_res/category2';
    else return this.catData.bgUrl;
  }

  ngOnDestroy() {
    if (this.menuBackSub) this.menuBackSub.unsubscribe();
    if (this.highlightsSub) this.highlightsSub.unsubscribe();
  }
}
