<div
  class="related-products"
  [ngClass]="
    helperService.isLandscape
      ? 'pl-20 w-1/2 mt-0'
      : helperService.isMobile
      ? 'padding-mobile md:px-8'
      : helperService.isTablet
      ? 'px-12'
      : helperService.isTablet4k || helperService.isTabletLandscape
      ? 'px-16'
      : 'px-20 mt-4'
  "
>
  <h1
    class="text-white mb-8"
    [ngClass]="helperService.isMobile ? 'text-lg' : helperService.isTabletLandscape ? 'text-3xl' : ''"
    *ngIf="helperService.isMobileDevice"
  >
    Passende Produkte
  </h1>
  <!--   <p class="text-white text-xl" [ngClass]="helperService.isLandscape ? 'mb-4' : 'mb-8'">{{ selectedProduct.title }}</p> -->
  <app-related-products-slider [selectedProduct]="selectedProduct"></app-related-products-slider>
</div>
