<div
  class="background h-full w-full flex flex-col justify-center items-center text-white"
  [ngClass]="[
    helperService.isTablet || helperService.isTabletLandscape ? 'gap-6' : 'gap-4',
    (highlightsPage && helperService.isTablet) || helperService.isTabletLandscape
      ? 'p-16'
      : (highlightsPage && !helperService.isTablet) || helperService.isTabletLandscape
      ? 'p-8'
      : ''
  ]"
>
  <p *ngIf="!highlightsPage" [ngClass]="helperService.isTablet || helperService.isTabletLandscape ? '' : 'text-xs'" class="text-center">
    Damit Sie das Video laden können, müssen <br />
    Sie das Speichern notwendiger Cookies erlauben.
  </p>

  <p *ngIf="highlightsPage" [ngClass]="helperService.isTablet || helperService.isTabletLandscape ? '' : 'text-xs'" class="text-center">
    Leider können die Produkthighlights erst geladen werden, <br *ngIf="helperService.isTablet || helperService.isTabletLandscape" />
    wenn Sie das Speichern notwendiger Cookies erlauben.
  </p>
  <img
    *ngIf="!highlightsPage"
    [ngClass]="helperService.isTablet || helperService.isTabletLandscape ? 'h-16' : 'h-8'"
    src="assets/icon/videoPlay.png"
  />

  <button class="bg-btn-col-cookie font-bold py-2 px-4 rounded inline-flex items-center" (click)="openCookieSetting()">
    <span [ngClass]="helperService.isTablet || helperService.isTabletLandscape ? 'text-base' : 'text-xs'">Cookie-Einstellungen öffnen</span>
  </button>
  <a href="https://policies.google.com/privacy" target="_blank" [ngClass]="helperService.isTablet || helperService.isTabletLandscape ? '' : 'text-xs'"
    >Mehr erfahren!</a
  >
</div>
