<div [ngClass]="helperService.isMobile || helperService.isTabletLandscape ? 'full-height' : 'inset-0'" class="absolute background-blur z-10">
  <app-menu></app-menu>
  <div
    [ngClass]="
      helperService.isLandscape
        ? 'mt-16 px-16'
        : helperService.isMobile
        ? 'padding-mobile md:px-8 pb-mobile'
        : helperService.isTabletLandscape
        ? 'px-16 pb-8 mt-12'
        : 'mt-32 px-16'
    "
  >
    <div class="grid" [ngClass]="helperService.isLandscape ? 'grid-cols-12 gap-8' : 'grid-cols-1 '">
      <div
        class="bg-default-col-bg shadow-md"
        [ngClass]="helperService.isLandscape ? 'col-start-1 col-end-7' : helperService.isTabletLandscape ? 'w-3/4' : 'col-span-12'"
      >
        <img class="close-icon py-4" src="assets/icon/icon-close.png" (click)="onClickCloseVideoOverlay()" />
        <div class="aspect-w-16 aspect-h-9">
          <app-cookie-video-overlay *ngIf="!helperService.isCollectingCookies && helperService.isMobileDevice"></app-cookie-video-overlay>
          <iframe
            *ngIf="helperService.isCollectingCookies"
            [src]="helperService.getNoCookieUrl(selectedVideo.youtubeLink) | safe: 'resourceUrl'"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            sandbox="allow-scripts allow-presentation allow-same-origin"
          ></iframe>
        </div>
        <p
          *ngIf="!selectedVideo.title.includes('LikeABosch')"
          [ngClass]="helperService.isMobile ? 'py-0 px-0 mt-2.5 text-small' : 'py-4 px-6 text-sm'"
          class="text-white"
        >
          {{ selectedVideo.title }}
        </p>
        <div
          *ngIf="selectedVideo.title.includes('LikeABosch')"
          [ngClass]="helperService.isMobile ? 'py-0 px-0 mt-2.5' : 'py-2 px-4'"
          class="flex flex-wrap max-w text-sm bg-default-col-bg shadow-md text-white"
        >
          <p class="text-small">
            {{ selectedVideo.title | split: 'ABosch':0 }}A<strong>{{ selectedVideo.title | split: 'LikeA':1 }}</strong>
          </p>
        </div>
      </div>

      <!--       --- DESIGN FOR LIKE A BOSCH OVERLAY ---      -->
      <!--       --- DESIGN FOR LIKE A BOSCH OVERLAY ---      -->
      <!--       --- DESIGN FOR LIKE A BOSCH OVERLAY ---      -->
      <div
        *ngIf="router.url === '/like-a-bosch' && !helperService.isMobile && !helperService.isTablet"
        [ngClass]="helperService.isLandscape ? 'col-start-7 col-end-13' : ''"
      >
        <h3 [ngClass]="helperService.isMobile ? 'text-base' : 'text-2xl'" class="text-white mt-8">Das könnte dich auch interessieren:</h3>
        <div class="grid grid-cols-4 gap-4">
          <p class="col-start-1 pb-4 mt-4 mb-2 text-white font-bold">Videos:</p>
          <p *ngIf="!helperService.isMobile" class="col-start-2 pb-4 mt-4 mb-2 text-white font-bold">Produkte:</p>
        </div>

        <div [ngClass]="helperService.isMobile ? 'grid-cols-1' : 'grid-cols-4'" class="grid gap-4 auto-rows-fr">
          <div
            *ngFor="let video of getVideoListWithoutSelectedVideo(); let i = index"
            style="--animation-order:{{ i + 1 }}"
            class="grid-cols-1 col-start-1 grid-rows-2 gap-4"
          >
            <a class="fade-in-box max-hight" (click)="onVideoClick(video)">
              <img class="object-cover" [src]="video.thumbailLink" />
            </a>
          </div>

          <p *ngIf="helperService.isMobile" class="col-start-2 pb-4 mt-4 mb-2 text-white font-bold">Produkte:</p>
          <div [ngClass]="helperService.isMobile ? 'grid-cols-2' : 'grid-cols-3'" class="grid col-start-2 col-end-5 row-start-1 gap-4">
            <div
              (click)="getProductViaEndpoint('program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=DISHWASHERS&feature_ids=15929998')"
              style="--animation-order: 3"
              class="product-box fade-in-box max-hight"
            >
              <p
                class="px-4 py-5 text-white font-bold bg-default-col-bg geschirrspueler shadow-md"
                [ngClass]="helperService.isLandscape ? 'text-xl' : 'text-2xl'"
              >
                Die neuen PerfectDry Geschirrspüler.
              </p>
            </div>
            <div
              (click)="
                getProductViaEndpoint('program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=HOBS,VENTING_HOBS&feature_ids=15755744')
              "
              style="--animation-order: 5"
              class="product-box fade-in-box max-hight"
            >
              <p
                class="px-4 py-5 text-white font-bold bg-default-col-bg shadow-md glass-protect"
                [ngClass]="helperService.isLandscape ? 'text-xl' : 'text-2xl'"
              >
                GlassProtect. Noch kratzresistenter
              </p>
            </div>

            <div
              (click)="
                getProductViaEndpoint(
                  'program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=FRIDGE_FREEZER_COMBINATIONS,REFRIGERATORS,FREEZERS&feature_ids=10613883'
                )
              "
              style="--animation-order: 7"
              class="product-box fade-in-box max-hight"
            >
              <p
                class="px-4 py-5 text-white font-bold bg-default-col-bg vario-style shadow-md"
                [ngClass]="helperService.isLandscape ? 'text-2xl' : 'text-3xl'"
              >
                Vario Style
              </p>
            </div>
          </div>
          <div [ngClass]="helperService.isMobile ? 'grid-cols-2' : 'grid-cols-3'" class="grid col-start-2 col-end-5 row-start-2 gap-4">
            <div
              (click)="getProductViaEndpoint('program=accent | line,accent | line carbon black&group=HOODS&typology=TYPOLOGY.DOWH')"
              style="--animation-order: 4"
              class="product-box fade-in-box max-hight"
            >
              <p
                class="px-4 py-5 text-white font-bold bg-default-col-bg shadow-md glass-dunstabzug"
                [ngClass]="helperService.isLandscape ? 'text-xl' : 'text-2xl'"
              >
                Integrierter Glas Dunstabzug.
              </p>
            </div>

            <div
              (click)="getProductViaEndpoint('program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=VENTING_HOBS')"
              style="--animation-order: 6"
              class="product-box fade-in-box max-hight"
            >
              <p
                class="px-4 py-4 text-white font-bold bg-default-col-bg integriert-dunstabzug shadow-md"
                [ngClass]="helperService.isLandscape ? 'text-base' : 'text-xl'"
              >
                Die neuen Kochfelder mit integriertem Dunstabzug
              </p>
            </div>
            <div
              (click)="
                getProductViaEndpoint(
                  'program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=FRIDGE_FREEZER_COMBINATIONS,REFRIGERATORS,FREEZERS&feature_ids=%11646894,15693340,16209736'
                )
              "
              style="--animation-order: 8"
              class="product-box fade-in-box max-hight"
            >
              <p class="px-4 py-5 text-black text-sm font-bold bg-default-col-bg shadow-md kuehl-gefrier">
                Die neuen XXL <br />
                Kühl und Gefrierkombinationen.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!--       --- DESIGN FOR LIKE A BOSCH OVERLAY FOR MOBILE VERSION --- -->
      <!--       --- DESIGN FOR LIKE A BOSCH OVERLAY FOR MOBILE VERSION --- -->
      <!--       --- DESIGN FOR LIKE A BOSCH OVERLAY FOR MOBILE VERSION --- -->
      <div *ngIf="router.url === '/like-a-bosch' && helperService.isMobile" class="pb-4">
        <h3 class="text-white text-sm mt-8">Das könnte dich auch interessieren:</h3>
        <div class="grid grid-cols-4 gap-4">
          <p class="col-start-1 pb-4 mt-4 mb-2 text-white font-bold">Videos:</p>
        </div>

        <div>
          <div class="grid grid-cols-2" *ngFor="let video of getVideoListWithoutSelectedVideo(); let i = index" style="--animation-order:{{ i + 1 }}">
            <div>
              <a class="fade-in-box" (click)="onVideoClick(video)">
                <img class="object-cover" [src]="getVideoLink(video.thumbailLink)" />
              </a>
              <p class="text-white text-small mt-2">{{ video.title }}</p>
            </div>
          </div>

          <div class="mt-8">
            <p class="col-start-2 pb-4 mb-2 mt-16 text-white font-bold">Produkte:</p>
            <div class="grid grid-cols-2 col-start-2 col-end-5 row-start-1 gap-4">
              <div
                (click)="getProductViaEndpoint('program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=DISHWASHERS&feature_ids=15929998')"
                style="--animation-order: 3"
                class="product-box fade-in-box"
                [ngClass]="helperService.isMobile && !helperService.isTablet ? 'h-36' : helperService.isTablet ? 'h-60' : ''"
              >
                <p class="p-2 text-base m-0 text-white font-bold bg-default-col-bg geschirrspueler shadow-md">Die neuen PerfectDry Geschirrspüler.</p>
              </div>
              <div
                (click)="
                  getProductViaEndpoint('program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=HOBS,VENTING_HOBS&feature_ids=15755744')
                "
                style="--animation-order: 5"
                class="product-box fade-in-box"
                [ngClass]="helperService.isMobile && !helperService.isTablet ? 'h-36' : helperService.isTablet ? 'h-60' : ''"
              >
                <p class="p-2 text-base m-0 text-white font-bold bg-default-col-bg shadow-md glass-protect">GlassProtect. Noch kratzresistenter</p>
              </div>

              <div
                (click)="
                  getProductViaEndpoint(
                    'program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=FRIDGE_FREEZER_COMBINATIONS,REFRIGERATORS,FREEZERS&feature_ids=10613883'
                  )
                "
                style="--animation-order: 7"
                class="product-box fade-in-box"
                [ngClass]="helperService.isMobile && !helperService.isTablet ? 'h-36' : helperService.isTablet ? 'h-60' : ''"
              >
                <p class="p-2 text-base m-0 text-white font-bold bg-default-col-bg vario-style shadow-md">Vario Style</p>
              </div>

              <div
                (click)="getProductViaEndpoint('program=accent | line,accent | line carbon black&group=HOODS&typology=TYPOLOGY.DOWH')"
                style="--animation-order: 4"
                class="product-box fade-in-box"
                [ngClass]="helperService.isMobile && !helperService.isTablet ? 'h-36' : helperService.isTablet ? 'h-60' : ''"
              >
                <p class="p-2 text-base m-0 text-white font-bold bg-default-col-bg shadow-md glass-dunstabzug">Integrierter Glas Dunstabzug.</p>
              </div>

              <div
                (click)="getProductViaEndpoint('program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=VENTING_HOBS')"
                style="--animation-order: 6"
                class="product-box fade-in-box"
                [ngClass]="helperService.isMobile && !helperService.isTablet ? 'h-36' : helperService.isTablet ? 'h-60' : ''"
              >
                <p class="p-2 text-base m-0 text-white font-bold bg-default-col-bg integriert-dunstabzug shadow-md">
                  Die neuen Kochfelder mit integriertem Dunstabzug
                </p>
              </div>
              <div
                (click)="
                  getProductViaEndpoint(
                    'program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=FRIDGE_FREEZER_COMBINATIONS,REFRIGERATORS,FREEZERS&feature_ids=%11646894,15693340,16209736'
                  )
                "
                style="--animation-order: 8"
                class="product-box fade-in-box"
                [ngClass]="helperService.isMobile && !helperService.isTablet ? 'h-36' : helperService.isTablet ? 'h-60' : ''"
              >
                <p class="p-2 text-base m-0 text-black font-bold bg-default-col-bg shadow-md kuehl-gefrier">
                  Die neuen XXL <br />
                  Kühl und Gefrierkombinationen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--       --- DESIGN FOR SENSOR TECHNOLOGY OVERLAY ---     -->
      <!--       --- DESIGN FOR SENSOR TECHNOLOGY OVERLAY ---     -->
      <!--       --- DESIGN FOR SENSOR TECHNOLOGY OVERLAY ---     -->

      <div
        *ngIf="router.url === '/sensortechnologie' && !helperService.isMobile && !helperService.isTablet4k && !helperService.isTabletLandscape"
        [ngClass]="helperService.isLandscape ? 'col-start-7 col-end-13' : ''"
      >
        <h3 class="text-white text-2xl mt-8">Das könnte dich auch interessieren:</h3>
        <div class="grid grid-cols-4 gap-4">
          <p class="col-start-1 pb-4 mt-4 mb-2 text-white font-bold">Videos:</p>
          <p class="col-start-4 pb-4 mt-4 mb-2 text-white font-bold">Produkte:</p>
        </div>
        <div [ngClass]="{ 'auto-rows-fr': helperService.isMobile }" class="grid grid-cols-4 gap-4">
          <div class="grid grid-cols-3 col-start-1 col-end-4 grid-rows-2 gap-4 video-container">
            <div
              class="fade-in-box"
              (click)="onVideoClick(video)"
              *ngFor="let video of getVideoListWithoutSelectedVideo(); let i = index"
              style="--animation-order:{{ i + 1 }}"
            >
              <img class="" [src]="video.thumbailLink" />
              <p class="text-white text-xs py-2 max-w px-4 bg-default-col-bg shadow-md">{{ video.title }}</p>
            </div>
          </div>

          <div class="grid grid-cols-1 col-start-4 grid-rows-2 gap-4 auto-rows-fr">
            <!--  WHEN PERFECT BAKE IS CLICKED -->
            <!--  WHEN PERFECT BAKE IS CLICKED -->
            <!--  WHEN PERFECT BAKE IS CLICKED -->
            <div
              *ngIf="selectedVideo.id === 1"
              (click)="
                getProductViaEndpoint(
                  'program=accent | line,accent | line carbon black&ggroup=OVENS&steam_functions:not=ADDEDSTEAM,HS_HOTAIR_STEAM&start_date:gt=2022-12-31&feature_ids:and=20774593'
                )
              "
              style="--animation-order: 1"
              class="product-box fade-in-box col-span-3"
            >
              <p class="p-5 text-white text-sm h-full font-bold bg-default-col-bg backoefen shadow-md">
                Backöfen mit <br />
                PerfectBake plus
              </p>
            </div>
            <!--  WHEN PERFECT ROAST IS CLICKED -->
            <!--  WHEN PERFECT ROAST IS CLICKED -->
            <!--  WHEN PERFECT ROAST IS CLICKED -->
            <div
              *ngIf="selectedVideo.id === 2"
              (click)="
                getProductViaEndpoint('group=OVENS&steam_functions:not=ADDEDSTEAM,HS_HOTAIR_STEAM&start_date:gt=2022-12-31&feature_ids:and=20774741')
              "
              style="--animation-order: 1"
              class="product-box fade-in-box col-span-3"
            >
              <p class="p-5 text-white text-sm h-full font-bold bg-default-col-bg backoefen shadow-md">
                Backöfen mit <br />
                PerfectRoast plus
              </p>
            </div>
            <!--  WHEN PERFECT COOK IS CLICKED -->
            <!--  WHEN PERFECT COOK IS CLICKED -->
            <!--  WHEN PERFECT COOK IS CLICKED -->
            <div
              *ngIf="selectedVideo.id === 3"
              (click)="getProductViaEndpoint('program=accent | line,accent | line carbon black&group=HOBS&feature_ids:and=10613815')"
              style="--animation-order: 2"
              class="product-box fade-in-box col-span-3"
            >
              <p class="p-5 text-white text-sm h-full font-bold bg-default-col-bg shadow-md kochfelder">
                Kochfelder mit <br />
                PerfectCook Kochsensor
              </p>
            </div>
            <!--  WHEN PERFECT FRY IS CLICKED -->
            <!--  WHEN PERFECT FRY IS CLICKED -->
            <!--  WHEN PERFECT FRY IS CLICKED -->
            <div
              *ngIf="selectedVideo.id === 4"
              (click)="getProductViaEndpoint('program=accent | line,accent | line carbon black&group=HOBS&feature_ids:and=10613940')"
              style="--animation-order: 2"
              class="product-box fade-in-box col-span-3"
            >
              <p class="p-5 text-white text-sm h-full font-bold bg-default-col-bg shadow-md kochfelder">
                Kochfelder mit <br />
                PerfectFry Bratsensor
              </p>
            </div>
            <!--  WHEN PERFECT AIR IS CLICKED -->
            <!--  WHEN PERFECT AIR IS CLICKED -->
            <!--  WHEN PERFECT AIR IS CLICKED -->
            <div
              *ngIf="selectedVideo.id === 5"
              (click)="getProductViaEndpoint('program=accent | line,accent | line carbon black&group=VENTING_HOBS&feature_ids:and=11722507')"
              style="--animation-order: 2"
              class="product-box fade-in-box col-span-3"
            >
              <p class="p-5 text-white text-sm h-full font-bold bg-default-col-bg shadow-md kochfelder-mit-dunstabzug">
                Kochfelder mit <br />
                PerfectAir Luftgütesensor
              </p>
            </div>
            <!--  WHEN VITA FRESH IS CLICKED -->
            <!--  WHEN VITA FRESH IS CLICKED -->
            <!--  WHEN VITA FRESH IS CLICKED -->
            <div
              *ngIf="selectedVideo.id === 6"
              (click)="
                getProductViaEndpoint(
                  'program=accent | line,accent | line carbon black&group=REFRIGERATORS,FREEZERS,FRIDGE_FREEZER_COMBINATIONS&feature_ids:and=16209614'
                )
              "
              style="--animation-order: 2"
              class="product-box fade-in-box col-span-3"
            >
              <p class="p-5 text-white text-sm h-full font-bold bg-default-col-bg shadow-md kuehlscraenke">
                Kühlschränke mit <br />
                VitaFresh pro
              </p>
            </div>
          </div>
        </div>
      </div>

      <!--       --- DESIGN FOR SENSOR TECHNOLOGY OVERLAY FOR MOBILE VERSION ---     -->
      <!--       --- DESIGN FOR SENSOR TECHNOLOGY OVERLAY FOR MOBILE VERSION---     -->
      <!--       --- DESIGN FOR SENSOR TECHNOLOGY OVERLAY FOR MOBILE VERSION---     -->

      <div
        *ngIf="router.url === '/sensortechnologie' && (helperService.isMobile || helperService.isTablet4k || helperService.isTabletLandscape)"
        [ngClass]="helperService.isLandscape ? 'col-start-7 col-end-13' : ''"
      >
        <h3 [ngClass]="helperService.isMobile ? 'text-sm' : 'text-3xl'" class="text-white text-sm mt-8">Das könnte dich auch interessieren:</h3>
        <div class="grid grid-cols-4 gap-4">
          <p [ngClass]="helperService.isMobile ? 'text-xs' : 'text-2xl'" class="col-start-1 pb-4 mt-4 mb-2 text-white font-bold">Videos:</p>
        </div>

        <div>
          <div class="grid grid-cols-1 gap-2 auto-rows-fr">
            <div
              [ngClass]="helperService.isTablet4k || helperService.isTabletLandscape ? ' grid-cols-3' : 'grid-cols-2'"
              class="grid col-start-1 col-end-4 grid-rows-2 gap-4 video-container"
            >
              <div
                class="fade-in-box"
                (click)="onVideoClick(video)"
                *ngFor="let video of getVideoListWithoutSelectedVideo(); let i = index"
                style="--animation-order:{{ i + 1 }}"
              >
                <img [src]="video.thumbailLink" />
                <p class="text-white text-small mt-2">{{ video.title }}</p>
              </div>
            </div>
            <div>
              <p [ngClass]="helperService.isMobile ? 'text-xs' : 'text-2xl'" class="col-start-2 pb-4 mt-4 mb-2 text-white font-bold">Produkte:</p>
              <div
                [ngClass]="helperService.isTablet4k || helperService.isTabletLandscape ? ' grid-cols-3' : 'grid-cols-2'"
                class="grid col-start-4 grid-rows-2 gap-4 auto-rows-fr"
              >
                <!--  WHEN PERFECT BAKE IS CLICKED -->
                <!--  WHEN PERFECT BAKE IS CLICKED -->
                <!--  WHEN PERFECT BAKE IS CLICKED -->
                <div
                  *ngIf="selectedVideo.id === 1"
                  (click)="
                    getProductViaEndpoint(
                      'program=accent | line,accent | line carbon black&ggroup=OVENS&steam_functions:not=ADDEDSTEAM,HS_HOTAIR_STEAM&start_date:gt=2022-12-31&feature_ids:and=20774593'
                    )
                  "
                  style="--animation-order: 1"
                  [ngClass]="helperService.isTablet || helperService.isTabletLandscape || helperService.isTablet4k ? 'h-60' : 'h-32'"
                  class="product-box fade-in-box"
                >
                  <p class="p-5 text-white text-sm h-full font-bold bg-default-col-bg backoefen shadow-md m-0">
                    Backöfen mit <br />
                    PerfectBake plus
                  </p>
                </div>
                <!--  WHEN PERFECT ROAST IS CLICKED -->
                <!--  WHEN PERFECT ROAST IS CLICKED -->
                <!--  WHEN PERFECT ROAST IS CLICKED -->
                <div
                  *ngIf="selectedVideo.id === 2"
                  (click)="
                    getProductViaEndpoint(
                      'group=OVENS&steam_functions:not=ADDEDSTEAM,HS_HOTAIR_STEAM&start_date:gt=2022-12-31&feature_ids:and=20774741'
                    )
                  "
                  style="--animation-order: 1"
                  [ngClass]="helperService.isTablet || helperService.isTabletLandscape || helperService.isTablet4k ? 'h-60' : 'h-32'"
                  class="product-box fade-in-box"
                >
                  <p class="p-5 text-white text-sm h-full font-bold bg-default-col-bg backoefen shadow-md m-0">
                    Backöfen mit <br />
                    PerfectRoast plus
                  </p>
                </div>
                <!--  WHEN PERFECT COOK IS CLICKED -->
                <!--  WHEN PERFECT COOK IS CLICKED -->
                <!--  WHEN PERFECT COOK IS CLICKED -->
                <div
                  *ngIf="selectedVideo.id === 3"
                  (click)="getProductViaEndpoint('program=accent | line,accent | line carbon black&group=HOBS&feature_ids:and=10613815')"
                  style="--animation-order: 2"
                  [ngClass]="helperService.isTablet || helperService.isTabletLandscape || helperService.isTablet4k ? 'h-60' : 'h-32'"
                  class="product-box fade-in-box"
                >
                  <p class="p-5 text-white text-sm h-full font-bold bg-default-col-bg shadow-md kochfelder m-0">
                    Kochfelder mit <br />
                    PerfectCook Kochsensor
                  </p>
                </div>
                <!--  WHEN PERFECT FRY IS CLICKED -->
                <!--  WHEN PERFECT FRY IS CLICKED -->
                <!--  WHEN PERFECT FRY IS CLICKED -->
                <div
                  *ngIf="selectedVideo.id === 4"
                  (click)="getProductViaEndpoint('program=accent | line,accent | line carbon black&group=HOBS&feature_ids:and=10613940')"
                  style="--animation-order: 2"
                  [ngClass]="helperService.isTablet || helperService.isTabletLandscape || helperService.isTablet4k ? 'h-60' : 'h-32'"
                  class="product-box fade-in-box"
                >
                  <p class="p-5 text-white text-sm h-full font-bold bg-default-col-bg shadow-md kochfelder m-0">
                    Kochfelder mit <br />
                    PerfectFry Bratsensor
                  </p>
                </div>
                <!--  WHEN PERFECT AIR IS CLICKED -->
                <!--  WHEN PERFECT AIR IS CLICKED -->
                <!--  WHEN PERFECT AIR IS CLICKED -->
                <div
                  *ngIf="selectedVideo.id === 5"
                  (click)="getProductViaEndpoint('program=accent | line,accent | line carbon black&group=VENTING_HOBS&feature_ids:and=11722507')"
                  style="--animation-order: 2"
                  [ngClass]="helperService.isTablet || helperService.isTabletLandscape || helperService.isTablet4k ? 'h-60' : 'h-32'"
                  class="product-box fade-in-box"
                >
                  <p class="p-5 text-white text-sm h-full font-bold bg-default-col-bg shadow-md kochfelder-mit-dunstabzug m-0">
                    Kochfelder mit <br />
                    PerfectAir Luftgütesensor
                  </p>
                </div>
                <!--  WHEN VITA FRESH IS CLICKED -->
                <!--  WHEN VITA FRESH IS CLICKED -->
                <!--  WHEN VITA FRESH IS CLICKED -->
                <div
                  *ngIf="selectedVideo.id === 6"
                  (click)="
                    getProductViaEndpoint(
                      'program=accent | line,accent | line carbon black&group=REFRIGERATORS,FREEZERS,FRIDGE_FREEZER_COMBINATIONS&feature_ids:and=16209614'
                    )
                  "
                  style="--animation-order: 2"
                  [ngClass]="helperService.isTablet || helperService.isTabletLandscape || helperService.isTablet4k ? 'h-60' : 'h-32'"
                  class="product-box fade-in-box"
                >
                  <p class="p-5 text-white text-sm h-full font-bold bg-default-col-bg shadow-md kuehlscraenke m-0">
                    Kühlschränke mit <br />
                    VitaFresh pro
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
