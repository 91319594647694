import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-cookie-video-overlay',
  templateUrl: './cookie-video-overlay.component.html',
  styleUrls: ['./cookie-video-overlay.component.scss'],
})
export class CookieVideoOverlayComponent implements OnInit {
  constructor(public helperService: HelperService) {}

  @Input() highlightsPage: boolean = false;

  ngOnInit(): void {}

  openCookieSetting() {
    this.helperService.showCookieOverlay = true;
  }
}
