<!--   LAYOUT FOR PRODUCT OVERVIEW OVERLAY    -->
<!--   LAYOUT FOR PRODUCT OVERVIEW OVERLAY    -->
<!--   LAYOUT FOR PRODUCT OVERVIEW OVERLAY    -->

<div *ngIf="selectedMediaArray" class="media-carousel-wrap grid grid-cols-6 gap-4">
  <div class="media-carousel-container-overlay col-start-2 col-span-4 xl:col-start-1 xl:col-span-6">
    <owl-carousel-o [options]="mediaOptions">
      <ng-container *ngIf="selectedMediaArray">
        <ng-container *ngFor="let media of selectedMediaArray; let i = index">
          <ng-template *ngIf="media.type === 'picture.' || media.type === 'video.'" carouselSlide id="slide-{{ i }}">
            <div (click)="showThisMedia(media)" class="aspect-w-1 aspect-h-1 mt-2">
              <img class="play-icon" src="assets/icon/videoPlay.png" *ngIf="media.type === 'video.'" />
              <img class="thumbnail-img" [src]="getThumbnail(media.url)" *ngIf="media.type === 'video.'" />
              <img *ngIf="media.type === 'picture.'" src="{{ media.url }}" class="product-image" alt="Produkt-Medien" />
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
