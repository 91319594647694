import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductDetailsComponent } from './components/product-page/product-details/product-details.component';
import { ProductHighlightsComponent } from './components/product-page/product-highlights/product-highlights.component';
import { ProductOverviewComponent } from './components/product-page/product-overview/product-overview.component';
import { RelatedProductsComponent } from './components/product-page/related-products/related-products.component';
import { AllFeaturesFilterComponent } from './pages/all-features-filter/all-features-filter.component';
import { AllFeaturesComponent } from './pages/all-features/all-features.component';
import { CategoryFilterComponent } from './pages/category-filter/category-filter.component';
import { FilterResultComponent } from './pages/filter-result/filter-result.component';
import { HomeConnectComponent } from './pages/home-connect/home-connect.component';
import { HomeComponent } from './pages/home/home.component';
import { LikeABoschComponent } from './pages/like-a-bosch/like-a-bosch.component';
import { ProductPageComponent } from './pages/product-page/product-page.component';
import { ProductWorldComponent } from './pages/product-world/product-world.component';
import { SallysHighlightsComponent } from './pages/sallys-highlights/sallys-highlights.component';
import { ScreensaverComponent } from './pages/screensaver/screensaver.component';
import { SensorTechnologyComponent } from './pages/sensor-technology/sensor-technology.component';
import { VideoWorldComponent } from './pages/video-world/video-world.component';

const routes: Routes = [
  { path: '', component: ScreensaverComponent },
  { path: 'startseite', component: HomeComponent },
  { path: 'unsere-produkte', component: ProductWorldComponent },
  { path: 'produktauswahl', component: CategoryFilterComponent },
  { path: 'like-a-bosch', component: LikeABoschComponent },
  { path: 'suchergebnisse', component: FilterResultComponent },
  {
    path: 'produktdetails',
    component: ProductPageComponent,
    children: [
      {
        path: 'ueberblick',
        component: ProductOverviewComponent,
      },
      {
        path: 'highlights',
        component: ProductHighlightsComponent,
      },
      {
        path: 'passende-produkte',
        component: RelatedProductsComponent,
      },
      {
        path: 'details',
        component: ProductDetailsComponent,
      },
    ],
  },

  /*   { path: 'offerings', component: OfferingsComponent },
  { path: 'offerings-filter', component: OfferingsFilterComponent },
  { path: 'offerings-result', component: OfferingsResultComponent }, */
  { path: 'bosch-entdecken', component: VideoWorldComponent },
  { path: 'vernetztes-zuhause', component: HomeConnectComponent },
  { path: 'sensortechnologie', component: SensorTechnologyComponent },
  { path: 'sallys-highlights', component: SallysHighlightsComponent },
  { path: 'alle-funktionen', component: AllFeaturesComponent },
  { path: 'funktionsauswahl', component: AllFeaturesFilterComponent },
  /*   { path: 'video-library', component: VideoLibraryComponent }, */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
