<app-background [imgPath]="'assets/img/background_images/kuechensortiment'"></app-background>
<app-menu [logoPath]="'assets/img/bosch_logo_red.svg'" [showBack]="true" [showHome]="true" [showSearch]="true"></app-menu>
<div class="px-16 xl:px-0 xl:pl-16 xl:w-1/2 mt-32">
  <div class="grid grid-cols-3 gap-3 category-menu">
    <div
      *ngFor="let cat of getParentCategories(); let i = index"
      style="--animation-order:{{ i }}"
      (click)="onCategoryClick(cat)"
      class="box-effekt-1 category-col bg-default-col-bg fade-in-box"
    >
      <span class="category-title">{{ cat }}</span>
    </div>
  </div>
</div>
