<div class="pt-20" [ngClass]="helperService.isMobile && !helperService.isTablet ? '' : helperService.isTabletLandscape ? '' : ''">
  <div
    [ngClass]="
      helperService.isTablet
        ? 'left-8 gap-6'
        : helperService.isTabletLandscape || helperService.isTablet4k
        ? 'left-16 gap-6'
        : helperService.isMobile && !helperService.isTablet
        ? 'left-4 gap-2'
        : ''
    "
    *ngIf="helperService.isMobileDevice"
    class="flex absolute bottom-8"
  >
    <a
      [ngClass]="
        helperService.isTablet4k
          ? 'p-4 min-w-56'
          : helperService.isTabletLandscape || helperService.isTablet
          ? 'p-3 min-w-40 tablet-btn-text'
          : helperService.isMobile && !helperService.isTablet
          ? 'mobile-btn-text min-w-24 p-1.5'
          : ''
      "
      class="bg-black text-center text-white"
      href="https://www.bosch-home.com/imprint"
      target="_blank"
      >Impressum</a
    >

    <a
      [ngClass]="
        helperService.isTablet4k
          ? 'p-4 min-w-56'
          : helperService.isTabletLandscape || helperService.isTablet
          ? 'p-3 min-w-40 tablet-btn-text'
          : helperService.isMobile && !helperService.isTablet
          ? 'mobile-btn-text min-w-24 p-1.5'
          : ''
      "
      class="bg-black text-center text-white"
      href="https://www.bosch-home.com/imprint/data-protection-information"
      target="_blank"
      >Datenschutzerklärung</a
    >
  </div>
</div>
