import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IAsset } from 'src/app/models/assets.model';
import { IVideo } from 'src/app/models/video.model';
import { HelperService } from 'src/app/services/helper.service';
import { IProduct } from '../../models/product.model';

@Component({
  selector: 'app-media-overlay',
  templateUrl: './media-overlay.component.html',
  styleUrls: ['./media-overlay.component.scss'],
})
export class MediaOverlayComponent implements OnInit {
  @Output() closeVideoOverlay = new EventEmitter();
  @Input() selectedProduct!: IProduct;
  @Input() selectedMedia!: IAsset;
  @Input() selectedMediaArray!: IAsset[];
  @Input() videos!: IVideo[];
  @Input() selectedVideo!: IVideo;
  constructor(public helperService: HelperService) {}

  ngOnInit(): void {}

  getProductImage() {
    if (this.selectedProduct.highResPicture) return this.selectedProduct.highResPicture;
    else return this.selectedProduct.lowResPicture;
  }

  getEmbededUrl(url: string) {
    return this.helperService.getEmbededUrl(url);
  }

  onClickCloseVideoOverlay() {
    this.closeVideoOverlay.emit();
  }

  showThisMedia(media: IAsset) {
    this.selectedMedia = media;
  }

  showThisVideo(video: IVideo) {
    this.selectedVideo = video;
  }
}
