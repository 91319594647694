import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICatApiResponse, IHighlightsApiResponse, IProdApiResponse, IProdsApiResponse } from '../models/apiResponse.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly headers = new HttpHeaders().set('Content-Type', 'application/json');
  private readonly brandId = 'A01';

  constructor(private http: HttpClient) {}

  /**
   * Searches for a specific product with all its features by either a vib or an ean number
   * @param vib (optional) contains the vib number of a product
   * @param ean (optional) contains the ean of a product
   * @returns the JSON file of a specific product (or 404 if no product can be found)
   */
  getProduct(vib?: string, ean?: string): Observable<IProdApiResponse> {
    // either vib or ean can be used for identifying a specifc product
    const id = vib !== '' ? vib : ean;
    return this.http.get<IProdApiResponse>(environment.apiUrl + `products/${id}?brandId=${this.brandId}`, { headers: this.headers });
  }

  /**
   * Searches for a specific product with all its features by specific category url, query params or both
   * @param searchParam (optional) parameter of user search
   * @param searchUrl (optional) contains the url of a specific product category
   * @returns the JSON file of a specific product (or 404 if no product can be found)
   */
  getProductBySearchParams(searchParam?: string, searchUrl?: string): Observable<ICatApiResponse> {
    const url = `${environment.apiUrl}products?brandId=${this.brandId}&${searchUrl}&per_page=50&query=${searchParam}`;
    return this.http.get<ICatApiResponse>(url, { headers: this.headers });
  }

  /**
   * Searches for more products with all its features by their vibs
   * @param vibs (optional) contains the combination of vib numbers of a products array
   * @returns the JSON file of a group of products (or 404 if no product can be found)
   */
  getProducts(vibs: string): Observable<IProdsApiResponse> {
    return this.http.get<IProdsApiResponse>(environment.apiUrl + `products?brandId=${this.brandId}&identifier=${vibs}`, {
      headers: this.headers,
    });
  }

  /**
   * Searches for all products with a given category
   * @param endpoint string
   * @returns an array of products found by given category
   */
  getProductsByGroup(endpoint: string): Observable<IProdsApiResponse> {
    return this.http.get<IProdsApiResponse>(environment.apiUrl + `products?brandId=${this.brandId}&${endpoint}&per_page=100`, {
      headers: this.headers,
    });
  }
  /**
   * Searches for 1 product with a given category && the selected featurIds && titles to get the length of all products from the meta data
   * @param group string (product category)
   * @param ids string (array of strings of selected feature ids)
   * @returns 1 Product found by given category & features just for the length to show a count on the button
   */
  getProductsLengthByIdAndTitle(group: string, ids: string, titles: string): Observable<ICatApiResponse> {
    return this.http.get<ICatApiResponse>(
      environment.apiUrl +
        `products?brandId=${this.brandId}&program=accent | line,accent | line carbon black&group=${group}&feature_ids:and=${ids}&feature_title:regexp:and=^${titles}&per_page=1`,
      { headers: this.headers },
    );
  }

  /**
   * Searches for 1 product with a given category && the selected featurIds to get the length of all products from the meta data
   * @param group string (product category)
   * @param ids string (array of strings of selected feature ids)
   * @returns 1 Product found by given category & features just for the length to show a count on the button
   */
  getProductsLengthById(group: string, ids: string): Observable<ICatApiResponse> {
    return this.http.get<ICatApiResponse>(
      environment.apiUrl +
        `products?brandId=${this.brandId}&program=accent | line,accent | line carbon black&group=${group}&feature_ids:and=${ids}&per_page=1`,
      { headers: this.headers },
    );
  }

  /**
   * Searches for 1 product with a given category && the selected featurTitles to get the length of all products from the meta data
   * @param group string (product category)
   * @param titles string (array of strings of selected feature titles)
   * @returns 1 Product found by given category & features just for the length to show a count on the button
   */
  getProductsLengthByTitle(group: string, titles: string): Observable<ICatApiResponse> {
    return this.http.get<ICatApiResponse>(
      environment.apiUrl +
        `products?brandId=${this.brandId}&program=accent | line,accent | line carbon black&group=${group}&feature_title:regexp:and=^${titles}&per_page=1`,
      { headers: this.headers },
    );
  }

  /**
   * Searches for all products with a given category && && the selected featurIds && titles
   * @param group string (product category)
   * @param titles string (array of strings of selected feature titles)
   * @returns an array of products found by given category & features (max 100 products)
   */
  getProductsByTitle(group: string, titles: string): Observable<IProdsApiResponse> {
    return this.http.get<IProdsApiResponse>(
      environment.apiUrl +
        `products?brandId=${this.brandId}&program=accent | line,accent | line carbon black&group=${group}&feature_title:regexp:and=^${titles}&per_page=100`,
      { headers: this.headers },
    );
  }

  /**
   * Searches for all products with a given category && the selected featurIds / returns all of a category if no id is selected
   * @param group string (product category)
   * @param ids string (array of strings of selected feature ids)
   * @returns an array of products found by given category & features (max 100 products)
   */
  getProductsById(group: string, ids: string): Observable<IProdsApiResponse> {
    return this.http.get<IProdsApiResponse>(
      environment.apiUrl +
        `products?brandId=${this.brandId}&program=accent | line,accent | line carbon black&group=${group}&feature_ids:and=${ids}&per_page=100`,
      { headers: this.headers },
    );
  }

  /**
   * Searches for all products with a given category && the selected featureIds && titles
   * @param group string (product category)
   * @param ids string (array of strings of selected feature ids)
   * @returns an array of products found by given category & features (max 100 products)
   */
  getProductsByIdAndTitle(group: string, ids: string, titles: string): Observable<IProdsApiResponse> {
    return this.http.get<IProdsApiResponse>(
      environment.apiUrl +
        `products?brandId=${this.brandId}&program=accent | line,accent | line carbon black&group=${group}&feature_ids:and=${ids}&feature_title:regexp:and=^${titles}&per_page=100`,
      { headers: this.headers },
    );
  }

  /**
   * Searches for a bunch of products based on a specific category group name
   * @param catGroup contains the specific category group name
   * @returns the JSON file of an array of several products
   */
  getSearchResult(catGroup: string): Observable<ICatApiResponse> {
    return this.http.get<ICatApiResponse>(environment.apiUrl + `products?brandId=${this.brandId}&group=${catGroup}`, { headers: this.headers });
  }

  /**
   * Gets all Features of the chosen Product group ascending
   * @param group specific group of devices
   * @returns ths JSON file of all features of the specific group
   */
  getHighlightsOfSelectedGroup(group: string): Observable<IHighlightsApiResponse> {
    return this.http.get<IHighlightsApiResponse>(
      environment.apiUrl + `features?brandId=A01&program=accent | line,accent | line carbon black&group=${group}&per_page=100&order_by=title:ASC`,
      { headers: this.headers },
    );
  }

  /**
   * Gets the a specific feature based on a specific feature title
   * @param feature contains the specific feature name
   * @returns the JSON file of an array of several products
   */
  getHighlightsOfSelectedFeature(feature: string): Observable<IHighlightsApiResponse> {
    return this.http.get<IHighlightsApiResponse>(environment.apiUrl + `features?brandId=${this.brandId}&titles[]=${feature}`, {
      headers: this.headers,
    });
  }

  /**
   * Searches for a bunch of features based on a specific category group
   * @param group contains the specific category group name
   * @returns the JSON file of an array of several products
   */
  getHighlightsOfProductGroup(group: string): Observable<IHighlightsApiResponse> {
    return this.http.get<IHighlightsApiResponse>(environment.apiUrl + `features?brandId=${this.brandId}&group=${group}&per_page=100`, {
      headers: this.headers,
    });
  }

  /**
   * Searches for a bunch of features based on the featureIds to compare with the remaining features
   * ( used as a reference array to grey out features which would not return a product )
   * @param group contains the specific category group name
   * @param ids contains a long string with the combined ids
   * @returns the JSON file of an array of several products
   */
  getHighlightsById(group: string, ids: string): Observable<IHighlightsApiResponse> {
    return this.http.get<IHighlightsApiResponse>(
      environment.apiUrl + `features?brandId=${this.brandId}&group=${group}&feature_ids:and=${ids}&per_page=100`,
      {
        headers: this.headers,
      },
    );
  }

  /**
   * Searches for a bunch of features based on the featureTitle to compare with the remaining features
   * ( used as a reference array to grey out features which would not return a product )
   * @param group contains the specific category group name
   * @param titles contains a long string with the combined titles
   * @returns the JSON file of an array of several products
   */
  getHighlightsByTitle(group: string, titles: string): Observable<IHighlightsApiResponse> {
    return this.http.get<IHighlightsApiResponse>(
      environment.apiUrl + `features?brandId=${this.brandId}&group=${group}&feature_title:regexp:and=^${titles}&per_page=100`,
      {
        headers: this.headers,
      },
    );
  }

  /**
   * Searches for a bunch of features based on the featureIds && featureTitles to compare with the remaining features
   * ( used as a reference array to grey out features which would not return a product )
   * @param group contains the specific category group name
   * @param ids contains a long string with the combined ids
   * @param titles contains a long string with the combined titles
   * @returns the JSON file of an array of several products
   */
  getHighlightsByIdAndTitle(group: string, ids: string, titles: string): Observable<IHighlightsApiResponse> {
    return this.http.get<IHighlightsApiResponse>(
      environment.apiUrl + `features?brandId=${this.brandId}&group=${group}&feature_ids:and=${ids}&feature_title:regexp:and=^${titles}&per_page=100`,
      {
        headers: this.headers,
      },
    );
  }

  /**
   * Takes in email & identifier in the body to send the email to the user
   * @param email user input: string
   * @param identifier contains the vib of a product
   * @returns just a success / error message if the email was sent
   */
  sendMail(email: string, identifier: string): Observable<any> {
    const body = { email: email, identifier: identifier };
    return this.http.post<IProdApiResponse>(environment.apiUrl + `share/product-mail?brandId=${this.brandId}`, body, { headers: this.headers });
  }

  /**
   * Takes in email & identifier in the body to send the email to the user
   * @param email user input: string
   * @param url  contains the url of the link to Bosch homepage
   * @returns just a success / error message if the email was sent
   */
  sendWebsiteLinkAsMail(email: string, url: string): Observable<any> {
    const body = { email: email, url: url };
    return this.http.post<IProdApiResponse>(environment.apiUrl + `share/video-mail?brandId=${this.brandId}`, body, { headers: this.headers });
  }
}
