import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IProdApiResponse } from 'src/app/models/apiResponse.model';
import { IProduct } from 'src/app/models/product.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-offerings-result',
  templateUrl: './offerings-result.component.html',
  styleUrls: ['./offerings-result.component.scss'],
})
export class OfferingsResultComponent implements OnInit {
  filteredBySpecificCategory!: string[];
  bgUrl: string = '';
  products: IProduct[] = [];
  selectedProduct!: IProduct;
  currentStepNumber: number = 1;
  isLoading: boolean = false;
  prodSub!: Subscription;

  constructor(private router: Router, private apiService: ApiService) {
    this.bgUrl = history.state.bgUrl;
    this.filteredBySpecificCategory = window.history.state.filteredBySpecificCategory.articles;
    this.getSearchResults();
  }

  ngOnInit(): void {}

  getSearchResults() {
    this.filteredBySpecificCategory.forEach((e) => {
      this.prodSub = this.apiService.getProduct(e).subscribe((res: IProdApiResponse) => {
        this.products.push(res.data);
        this.selectedProduct = this.products[0];
      });
    });
  }

  getProductImg(selectedProduct: IProduct) {
    if (selectedProduct.highResPicture) {
      return selectedProduct.highResPicture;
    } else {
      return selectedProduct.lowResPicture;
    }
  }

  onClickGetPreviousProduct(): void {
    if (this.currentStepNumber === 1) {
      this.currentStepNumber = this.products.length;
      this.selectedProduct = this.products[this.products.length - 1];
      this.currentStepNumber = this.products.length;
      return;
    }
    this.selectedProduct = this.products[--this.currentStepNumber - 1];
  }

  onClickGetNextProduct(): void {
    if (this.currentStepNumber === this.products.length) {
      this.currentStepNumber = 1;
      this.selectedProduct = this.products[this.currentStepNumber - 1];
      return;
    }
    this.selectedProduct = this.products[++this.currentStepNumber - 1];
  }

  onClickMoveToProductSite(product: IProduct): void {
    this.router.navigateByUrl('/produkt', { state: { selectedEan: product.ean, bgUrl: this.bgUrl } });
  }

  ngOnDestroy() {
    if (this.prodSub) this.prodSub.unsubscribe();
  }
}
