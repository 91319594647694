import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IVideo } from 'src/app/models/video.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-video-library-overlay',
  templateUrl: './video-library-overlay.component.html',
  styleUrls: ['./video-library-overlay.component.scss'],
})
export class VideoLibraryOverlayComponent implements OnInit {
  @Output() closeVideoOverlay = new EventEmitter();
  @Input() selectedVideo!: IVideo;
  @Input() videos!: IVideo[];
  cookieStatus!: string;

  constructor(public router: Router, public helperService: HelperService) {}

  ngOnInit(): void {}

  showThisVideo(video: IVideo) {
    this.selectedVideo = video;
  }

  onClickCloseVideoOverlay() {
    this.closeVideoOverlay.emit();
  }
}
