<!-- SLIDER LAYOUT  -->
<!-- SLIDER LAYOUT  -->
<!-- SLIDER LAYOUT  -->

<div *ngIf="sallysProducts.length > 1" class="grid grid-cols-12 gap-4">
  <div class="sally-overlay-carousel-container col-start-1 col-end-13">
    <owl-carousel-o *ngIf="!isLoading" [options]="sallyOptions">
      <ng-container *ngFor="let product of sallysProducts; let i = index">
        <ng-template carouselSlide id="slide-{{ i }}">
          <div (click)="checkProductGroup(product)" class="aspect-w-16 aspect-h-9 mt-2 mb-2 bg-default-col-bg">
            <img class="sally-product-img" [src]="getProductImg(product)" />
          </div>
          <div class="scrollbar-hidden fade-in-box" style="--animation-order:{{ i }}">
            <!-- <p class="bg-default-col-bg p-3 mb-2 text-sm text-white">{{ product.vib }}</p> -->
            <p [ngClass]="helperService.isMobile ? 'text-small' : 'text-sm p-3 mb-2 '" class="video-description-box bg-default-col-bg text-white">
              {{ product.title }} <br />
              <strong>{{ product.vib }}</strong>
            </p>
            <p
              *ngIf="product.group !== 'ACCESSORIES'"
              (click)="showThisProduct(product)"
              [ngClass]="helperService.isMobile ? 'text-small' : 'text-sm p-3  '"
              class="more-box bg-default-col-bg font-bold text-white"
            >
              Mehr erfahren
            </p>
            <img
              src="assets/menuIcons/shareIcon.svg"
              *ngIf="product.group === 'ACCESSORIES'"
              (click)="openRelatedProductOverlay(sallysProducts[0])"
              class="bg-default-col-bg font-bold p-3 text-sm text-white share-icon"
            />
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>

<!--  SINGLE PRODUCT LAYOUT -->
<!--  SINGLE PRODUCT LAYOUT -->
<!--  SINGLE PRODUCT LAYOUT -->

<div class="grid grid-cols-1 justify-items-center mt-4">
  <div class="w-1/2" *ngIf="sallysHint.products.length === 1 && !isLoading">
    <div class="aspect-w-16 aspect-h-9 mt-2 mb-2 bg-default-col-bg">
      <img class="sally-product-img" [src]="getProductImg(sallysProducts[0])" />
    </div>
    <p class="bg-default-col-bg p-3 mb-2 text-sm text-white">{{ sallysProducts[0].title }}</p>
    <p
      *ngIf="sallysProducts[0].group !== 'ACCESSORIES'"
      (click)="checkProductGroup(sallysProducts[0])"
      class="bg-default-col-bg font-bold p-3 text-sm text-white"
    >
      Mehr erfahren
    </p>
    <img
      src="assets/menuIcons/shareIcon.svg"
      *ngIf="sallysProducts[0].group === 'ACCESSORIES'"
      (click)="openRelatedProductOverlay(sallysProducts[0])"
      class="bg-default-col-bg font-bold p-3 text-sm text-white share-icon"
    />
  </div>
</div>

<app-related-products-overlay
  [selectedRelatedProduct]="selectedRelatedProduct"
  (closeRelatedProductOverlay)="closeRelatedProductOverlay()"
  *ngIf="showRelatedProductOverlay"
></app-related-products-overlay>
