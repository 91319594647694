import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { IVideo } from 'src/app/models/video.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-video-library-slider',
  templateUrl: './video-library-slider.component.html',
  styleUrls: ['./video-library-slider.component.scss'],
})
export class VideoLibrarySliderComponent implements OnInit {
  @Output() mediaChanged = new EventEmitter();
  @Input() videos!: IVideo[];
  @Input() selectedVideo!: IVideo;

  constructor(public helperService: HelperService) {}

  ngOnInit(): void {}

  showThisVideo(video: IVideo) {
    this.mediaChanged.emit(video);
  }

  modernKitchenOption: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 8,
    navSpeed: 700,
    navText: ['<img class="arrow" src="assets/icon/icon-arrow-left.svg"/>', '<img class="arrow" src="assets/icon/icon-arrow-right.gif"/>'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 4,
      },
      940: {
        items: 4,
      },
      1280: {
        items: 4,
      },
      2000: {
        items: 3,
      },
    },
    nav: true,
  };
}
