import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MockdataService {
  constructor() {}

  getAllCategories() {
    // transform json file to filter-categories
    return fetch('../../assets/data/categories.json').then((res) => res.json());
  }

  getAllOfferingsCategories() {
    // transform json file to filter-categories
    return fetch('../../assets/data/offerings-list.json').then((res) => res.json());
  }

  getLikeABoschVideos() {
    // transform json file to like a bosch videos
    return fetch('../../assets/data/likeabosch-videos.json').then((res) => res.json());
  }

  getFilterResults() {
    // transform json file to fake products
    return fetch('../../assets/data/tempProduct.json').then((res) => res.json());
  }

  getMoreVideos() {
    // transform json file to like a bosch videos
    return fetch('../../assets/data/more-videos.json').then((res) => res.json());
  }

  getSensorTechnologyVideos() {
    // transform json file to like a bosch videos
    return fetch('../../assets/data/sensor-technology-videos.json').then((res) => res.json());
  }

  getVideoWorldVideos(category: string) {
    return fetch(`../../assets/data/videoworld-${category}-videos.json`).then((res) => res.json());
  }

  getAllFeatures() {
    return fetch('../../assets/data/features.json').then((res) => res.json());
  }

  getSallysHints() {
    return fetch('../../assets/data/sallys-hints.json').then((res) => res.json());
  }
}
