import { Component, HostListener, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription, debounceTime, fromEvent, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from './global-constants';

import { HelperService } from './services/helper.service';
import { NetworkService } from './services/network.service';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'bosch-pos';
  showScreensaverNotice = false;

  userActivity: any;
  userScreensaver: any;
  userInactive: Subject<any> = new Subject();
  userInactiveScreensaver: Subject<any> = new Subject();

  windowWidth: number = GlobalConstants.windowWidth;

  routerEventSub: Subscription = new Subscription();

  networkSub: Subscription = new Subscription();
  isOnline!: boolean;

  isLandscape = false;

  constructor(
    private router: Router,
    public helperService: HelperService,
    private networkService: NetworkService,
    private metaService: Meta,
    private platform: Platform,
  ) {
    this.disableDraggingImages();

    this.checkResolutionAndScreenOrientation();

    // Subscribes to the networkState and redirects to Screensaver page when there is no network connection
    this.networkSub = this.networkService.networkState.subscribe((isOnline: boolean) => {
      if (!isOnline && this.router.url !== '/') this.router.navigateByUrl('/');
    });

    if (!this.helperService.isMobile && !this.helperService.isTablet4k && !this.helperService.isTabletLandscape) {
      // Screensaver only visible when not in mobile view
      this.checkForScreensaver();

      // Reload Page if you route to the same page you are on
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.routerEventSub = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // Trick the Router into believing its last link wasn't previously loaded
          this.router.navigated = false;
        }
      });
      // Doesn't show screensaver in mobile view just redirect to /home
    } else {
      this.router.events.subscribe((route) => {
        if (route instanceof NavigationEnd) {
          if (route.url === '/' && !this.platform.BLINK && !this.platform.FIREFOX && !this.platform.EDGE) this.router.navigateByUrl('/startseite');
        }
      });
    }
    // additional check if not chromium / firefox browser => mobile / not "Stele" => no Screensaver
    if (
      (this.router.url === '/' && !this.platform.BLINK && !this.platform.FIREFOX && !this.platform.EDGE) ||
      this.helperService.isMobile ||
      this.helperService.isTablet4k ||
      this.helperService.isTabletLandscape
    ) {
      this.router.navigateByUrl('/startseite');
    }

    this.detectScreenOrientation();
  }

  ngOnInit(): void {
    this.setScalability();
  }

  // prevents ping-gesture zoom on normal view but allows it for mobile devices
  setScalability(): void {
    if (this.helperService.isMobileDevice) {
      this.metaService.updateTag(
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0, user-scalable=yes',
        },
        'name=viewport',
      );
    } else {
      this.metaService.updateTag(
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
        },
        'name=viewport',
      );
    }
  }

  detectScreenOrientation() {
    const portrait = window.matchMedia('(orientation: portrait)');
    portrait.addEventListener('change', () => {
      window.location.reload();
    });
  }

  disableDraggingImages() {
    // Adds app wide eventlistener which disables dragging images
    addEventListener('dragstart', function (e) {
      e.preventDefault();
    });
  }

  checkResolutionAndScreenOrientation() {
    // Checks for 4k resolution / landscape orientation and updates the
    // is4k and isLandscape booleans in the helperService when screen is resized
    const resize$ = fromEvent(window, 'resize');
    resize$
      .pipe(
        map((i: any) => i),
        debounceTime(200), // Waits > 200ms between 2 events emitted before running the next.
      )
      .subscribe((event) => {
        // The order matters since Landscape mode can only be true if Tablet Landscape is false.
        // Therefore isTabletLandscapeMode()-Method has to be checked before isLandscapeMode()-Method is called
        GlobalConstants.windowWidth = window.innerWidth;
        this.helperService.is4kMode();
        this.helperService.isMobileMode();
        this.helperService.isTabletMode();
        this.helperService.isTabletLandscapeMode();
        this.helperService.isLandscapeMode();
        this.helperService.isTablet4kMode();
      });
  }

  checkForScreensaver() {
    if (environment.screensaverOn) this.setTimeout();
    this.userInactiveScreensaver.subscribe(() => {
      // Show hint for screensaver switch if user is not on home page
      if (window.location.pathname !== '/' && !this.helperService.isMobile && !this.helperService.isTablet4k && !this.helperService.isTabletLandscape)
        this.showScreensaverNotice = true;
    });
    this.userInactive.subscribe(() => {
      // Navigate to "screensaver" page after specific amount of time and mute screensaver video
      this.helperService.updateVideoMutedState(true);
      this.router.navigateByUrl('/');
    });
  }

  // Waiting time until "screensaver" page is shown (e.g. 1min)
  setTimeout() {
    this.userScreensaver = setTimeout(() => this.userInactiveScreensaver.next(undefined), 1000 * 60 * 4.5);
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1000 * 60 * 5);
  }

  // Updates timer when user clicks or moves the cursor
  @HostListener('window:mousemove') moveReset() {
    if (environment.screensaverOn) this.refreshUserState();
  }

  @HostListener('window:click') clickReset() {
    if (environment.screensaverOn) this.refreshUserState();
  }

  refreshUserState() {
    this.showScreensaverNotice = false;
    clearTimeout(this.userActivity);
    clearTimeout(this.userScreensaver);
    this.setTimeout();
  }

  openCookieSettings() {
    this.helperService.showCookieOverlay = true;
  }

  closeCookieOverlay() {
    this.helperService.showCookieOverlay = false;
  }

  closeSearchOverlay() {
    this.helperService.showSearchOverlay = false;
  }

  ngOnDestroy() {
    if (this.userInactive) {
      this.userInactive.unsubscribe();
    }
    if (this.userInactiveScreensaver) {
      this.userInactiveScreensaver.unsubscribe();
    }
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
    if (this.networkSub) {
      this.networkSub.unsubscribe();
    }
  }
}
