import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IHighlightsApiResponse } from 'src/app/models/apiResponse.model';
import { IFeatureFilter } from 'src/app/models/featureFilter.model';
import { IFeature } from 'src/app/models/features.model';
import { IHighlights } from 'src/app/models/highlights.model';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { MockdataService } from 'src/app/services/mockdata.service';

@Component({
  selector: 'app-all-features-filter',
  templateUrl: './all-features-filter.component.html',
  styleUrls: ['./all-features-filter.component.scss'],
})
export class AllFeaturesFilterComponent implements OnInit {
  category!: string;
  features!: IFeatureFilter;
  selectedHighlight!: IHighlights;
  allHighlightsOfSelectedFeature: IHighlights[] = [];
  bgUrl: string = 'assets/img/background_images/category1.png';
  showOverlay: boolean = false;
  isLoading: boolean = false;
  allFeatures: IHighlights[] = [];
  highlightedFeatures: IFeature[] = [];
  highlightSub: Subscription;

  constructor(
    private mockdataService: MockdataService,
    public helperService: HelperService,
    private apiService: ApiService,
    private route: ActivatedRoute,
  ) {
    this.isLoading = true;
    this.route.paramMap.subscribe((params) => {
      this.category = params.get('category')!;
    });

    // gets all the filtered features by category
    this.mockdataService.getAllFeatures().then((res) => {
      this.highlightedFeatures = res.features[this.category];
      // console.log('this.highlighted Features is', this.highlightedFeatures);
    });
    this.highlightSub = this.apiService.getHighlightsOfSelectedGroup(this.category).subscribe((res: IHighlightsApiResponse) => {
      this.allFeatures = res.data;
      this.isLoading = false;
      // console.log('this.all Features is', this.allFeatures);
      this.allFeatures = this.filterValidFeatures();
      // console.log('after valid filter is', this.allFeatures);
    });
  }

  ngOnInit(): void {}

  filterValidFeatures() {
    // gets alls highlights that contains either a video or a relevant image
    return this.allFeatures.filter((e) => {
      return (
        e.assets.filter(
          (v) =>
            v.category.includes('illustration-drawing') ||
            v.category.includes('feature-video') ||
            v.category.includes('category.detail') ||
            v.category.includes('food.mood.styling') ||
            v.category.includes('product-image') ||
            v.category.includes('product-video'),
        ).length > 0
      );
    });
  }

  getHighlightedFeatures() {
    this.allFeatures.forEach((e) => {
      this.highlightedFeatures.forEach((el) => {
        if (e.title === el.title) e.isHighlighted = true;
      });
    });
    return this.allFeatures;
  }

  onFeatureClick(feature: IHighlights) {
    this.isLoading = true;
    this.getBgUrl();
    this.selectedHighlight = feature;
    this.isLoading = false;
    this.showOverlay = true;
  }

  getBgUrl() {
    switch (this.category) {
      case 'OVENS,MICROWAVE_OVENS,STEAM_OVENS,COMBI_STEAM_OVEN':
        this.bgUrl = 'assets/img/background_images/category1.jpg';
        break;
      case 'HOBS,VENTING_HOBS':
        this.bgUrl = 'assets/img/background_images/category2.jpg';
        break;
      case 'HOODS':
        this.bgUrl = 'assets/img/background_images/category6.jpg';
        break;
      case 'FRIDGE_FREEZER_COMBINATIONS,REFRIGERATORS,FREEZERS':
        this.bgUrl = 'assets/img/background_images/category7.jpg';
        break;
      case 'DISHWASHERS':
        this.bgUrl = 'assets/img/background_images/category9.jpg';
        break;
      default:
        this.bgUrl = 'assets/img/background_images/category1.jpg';
        break;
    }
  }

  closeOverlay(): void {
    this.showOverlay = false;
    this.selectedHighlight = undefined!;
    this.helperService.updatedBackgroundBlur(false);
  }

  getOnlyHighlightsWithVideoOrImage() {
    // gets alls highlights that contains either a video or a relevant image
    return this.allHighlightsOfSelectedFeature.filter((e) => {
      return e.assets.filter((v) => v.category.includes('feature-video') || v.category.includes('category.detail')).length > 0;
    });
  }

  getLogoColor(): string {
    if (this.helperService.isMobile) return 'assets/img/bosch_logo.png';
    else return 'assets/img/bosch_logo_red.svg';
  }

  ngOnDestroy() {
    if (this.highlightSub) this.highlightSub.unsubscribe();
  }
}
