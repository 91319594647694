import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { IVideo } from 'src/app/models/video.model';
import { HelperService } from 'src/app/services/helper.service';
import { MockdataService } from 'src/app/services/mockdata.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sensor-technology',
  templateUrl: './sensor-technology.component.html',
  styleUrls: ['./sensor-technology.component.scss'],
})
export class SensorTechnologyComponent {
  blurredSub: Subscription;
  isBlurred: boolean = false;
  showVideoOverlay: boolean = false;
  selectedVideo!: IVideo;
  videos!: IVideo[];
  video!: IVideo;

  constructor(public helperService: HelperService, private mockService: MockdataService) {
    if (environment.useMockData) {
      this.mockService.getSensorTechnologyVideos().then((res) => {
        this.videos = res.videos as IVideo[];
      });
    }
    // blur background if a video is clicked
    this.blurredSub = this.helperService.backgroundBlur.subscribe((blurred: boolean) => {
      this.isBlurred = blurred;
    });
  }

  getImages(imageLink: string) {
    if (this.helperService.isMobile) {
      return imageLink.split('.')[0] + '-mobile.jpg';
    } else {
      return imageLink;
    }
  }

  onVideoClick(video: IVideo): void {
    this.selectedVideo = video;
    this.showVideoOverlay = true;
    this.helperService.updatedBackgroundBlur(true);
  }

  closeVideoOverlay(): void {
    this.showVideoOverlay = false;
    this.helperService.updatedBackgroundBlur(false);
  }

  getLogoColor(): string {
    if (this.helperService.isMobile) return 'assets/img/bosch_logo.png';
    else return 'assets/img/bosch_logo_red.svg';
  }

  ngOnDestroy() {
    if (this.blurredSub) this.blurredSub.unsubscribe();
  }
}
