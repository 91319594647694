<div
  class="bosch-scrollbar"
  [ngClass]="[
    helperService.isLandscape ? 'w-1/2 feature-list-landscape' : 'feature-list',
    helperService.isMobile ? 'padding-mobile md:px-8 min-h-screen pb-8' : 'px-16',
    helperService.isMobileDevice ? 'pb-16' : '',
    showOverlay ? 'unscrollable' : ''
  ]"
>
  <h1
    class="text-white mb-8"
    [ngClass]="helperService.isMobile ? 'text-lg' : helperService.isTabletLandscape ? 'text-3xl' : ''"
    *ngIf="helperService.isMobileDevice"
  >
    Highlights
  </h1>
  <div>
    <app-cookie-video-overlay [highlightsPage]="true" *ngIf="!helperService.isCollectingCookies"></app-cookie-video-overlay>

    <div
      *ngIf="helperService.isCollectingCookies"
      class="grid gap-x-2"
      [ngClass]="[
        helperService.isLandscape ? 'gap-y-3' : '',
        helperService.isMobile ? 'grid-cols-2 ' : 'grid-cols-3',
        helperService.isTablet4k ? 'gap-y-24' : 'gap-y-20'
      ]"
    >
      <ng-container *ngFor="let highlight of getHighlights()">
        <div
          *ngFor="let asset of helperService.getRightAsset(highlight); let i = index"
          style="--animation-order:{{ i }}"
          class="media-container aspect-w-16 aspect-h-9"
        >
          <div class="video-container fade-in-box" (click)="openOverlay(highlight)">
            <img class="play-icon" src="assets/icon/videoPlay.png" *ngIf="asset.category.includes('feature-video')" />
            <img class="thumbnail-img" [src]="helperService.getThumbnail(asset.url)" *ngIf="asset.category.includes('feature-video')" />
            <img *ngIf="asset.category.includes('category.detail')" src="{{ asset.url }}" class="fit-image" alt="Produktbild" />
            <p [ngClass]="{ 'px-3': !helperService.isMobile }" class="py-2 video-description-box text-white hightlight-description">
              {{ getHighlightText(highlight) }}
            </p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<app-highlights-overlay
  *ngIf="showOverlay"
  [selectedProduct]="selectedProduct"
  [selectedHighlight]="selectedHighlight"
  [selectedHighlightArray]="getHighlights()"
  (closeOverlay)="closeOverlay()"
></app-highlights-overlay>
