<app-background [imgPath]="getBackgroundImageUrl()"></app-background>

<app-menu [logoPath]="'assets/img/bosch_logo.png'" [showBack]="true" [showHome]="true" [showSearch]="true"></app-menu>
<div
  [ngClass]="[
    alignRight && helperService.isLandscape ? 'align-right mt-16' : helperService.isLandscape ? 'w-3/5 mt-16' : '',
    helperService.isMobile ? 'padding-mobile md:px-8  mt-2' : !helperService.isLandscape ? 'mt-20 px-16' : 'px-16',
    showSallyOverlay ? 'unscrollable' : ''
  ]"
>
  <div class="w-full mb-2 text-white text-sm relative" *ngIf="helperService.isMobile">
    <h3 class="mb-2">{{ catData.title }}</h3>
    <img [ngClass]="helperService.isTablet ? 'h-80' : 'h-48'" class="w-full object-cover" src="{{ catData.mobileImageUrl }}" alt="" />
    <app-choice-badge *ngIf="catData.childCat[0].showChoiceBadge && !isFeatureSelected() && helperService.isMobile"></app-choice-badge>
  </div>
  <div
    *ngFor="let catRow of catLayout | keyvalue"
    class="grid gap-2 mb-2"
    [ngClass]="{
      'grid-cols-3 gap-3': catRow.value.length === 3 && !helperService.isMobile,
      'grid-cols-1': helperService.isMobile,
      'grid-cols-2': !helperService.isMobile
    }"
  >
    <div
      *ngFor="let catHelper of catRow.value"
      style="--animation-order:{{ catHelper.idx }}"
      (click)="onCategoryClick(catHelper.category)"
      [ngClass]="[
        catHelper.category.isChecked === true && catHelper.category.hasCheckbox === true ? 'category-is-checked' : 'grey-box-border',
        !catHelper.isSelectable ? 'inactive-box' : '',
        helperService.isMobile ? 'h-32' : 'h-44'
      ]"
      class="box-effekt-1 p-4 bg-default-col-bg text-white fade-in-box"
    >
      <div
        class="font-bold"
        [ngClass]="{
          'text-20 h-[20%]': helperService.isMobile,
          'text-26': !helperService.isMobile && !helperService.isTablet,
          'h-45-percent': helperService.isTablet4k,
          'h-55-percent': !helperService.isMobile && !helperService.isTablet && catHelper.category.subtitle !== '',
          'h-full': catHelper.category.subtitle === ''
        }"
      >
        {{ catHelper.category.title }}
      </div>
      <p [ngClass]="helperService.isMobile ? 'text-xs mt-4' : ' h-45-percent'" *ngIf="catHelper.category.subtitle" class="line-height-small mb-2">
        {{ catHelper.category.subtitle }}
      </p>
    </div>
  </div>

  <div *ngIf="catData.showResultBtn" [ngClass]="helperService.isMobile ? 'flex justify-center sticky bottom-4 mt-4 ' : 'grid-cols-3 grid gap-3'">
    <div
      style="--animation-order: {{ catData.childCat.length + 1 }}"
      [ngClass]="[!isLoading ? 'grey-box-border bg-black' : '', helperService.isMobile ? ' p-2  w-10/12' : 'p-4']"
      class="text-2xl text-white col-start-2 col-end-3 text-center fade-in-box flex flex-col mobile-boxshadow"
      (click)="onShowResultsClick()"
    >
      <div *ngIf="isLoading" class="loading"></div>
      <span [ngClass]="helperService.isMobile ? ' text-base' : 'text-3xl'" *ngIf="productsLength < 1 && !isLoading" class="text-mb-2"
        >Kein Gerät gefunden</span
      >
      <span [ngClass]="helperService.isMobile ? ' text-base' : 'text-3xl mb-2'" *ngIf="productsLength === 1 && !isLoading"
        >{{ productsLength }} Gerät</span
      >
      <span [ngClass]="helperService.isMobile ? 'text-base' : 'text-3xl mb-2'" *ngIf="productsLength > 1 && !isLoading"
        >{{ productsLength }} Geräte</span
      >
      <span [ngClass]="{ 'text-base': helperService.isMobile }" *ngIf="productsLength >= 1 && !isLoading" class="font-bold">Jetzt anzeigen</span>
    </div>
  </div>
</div>

<div
  class="absolute top-38 w-1/4"
  [ngClass]="alignRight ? 'left-16' : 'right-16'"
  *ngIf="showSallysHint && sallysHint && helperService.isLandscape"
  (click)="openSallyOverlay()"
>
  <div class="sally-wrap-diagonal">
    <div class="sally-container">
      <div class="icon-container" [ngClass]="helperService.isLandscape ? 'pt-12' : 'pt-4'">
        <img class="sally-tipp" src="assets/img/sally/sallysTipp.png" alt="sally-tipp" />
        <div *ngIf="sallysHint.videos.length > 0" class="sally-play-icon-container">
          <img class="sally-play-icon" src="assets/img/sally/sallyVideo.svg" alt="sally-play" />
        </div>
        <div *ngIf="sallysHint.videos.length <= 0" class="sally-plus-icon-container">
          <img class="sally-plus-icon" src="assets/img/sally/sallyPlus.svg" alt="sally-plus" />
        </div>
      </div>
      <h4 class="text-sm text-white font-bold pt-2 pb-2">{{ sallysHint.hint }}</h4>
    </div>
    <div class="sallys-img-container">
      <img class="sallys-img" src="assets/img/sally/sally.png" alt="sallys-bild" />
    </div>
  </div>
</div>

<!-- <div
  [ngClass]="alignRight && helperService.isLandscape ? 'align-right' : ''"
  *ngIf="catData.childCat[0].showChoiceBadge === true && featuresLoading && !helperService.isMobile"
  class="px-16 flex justify-center items-center" [ngClass]="helperService.isLandscape ? 'w-3/5 mt-20' : 'mt-28'"
>
</div> -->
<!-- <app-special-slider
  [allFeatures]="this.allFeatures"
  [filteredForOverlay]="filteredForOverlay"
  [alignRight]="alignRight"
  *ngIf="!featuresLoading && catData.childCat[0].showChoiceBadge && isFeatureSelected()"
></app-special-slider> -->

<div
  [ngClass]="[
    alignRight && helperService.isLandscape ? 'align-right' : helperService.isLandscape ? 'w-3/5 mt-16' : '',
    helperService.isMobile ? 'padding-mobile md:px-8 pb-mobile mt-5' : 'px-16 mt-20'
  ]"
>
  <app-choice-badge *ngIf="catData.childCat[0].showChoiceBadge && !isFeatureSelected() && !helperService.isMobile"></app-choice-badge>

  <div
    [ngClass]="catData.childCat[0].showChoiceBadge && !isFeatureSelected() ? 'flex justify-end' : ''"
    *ngIf="showSallysHint && sallysHint && !helperService.isLandscape"
    (click)="openSallyOverlay()"
  >
    <div [ngClass]="helperService.isTabletLandscape ? 'w-1/2' : 'md:w-1/2'" class="sally-wrap-diagonal">
      <div class="sally-container">
        <div class="icon-container" [ngClass]="helperService.isLandscape ? 'pt-12' : 'pt-4'">
          <img class="sally-tipp" src="assets/img/sally/sallysTipp.png" alt="sally-tipp" />
          <div *ngIf="sallysHint.videos.length > 0" class="sally-play-icon-container">
            <img class="sally-play-icon" src="assets/img/sally/sallyVideo.svg" alt="sally-play" />
          </div>
          <div *ngIf="sallysHint.videos.length <= 0" class="sally-plus-icon-container">
            <img class="sally-plus-icon" src="assets/img/sally/sallyPlus.svg" alt="sally-plus" />
          </div>
        </div>
        <h4 [ngClass]="helperService.isMobile && !helperService.isTablet ? 'text-small ' : 'text-sm '" class="text-white pt-2 pb-2 font-bold">
          {{ sallysHint.hint }}
        </h4>
      </div>
      <div class="sallys-img-container">
        <img class="sallys-img" src="assets/img/sally/sally.png" alt="sallys-bild" />
      </div>
    </div>
  </div>
</div>

<app-sally-overlay
  *ngIf="showSallyOverlay"
  [sallysHint]="sallysHint"
  [bgUrl]="catData.bgUrl"
  (closeSallyOverlay)="closeSallyOverlay()"
></app-sally-overlay>
