<div [ngClass]="helperService.isMobile || helperService.isTabletLandscape ? 'full-height' : 'inset-0'" class="absolute bg-black background-blur z-50">
  <app-menu [showSearch]="false" [logoPath]="'assets/img/bosch_logo.png'"></app-menu>

  <div
    [ngClass]="
      helperService.isLandscape
        ? 'pl-16 pt-32 w-3/5'
        : helperService.isMobile && !helperService.isTablet
        ? 'z-20 padding-mobile md:px-8 text-sm'
        : helperService.isTablet
        ? 'px-12'
        : helperService.isTabletLandscape
        ? 'px-16 pt-16'
        : 'px-16 pt-64'
    "
  >
    <div class="mb-4 flex justify-end">
      <img class="close-icon py-4" src="assets/icon/icon-close.png" (click)="onCloseSearchClick()" />
    </div>
    <div [ngClass]="helperService.isMobile ? 'flex-col gap-8 items-center' : helperService.isLandscape ? 'gap-24' : 'gap-16'" class="flex">
      <div
        [ngClass]="helperService.isMobile && !helperService.isTablet ? 'w-full gap-8' : helperService.isTablet ? 'w-3/5 gap-8 ' : 'w-1/2  gap-16'"
        class="flex flex-col w-1/2"
      >
        <input
          (input)="onInput()"
          [ngClass]="helperService.isMobile ? 'text-sm' : helperService.isTabletLandscape ? 'text-base' : 'text-2xl'"
          [(ngModel)]="searchParam"
          class="search-input w-full"
          type="text"
          placeholder="Suchbegriff"
        />

        <div [ngClass]="helperService.isMobile ? 'text-sm' : helperService.isTabletLandscape ? 'text-base' : 'text-2xl'" class="w-full">
          <div class="category-select">
            <div class="relative">
              <div class="select-selected bg-black text-white rounded-md px-1 cursor-pointer flex justify-between" (click)="toggleSelect()">
                {{ selectedCategory?.title || 'Kategorie wählen' }}
                <img [ngStyle]="getRotationStyle()" class="h-3 mr-3" src="assets/icon/icon-arrow-right.gif" alt="" />
              </div>
              <div
                class="select-items absolute bg-black text-white top-full left-0 w-full mt-3 rounded-md shadow-lg z-10"
                [class.hidden]="!isSelectOpen"
              >
                <div [ngClass]="helperService.isMobile ? 'py-2' : 'py-4'" class="select-item px-1 cursor-pointer" (click)="onShowAllProductsClick()">
                  Alle Geräte
                </div>
                <div
                  *ngFor="let category of categories"
                  [ngClass]="helperService.isMobile ? 'py-2' : helperService.isLandscape ? 'py-3' : 'py-4'"
                  class="select-item px-1 cursor-pointer"
                  (click)="onSelectCategoryClick(category)"
                >
                  {{ category.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="helperService.isMobile ? 'flex justify-center sticky bottom-4 mt-4 ' : ' grid gap-3'">
        <div
          *ngIf="productsLength !== undefined"
          [ngClass]="[!isLoading ? 'grey-box-border bg-black' : '', helperService.isMobile ? ' p-2 ' : 'p-4']"
          class="text-2xl text-white col-start-2 col-end-3 text-center justify-center fade-in-box flex flex-col"
          (click)="onShowResultsClick()"
        >
          <div *ngIf="isLoading && productsLength" class="loading"></div>
          <span
            [ngClass]="helperService.isMobile ? ' text-base w-32' : helperService.isTabletLandscape ? 'text-xl' : 'text-3xl w-44'"
            *ngIf="productsLength < 1 && !isLoading"
            class="text-mb-2"
            >Kein Gerät <br />
            gefunden</span
          >
          <span
            [ngClass]="helperService.isMobile ? ' text-base' : helperService.isTabletLandscape ? 'text-xl' : 'text-3xl '"
            *ngIf="productsLength === 1 && !isLoading"
            >{{ productsLength }} Gerät</span
          >
          <span
            [ngClass]="helperService.isMobile ? 'text-base' : helperService.isTabletLandscape ? 'text-xl' : 'text-3xl '"
            *ngIf="productsLength > 1 && !isLoading"
            >{{ productsLength }} Geräte</span
          >
          <span
            (click)="onShowResultsClick()"
            [ngClass]="{ 'text-base': helperService.isMobile, 'text-xl': helperService.isTabletLandscape }"
            *ngIf="productsLength >= 1 && !isLoading"
            class="font-bold"
            >Jetzt anzeigen</span
          >
        </div>
      </div>
    </div>

    <div class="mt-32 w-4/5" *ngIf="!helperService.isMobileDevice" [ngClass]="helperService.is4k ? 'pl-1 ' : ''">
      <div class="simple-keyboard"></div>
    </div>
  </div>
</div>
