import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductMenuEntry } from 'src/app/enums/product-menu-entries.model';
import { IProduct } from 'src/app/models/product.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-product-menu',
  templateUrl: './product-menu.component.html',
  styleUrls: ['./product-menu.component.scss'],
})
export class ProductMenuComponent implements OnInit {
  selectedMenuEntry: string = 'Überblick';
  showMenuList = false;

  currentURL!: string;
  @Input() showMenu = false;
  @Input() bgNumber!: string;
  @Input() selectedProduct!: IProduct;
  @Output() selectedMenuEntryChanged = new EventEmitter<string>();
  @Output() scrollToComponent = new EventEmitter<string>();

  menuList = [
    { title: 'Überblick', route: 'produktdetails/ueberblick' },
    { title: 'Highlights', route: 'produktdetails/highlights' },
    { title: 'Produktdetails', route: 'produktdetails/details' },
    { title: 'Passende Produkte', route: 'produktdetails/passende-produkte' },
  ];

  constructor(public helperService: HelperService, private router: Router, public activatedRoute: ActivatedRoute, private location: Location) {}

  open: boolean = false;

  ngOnInit(): void {
    this.getURL();
  }

  private getURL() {
    this.activatedRoute.url.subscribe((url) => {
      this.currentURL = window.location.href;
      this.getSelectedEntry();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showMenuList = false;
  }

  private getSelectedEntry() {
    if (this.currentURL.includes('ueberblick')) {
      this.selectedMenuEntry = ProductMenuEntry.OVERVIEW;
    } else if (this.currentURL.includes('highlights')) {
      this.selectedMenuEntry = ProductMenuEntry.HIGHLIGHTS;
    } else if (this.currentURL.includes('passende-produkte')) {
      this.selectedMenuEntry = ProductMenuEntry.RELATED_PRODUCTS;
    } else if (this.currentURL.includes('produktdetails/details')) {
      this.selectedMenuEntry = ProductMenuEntry.PRODUCT_DETAILS;
    }
  }

  onClickShowMenu(): void {
    this.showMenuList = !this.showMenuList;
  }

  navigateToSubpage(navigationPath: string, selcectedMenuEntry: string) {
    if (this.helperService.isMobile || this.helperService.isMobileDevice) {
      this.scrollToComponent.emit(navigationPath);
      /*       this.router.navigate([`produktdetails`], { fragment: navigationPath }); */
      this.location.replaceState(`${navigationPath}?ean=${this.selectedProduct.ean}&bg=${this.bgNumber}`);
      this.selectedMenuEntry = selcectedMenuEntry;
    } else {
      this.router.navigateByUrl(`${navigationPath}?ean=${this.selectedProduct.ean}&bg=${this.bgNumber}`);
    }
    this.showMenuList = !this.showMenuList;
  }
}
