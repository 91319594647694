<app-background [imgPath]="'assets/img/video-world/sensor-technology/sensor.jpg'"></app-background>
<app-menu [logoPath]="getLogoColor()" [showHome]="true" [showShare]="true" [showBack]="true" [showBurger]="true" [showSearch]="true"></app-menu>
<div
  [ngClass]="
    helperService.isLandscape
      ? 'mt-16 pl-16 w-3/5'
      : helperService.isMobile
      ? 'padding-mobile md:px-8 pb-mobile'
      : helperService.isTabletLandscape
      ? 'mt-12 px-16 w-9/12'
      : 'px-16 mt-24'
  "
>
  <div class="grid grid-cols-12 gap-2" [ngClass]="{ 'bg-blur': isBlurred, 'unscrollable': showVideoOverlay }">
    <div class="col-span-12">
      <p class="text-white text-small mb-2" *ngIf="helperService.isMobile">Intelligente Sensortechnik</p>
      <img
        *ngIf="helperService.isMobile"
        class="object-cover w-full"
        src="assets/img/video-world/sensor-technology/sensor-mobile-header.jpg"
        alt=""
      />
      <div [ngClass]="helperService.isMobile && !helperService.isTablet ? 'p-4 mt-2' : 'p-6'" class="bg-default-col-bg">
        <h1
          [ngClass]="{ 'text-base': helperService.isMobile && !helperService.isTablet, 'text-3xl': helperService.isTabletLandscape }"
          class="mb-2 font-normal text-white"
        >
          Entspannt kochen mit Sensorunterstützung. Du bist kreativ, wir übernehmen den Rest.
        </h1>
        <p [ngClass]="helperService.isMobile ? 'text-xs' : ' text-xl mt-6 mb-10'" class="text-white">
          Du musst dich nicht für Technologie begeistern, nutze sie einfach. Verlass dich auf Sensoren, die selbstständig Dinge erkennen, um die du
          dich nicht mehr kümmern musst. Sensoren, die es ermöglichen auf Veränderungen zu reagieren und so für Konstanz sorgen. Sensoren, die immer
          den perfekten Zeitpunkt erkennen. Für dich der einfache Weg zum perfekten Ergebnis und Zeit für kreatives Kochen.
        </p>
      </div>
    </div>
    <div class="col-span-12">
      <div [ngClass]="helperService.isMobile ? 'grid-cols-2 gap-2' : 'grid-cols-4 gap-4'" class="grid video-container justify-end">
        <div *ngFor="let video of videos; let i = index" style="--animation-order: {{ i + 1 }}" class="fade-in-box" (click)="onVideoClick(video)">
          <img class="" [src]="getImages(video.thumbailLink)" />
          <p [ngClass]="helperService.isMobile ? 'text-small mt-2' : 'bg-default-col-bg px-4 py-2 shadow-md text-xs'" class="text-white max-w">
            {{ video.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-video-world-overlay
  *ngIf="showVideoOverlay"
  (closeVideoOverlay)="closeVideoOverlay()"
  [selectedVideo]="selectedVideo"
  [videos]="videos"
></app-video-world-overlay>
