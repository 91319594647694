import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-sally-video-slider',
  templateUrl: './sally-video-slider.component.html',
  styleUrls: ['./sally-video-slider.component.scss'],
})
export class SallyVideoSliderComponent implements OnInit {
  @Output() onVideoClick = new EventEmitter();
  @Input() videos?: string[];

  constructor(public helperService: HelperService) {}

  ngOnInit(): void {}

  showThisVideo(url: string) {
    this.onVideoClick.emit(url);
  }

  /* Options for the media carousel */
  sallyVideoOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 8,
    navSpeed: 700,
    nav: true,
    navText: ['<img class="arrow" src="assets/icon/icon-arrow-left.svg"/>', '<img class="arrow" src="assets/icon/icon-arrow-right.gif"/>'],
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      740: {
        items: 2,
      },
      940: {
        items: 2,
      },
    },
  };
}
