import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-home-connect-overlay',
  templateUrl: './home-connect-overlay.component.html',
  styleUrls: ['./home-connect-overlay.component.scss'],
})
export class HomeConnectOverlayComponent {
  @Output() closeVideoOverlay = new EventEmitter();
  bgUrl: string = 'assets/img/background_images/category1.png';

  constructor(private router: Router, public helperService: HelperService) {}

  onClickCloseVideoOverlay() {
    this.closeVideoOverlay.emit();
  }

  onProductClick(endpoint: string) {
    this.getBgUrl(endpoint);

    const bgURL = this.helperService.getBgNumber(this.bgUrl);
    this.router.navigateByUrl(`/suchergebnisse?endpoint=${endpoint}&bgUrl=${bgURL}`);
  }

  getBgUrl(endpoint: string) {
    if (endpoint.includes('OVENS,MICROWAVE_OVENS')) this.bgUrl = 'assets/img/background_images/category1.jpg';
    if (endpoint.includes('COOKER_HOB')) this.bgUrl = 'assets/img/background_images/category4.jpg';
    if (endpoint.includes('BUILTIN_CM')) this.bgUrl = 'assets/img/background_images/category3.jpg';
    if (endpoint.includes('FRIDGE_FREEZER_COMBINATIONS')) this.bgUrl = 'assets/img/background_images/category7.jpg';
    if (endpoint.includes('DISHWASHERS')) this.bgUrl = 'assets/img/background_images/category9.jpg';
  }
}
