<div [ngClass]="helperService.isTablet || helperService.isTablet4k || helperService.isTabletLandscape ? '' : 'p-4'" class="cookie-overlay">
  <div class="bg-white shadow-md border border-gray-200 max-w-xl rounded-lg dark:border-gray-700">
    <div [ngClass]="helperService.isTablet4k ? 'p-8' : 'p-6'">
      <div class="flex flex-col gap-6 items-center w-full">
        <img class="logo" src="assets/img/bosch_logo_red.png" alt="" />
        <h1
          class="text-black mb-4"
          [ngClass]="
            helperService.isTablet || helperService.isTabletLandscape ? 'mb-6 text-2xl' : helperService.isTablet4k ? 'mb-8 text-3xl' : 'mb-4 text-2xl'
          "
        >
          Datenschutzeinstellungen
        </h1>
      </div>

      <p
        class="font-normal text-black"
        [ngClass]="
          helperService.isTablet || helperService.isTabletLandscape ? 'text-base mb-6' : helperService.isTablet4k ? 'text-xl mb-8' : 'text-xs mb-6'
        "
      >
        Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben möchten, müssen Sie Ihre Erziehungsberechtigten um
        Erlaubnis bitten. Wir verwenden Cookies und andere Technologien auf unserer Website. Einige von ihnen sind essenziell, während andere uns
        helfen, diese Website und Ihre Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für
        personalisierte Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung. Weitere Informationen über die Verwendung Ihrer Daten finden Sie in
        unserer
        <a class="text-blue-400" href="https://www.bosch-home.com/imprint/data-protection-information" target="_blank">Datenschutzerklärung</a>. Hier
        finden Sie eine Übersicht über alle verwendeten Cookies. Sie können nun im allgemeinen Cookies erlauben oder sie kategorisch ablehnen. Die App
        ist dadurch weiterhin benutzbar, auch wenn das Nutzererlebnis darunter leiden wird.
      </p>
      <div [ngClass]="helperService.isMobile ? 'gap-3' : 'gap-4'" class="flex flex-col w-full">
        <a
          (click)="allowCookies()"
          class="text-white w-full justify-center bg-black font-medium px-3 py-2 text-center inline-flex items-center"
          [ngClass]="helperService.isTablet || helperService.isTablet4k || helperService.isTabletLandscape ? 'text-xl h-16' : 'text-sm'"
        >
          Alle akzeptieren
        </a>
        <a
          (click)="denyCookies()"
          class="text-black w-full justify-center bg-gray-200 font-medium px-3 py-2 text-center inline-flex items-center"
          [ngClass]="helperService.isTablet || helperService.isTablet4k || helperService.isTabletLandscape ? 'text-xl h-16' : 'text-sm'"
        >
          Alle ablehnen
        </a>
      </div>
    </div>
  </div>
</div>
