<div *ngIf="imgPath !== '' && !helperService.isMobile && !switchBackgroundForTablets">
  <img class="h-screen" [src]="getImgPath()" />
</div>

<div *ngIf="helperService.isMobile || switchBackgroundForTablets" class="mobile-background"></div>

<!-- <div class="tablet-landscape-background" *ngIf="helperService.isTabletLandscape"></div> -->

<div *ngIf="videoPath !== '' && !helperService.isMobile && !helperService.isTabletLandscape && !helperService.isMobileDevice" class="video-container">
  <div *ngIf="!helperService.isLandscape" class="aspect-w-9 aspect-h-16">
    <video loop autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" #videoPlayer>
      <source src="{{ getVideoPath() }}" type="video/mp4" />
      Browser not supported
    </video>
  </div>
  <div *ngIf="helperService.isLandscape" class="aspect-w-16 aspect-h-9">
    <video loop autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" #videoPlayer>
      <source src="{{ getVideoPath() }}" type="video/mp4" />
      Browser not supported
    </video>
  </div>
</div>
