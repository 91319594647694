import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAsset } from 'src/app/models/assets.model';
import { IHighlights } from 'src/app/models/highlights.model';
import { IProduct } from 'src/app/models/product.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-highlights-overlay',
  templateUrl: './highlights-overlay.component.html',
  styleUrls: ['./highlights-overlay.component.scss'],
})
export class HighlightsOverlayComponent {
  @Output() closeOverlay = new EventEmitter();
  @Input() selectedProduct!: IProduct;
  @Input() selectedHighlight!: IHighlights;
  @Input() selectedHighlightArray!: IHighlights[];
  @Input() selectedMediaArray!: IAsset[];
  @Input() media!: IAsset;

  constructor(public helperService: HelperService) {}

  getEmbededUrl(url: string) {
    return this.helperService.getEmbededUrl(url);
  }

  getFeatureIcon() {
    this.selectedHighlight.assets.forEach((e) => {
      if (e.category.includes('feature-icon')) {
        return e.url;
      } else return '';
    });
  }

  getHighlightText() {
    if (this.selectedHighlight.short_text) return this.selectedHighlight.short_text;
    else return this.selectedHighlight.long_text;
  }

  onClickCloseOverlay() {
    this.closeOverlay.emit();
  }

  showThisMedia(highlight: IHighlights) {
    this.selectedHighlight = highlight;
  }
}
