<app-background [imgPath]="'assets/img/background_images/home.jpg'"></app-background>
<app-menu [showHome]="true" [showBack]="true" [showSearch]="true"></app-menu>

<!-- For all views except mobile version -->
<!-- For all views except mobile version -->
<!-- For all views except mobile version -->
<div
  *ngIf="!helperService.isMobile && !helperService.isTablet"
  class="text-white mt-24"
  [ngClass]="helperService.isLandscape ? 'pl-16 w-3/5' : 'px-16'"
>
  <div class="mb-3 grid grid-cols-2 auto-rows-fr gap-3">
    <div
      routerLink="/like-a-bosch"
      style="--animation-order: 1"
      [ngClass]="helperService.isTabletLandscape ? 'h-40' : 'h-44'"
      class="p-6 pb-8 bg-default-col-bg fade-in-box cursor-pointer"
    >
      <span>
        <h4 [ngClass]="helperService.isTabletLandscape ? 'text-2xl h-2/5' : 'text-3xl h-3/5'" class="font-bold mb-2 h-3/5">Lebe fantastisch</h4>
        <p class="h-2/5 mb-2">#LikeA<strong>Bosch</strong>.</p>
      </span>
    </div>

    <div
      routerLink="/sallys-highlights"
      style="--animation-order: 2"
      [ngClass]="helperService.isTabletLandscape ? 'h-40' : 'h-44'"
      class="p-6 pb-8 bg-default-col-bg fade-in-box cursor-pointer"
    >
      <span>
        <h4 [ngClass]="helperService.isTabletLandscape ? 'text-2xl h-2/5' : 'text-3xl h-3/5'" class="font-bold mb-2 h-3/5">
          Sally und ihre Highlights
        </h4>
        <p class="h-2/5 mb-2">
          Europas Food-YouTuberin Nr. 1 <br />
          backt und kocht mit Bosch.
        </p>
      </span>
    </div>
  </div>

  <div class="grid grid-cols-3 gap-3">
    <div
      routerLink="/sensortechnologie"
      style="--animation-order: 3"
      [ngClass]="helperService.isTabletLandscape ? 'h-40' : 'h-44'"
      class="p-6 pb-8 bg-default-col-bg fade-in-box cursor-pointer"
    >
      <span>
        <h4 [ngClass]="helperService.isTabletLandscape ? 'text-2xl h-2/5' : 'text-3xl h-3/5'" class="font-bold mb-2 h-3/5">Überraschend einfach</h4>
        <p class="h-2/5 mb-2">Intelligente Sensortechnik für perfekte Ergebnisse.</p>
      </span>
    </div>

    <div
      routerLink="/vernetztes-zuhause"
      style="--animation-order: 4"
      [ngClass]="helperService.isTabletLandscape ? 'h-40' : 'h-44'"
      class="p-6 pb-8 bg-default-col-bg fade-in-box cursor-pointer"
    >
      <span>
        <h4 [ngClass]="helperService.isTabletLandscape ? 'text-2xl h-2/5' : 'text-3xl h-3/5'" class="font-bold mb-2 h-3/5">
          Deine smart vernetzte Küche
        </h4>
        <p class="h-2/5 mb-2">Home Connect.</p>
      </span>
    </div>

    <div
      routerLink="/alle-funktionen"
      style="--animation-order: 5"
      [ngClass]="helperService.isTabletLandscape ? 'h-40' : 'h-44'"
      class="p-6 pb-8 bg-default-col-bg fade-in-box cursor-pointer"
    >
      <span>
        <h4 [ngClass]="helperService.isTabletLandscape ? 'text-2xl h-2/5' : 'text-3xl h-3/5'" class="font-bold mb-2 h-3/5">Schnell und praktisch</h4>
        <p class="h-2/5 mb-2">Alle Funktionen im Überblick.</p>
      </span>
    </div>

    <!-- <div routerLink="/video-library" style="--animation-order: 6" class="p-6 pb-8 bg-default-col-bg fade-in-box h-44 cursor-pointer">
      <span>
        <h4 class="font-bold  text-3xl mb-2 h-3/5">Kino - Kompakt informiert</h4>
        <p class=" h-2/5 mb-2">Produktvideos, Rezeptvideos, TV-Spots</p>
      </span>
    </div> -->
  </div>
</div>

<!-- Only for mobile and tablet version -->
<!-- Only for mobile and tablet version -->
<!-- Only for mobile and tablet version -->
<div routerLink="/like-a-bosch" *ngIf="helperService.isMobile" class="text-white padding-mobile md:px-8 pb-mobile grid gap-2">
  <div>
    <img [ngClass]="{ 'object-cover w-full': helperService.isTablet }" src="assets/img/video-world/overview/likeABosch-header.jpg" alt="" />
    <div style="--animation-order: 1" class="p-4 h-auto bg-default-col-bg fade-in-box cursor-pointer">
      <span>
        <h4 class="font-bold mb-2">Lebe fantastisch</h4>
        <p class="text-xs">#LikeA<strong>Bosch</strong>.</p>
      </span>
    </div>
  </div>

  <div routerLink="/sallys-highlights" class="grid grid-cols-2 gap-2">
    <div>
      <img
        [ngClass]="helperService.isTablet ? 'h-56' : 'h-32'"
        class="object-cover w-full"
        src="assets/img/video-world/overview/sally-section.jpg"
        alt=""
      />
      <div style="--animation-order: 2" class="p-4 h-36 bg-default-col-bg fade-in-box cursor-pointer">
        <span>
          <h4 class="font-bold mb-2">Sally und ihre Highlights</h4>
          <p class="text-xs">Europas Food-YouTuberin Nr. 1 backt und kocht mit Bosch.</p>
        </span>
      </div>
    </div>

    <div routerLink="/sensortechnologie">
      <img
        [ngClass]="helperService.isTablet ? '  h-56' : 'h-32'"
        class="object-cover w-full"
        src="assets/img/video-world/overview/sensor-section.jpg"
        alt=""
      />
      <div style="--animation-order: 3" class="p-4 h-36 bg-default-col-bg fade-in-box cursor-pointer">
        <span>
          <h4 class="font-bold mb-2">Überraschend einfach</h4>
          <p class="text-xs">Intelligente Sensortechnik für perfekte Ergebnisse.</p>
        </span>
      </div>
    </div>
  </div>

  <div routerLink="/vernetztes-zuhause" class="grid grid-cols-2 gap-2">
    <div>
      <img
        [ngClass]="helperService.isTablet ? '  h-56' : 'h-32'"
        class="object-cover w-full"
        src="assets/img/video-world/overview/home-connect-section.jpg"
        alt=""
      />
      <div style="--animation-order: 4" class="p-4 h-36 bg-default-col-bg fade-in-box cursor-pointer">
        <span>
          <h4 class="font-bold mb-2">Deine smart vernetzte Küche</h4>
          <p class="text-xs">Home Connect.</p>
        </span>
      </div>
    </div>

    <div routerLink="/alle-funktionen">
      <img
        [ngClass]="helperService.isTablet ? '  h-56' : 'h-32'"
        class="object-cover w-full"
        src="assets/img/video-world/overview/all-features-section.jpg"
        alt=""
      />
      <div style="--animation-order: 5" class="p-4 h-36 bg-default-col-bg fade-in-box cursor-pointer">
        <span>
          <h4 class="font-bold mb-2">Schnell und praktisch</h4>
          <p class="text-xs">Alle Funktionen im Überblick.</p>
        </span>
      </div>
    </div>
  </div>
</div>
