<app-background [imgPath]="'assets/img/background_images/likeabosch.jpg'"></app-background>
<app-menu [logoPath]="getLogoColor()" [showBurger]="true" [showHome]="true" [showShare]="true" [showBack]="true" [showSearch]="true"></app-menu>
<div [ngClass]="helperService.isLandscape ? 'pl-16 w-3/5 mt-24' : helperService.isMobile ? 'padding-mobile md:px-8 pb-40' : 'px-16 mt-24'">
  <div [ngClass]="isBlurred ? 'grid grid-cols-12 gap-2 bg-blur' : helperService.isMobile ? 'flex flex-col' : 'grid grid-cols-12 gap-2'">
    <h3 *ngIf="helperService.isMobile" class="mb-2 text-white">#LikeA<b>Bosch</b></h3>
    <img *ngIf="helperService.isMobile" class="object-cover w-full" src="assets/img/video-world/overview/likeABosch-header.jpg" alt="" />
    <div [ngClass]="helperService.isMobile ? 'p-3 mt-2' : 'p-6'" class="col-span-12 bg-default-col-bg mb-3">
      <div [ngClass]="{ 'flex gap-2': helperService.isMobile }">
        <h1 [ngClass]="{ 'text-base': helperService.isMobile && !helperService.isTablet }" class="mb-2 font-normal text-white">Lebe fantastisch</h1>
        <h1 [ngClass]="{ 'text-base': helperService.isMobile && !helperService.isTablet }" class="text-white font-normal">
          #LikeA<strong>Bosch</strong>
        </h1>
      </div>

      <p [ngClass]="helperService.isMobile ? 'text-xs m-0 mt-2' : 'text-xl mt-4 mb-4'" class="text-white">
        Unsere Bosch Hausgeräte machen den Alltag einfacher, nachhaltiger und gesünder. Kurz gesagt: einfach fantastisch.
      </p>
    </div>
    <div [ngClass]="helperService.isTabletLandscape ? 'col-span-5' : 'col-span-3'">
      <div
        [ngClass]="
          helperService.isMobile ? 'flex gap-2' : helperService.isTabletLandscape ? 'grid grid-cols-2 gap-4' : 'grid grid-cols-1 gap-4 justify-end'
        "
      >
        <div
          class="text-white fade-in-box"
          (click)="onVideoClick(video)"
          *ngFor="let video of videos; let i = index"
          style="--animation-order:{{ i + 1 }}"
        >
          <img [ngClass]="helperService.is4k && helperService.isLandscape ? 'w-full' : ''" [src]="video.thumbailLink" />
          <div
            [ngClass]="{ 'bg-default-col-bg text-sm shadow-md py-2 px-4': !helperService.isMobile, 'h-28': helperService.isTabletLandscape }"
            class="flex flex-wrap max-w"
          >
            <p [ngClass]="{ 'text-small mt-2': helperService.isMobile }">
              {{ video.title | split: 'ABosch':0 }}A<strong>{{ video.title | split: 'LikeA':1 }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-video-world-overlay
    *ngIf="showVideoOverlay"
    (closeVideoOverlay)="closeVideoOverlay()"
    [selectedVideo]="selectedVideo"
    [videos]="videos"
  ></app-video-world-overlay>
</div>
