import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICategory } from 'src/app/models/category.model';
import { ICatFilter } from 'src/app/models/catFilter.model';
import { HelperService } from 'src/app/services/helper.service';
import { MockdataService } from 'src/app/services/mockdata.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-world',
  templateUrl: './product-world.component.html',
  styleUrls: ['./product-world.component.scss'],
})
export class ProductWorldComponent implements OnInit {
  categories: ICategory[] = [];
  titles: string[] = [];

  constructor(private mockService: MockdataService, private router: Router, public helperService: HelperService) {}

  ngOnInit(): void {
    // depending on current environment either load mock data or productive data
    if (environment.useMockData) {
      this.mockService.getAllCategories().then((res) => {
        this.categories = res.categories as ICategory[];
      });
    }
    // clear local storage to delete the cat filter history but keeps cookie-collection status
    const cookieStatus = localStorage.getItem('cookieStatus');
    localStorage.clear();
    if (cookieStatus && cookieStatus === 'allow') this.helperService.setCookieStatus(true);
    else this.helperService.setCookieStatus(false);
  }

  getVisibleCat() {
    return this.categories.filter((x) => x.level === 0);
  }

  onCategoryClick(cat: ICategory) {
    // get child categories of clicked category
    const childs = this.categories.filter((x) => x.parentId === cat.id);
    if (childs.length > 1) {
      const catFilter: ICatFilter = {
        categories: this.categories,
        childCat: childs,
        lastParentId: cat.id,
        curCatLevel: 1,
        colAmount: childs.length > 2 ? 3 : 2,
        showVideo: childs.filter((x) => x.showVideo).length > 0 ? true : false,
        showResultBtn: childs[0].showChoiceBadge,
        showSally: false,
        bgUrl: this.getBackgroundUrl(cat.id),
        mobileImageUrl: this.getMobileImageUrl(cat.id),
        apiCatTitle: cat.apiCatTitle,
        alignRight: cat.alignRight,
        title: cat.title,
      };
      this.helperService.updateLastCatState(catFilter);
      this.router.navigateByUrl('/produktauswahl', { state: { catData: catFilter } });
      this.helperService.setLastCatTitle(JSON.stringify(catFilter.title));
    } else {
      this.navigateToResultPage(cat);
    }
  }

  // navigates to result page and stores result data in local storage
  navigateToResultPage(cat: ICategory) {
    this.router.navigateByUrl(`/suchergebnisse?endpoint=${cat.endpoint}&bgUrl=${cat.id}`);
  }

  getBackgroundImageUrl(): string {
    if (this.helperService.isMobile) return 'assets/img/background_images_low_res/category2';
    else return 'assets/img/background_images/kuechensortiment';
  }

  getLogoColor(): string {
    if (this.helperService.isMobile) return 'assets/img/bosch_logo.png';
    else return 'assets/img/bosch_logo_red.svg';
  }

  getBackgroundUrl(parentId: number): string {
    return 'assets/img/background_images/category' + parentId + '.jpg';
  }

  private getMobileImageUrl(parentId: number): string {
    return 'assets/img/background_images/category' + parentId + '-mobile-header.jpg';
  }
}
