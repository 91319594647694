<!-- Content for all views except mobile version -->
<!-- Content for all views except mobile version -->
<!-- Content for all views except mobile version -->
<div class="px-16 tabletHeight" *ngIf="selectedProduct && !helperService.isMobile" [ngClass]="helperService.isLandscape ? 'w-3/5' : ''">
  <h1 class="text-white mb-8" [ngClass]="helperService.isTabletLandscape ? 'text-3xl' : ''" *ngIf="helperService.isMobileDevice">Produktdetails</h1>
  <div
    class="grid grid-cols-3 gap-4 pr-4 items-start bosch-scrollbar"
    [ngClass]="helperService.isLandscape ? 'feature-list-landscape' : 'feature-list '"
  >
    <div
      class="col-start-1 row-start-1 row-span-6"
      [ngClass]="
        helperService.isLandscape
          ? 'absolute custom-width-landscape'
          : helperService.isTablet4k || helperService.isTabletLandscape
          ? 'relative'
          : 'absolute custom-width'
      "
    >
      <ul class="text-white">
        <ng-container *ngFor="let category of getCategories(); let first = first">
          <li
            id="{{ category }}"
            [ngClass]="{ 'font-bold': first, 'text-xs': helperService.isTablet }"
            class="text-white productdetail"
            (click)="filterByCategory(category)"
          >
            {{ category | split: ':':0 }}<img src="assets/icon/pfeil.svg" alt="-" class="indicator" />
          </li>
        </ng-container>
      </ul>
    </div>
    <ng-container *ngFor="let detail of detailsFiltered">
      <ng-container *ngFor="let line of detail.lines; let i = index; let even = even; let odd = odd">
        <div [ngClass]="{ 'col-start-3': odd, 'col-start-2': even }" class="mt-2 pb-2 gap-x-2">
          <h3 [ngClass]="{ 'text-xs': helperService.isTablet }" class="text-white font-bold">{{ line | split: ':':0 }}</h3>
          <p [ngClass]="{ 'text-xs mt-2': helperService.isTablet }" class="text-white">{{ line | split: ':':1 }}</p>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- Accordion for mobile version -->
<!-- Accordion for mobile version -->
<!-- Accordion for mobile version -->
<div *ngIf="selectedProduct && helperService.isMobile" class="product-details padding-mobile md:px-8">
  <h1 class="text-white mb-8 text-lg" *ngIf="helperService.isMobile">Produktdetails</h1>
  <div class="items-start bosch-scrollbar flex flex-col gap-1 feature-list">
    <div class="col-start-1 row-start-1 row-span-6 w-full mb-8">
      <ul class="text-white">
        <ng-container *ngFor="let category of getCategories(); let first = first">
          <li
            id="{{ category }}"
            [ngClass]="{ 'font-bold': toggledCategories.includes(category) }"
            class="text-white productdetail text-xs"
            (click)="filterByCategory(category)"
          >
            {{ category | split: ':':0
            }}<img [ngClass]="{ 'rotate-indicator': toggledCategories.includes(category) }" src="assets/icon/pfeil.svg" alt="-" class="indicator" />
          </li>
          <div [ngClass]="toggledCategories.includes(category) ? 'block' : 'hidden'" class="detail-container pt-2 pb-2">
            <ng-container *ngFor="let detail of detailsFilteredByCategory(category)">
              <ng-container *ngFor="let line of detail.lines; let i = index; let even = even; let odd = odd">
                <div [ngClass]="{ 'col-start-3': odd, 'col-start-2': even }" class="mt-2 pb-2 gap-x-2">
                  <h3 class="text-white font-bold mt-0 text-xs">{{ line | split: ':':0 }}</h3>
                  <p class="text-white text-xs">{{ line | split: ':':1 }}</p>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
