import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { IAsset } from 'src/app/models/assets.model';
import { IProduct } from 'src/app/models/product.model';
import { IVideo } from 'src/app/models/video.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-media-slider',
  templateUrl: './media-slider.component.html',
  styleUrls: ['./media-slider.component.scss'],
})
export class MediaSliderComponent implements OnInit {
  @Output() mediaChanged = new EventEmitter();
  @Input() selectedProduct!: IProduct;
  @Input() selectedMediaArray!: IAsset[];
  @Input() videos!: IVideo[];
  showMediaOverlay = false;
  constructor(private helperService: HelperService) {}

  ngOnInit(): void {}

  getThumbnail(url: string) {
    return this.helperService.getThumbnail(url);
  }

  showThisMedia(media: IAsset) {
    this.mediaChanged.emit(media);
  }

  /* Options for the media carousel */
  mediaOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 8,
    navSpeed: 700,
    navText: ['<img class="arrow" src="assets/icon/icon-arrow-left.svg"/>', '<img class="arrow" src="assets/icon/icon-arrow-right.gif"/>'],
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      740: {
        items: 2,
      },
      940: {
        items: 2,
      },
    },
    nav: true,
  };
}
