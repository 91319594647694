import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IVideo } from 'src/app/models/video.model';
import { HelperService } from 'src/app/services/helper.service';
import { MockdataService } from 'src/app/services/mockdata.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-like-a-bosch',
  templateUrl: './like-a-bosch.component.html',
  styleUrls: ['./like-a-bosch.component.scss'],
})
export class LikeABoschComponent implements OnInit {
  blurredSub: Subscription;
  videos: IVideo[] = [];
  selectedVideo!: IVideo;
  showVideoOverlay = false;
  showMoreInformation = false;
  isBlurred = false;

  ngOnInit(): void {}
  constructor(private mockService: MockdataService, public helperService: HelperService) {
    if (environment.useMockData) {
      this.mockService.getLikeABoschVideos().then((res) => {
        this.videos = res.videos as IVideo[];
      });
    }

    // blur background if a video is clicked
    this.blurredSub = this.helperService.backgroundBlur.subscribe((blurred: boolean) => {
      this.isBlurred = blurred;
    });
  }
  onVideoClick(video: IVideo): void {
    this.selectedVideo = video;
    this.showVideoOverlay = true;
    this.helperService.updatedBackgroundBlur(true);
  }

  closeVideoOverlay(): void {
    this.showVideoOverlay = false;
    this.helperService.updatedBackgroundBlur(false);
  }

  getLogoColor(): string {
    if (this.helperService.isMobile) return 'assets/img/bosch_logo.png';
    else return 'assets/img/bosch_logo_red.svg';
  }

  ngOnDestroy() {
    if (this.blurredSub) this.blurredSub.unsubscribe();
  }
}
