<app-screensaver-notice
  *ngIf="showScreensaverNotice && !helperService.isMobile && !helperService.isTablet4k && !helperService.isTabletLandscape"
></app-screensaver-notice>
<app-cookie-overlay (closeCookieOverlay)="closeCookieOverlay()" *ngIf="helperService.showCookieOverlay"></app-cookie-overlay>
<app-search-overlay *ngIf="helperService.showSearchOverlay"></app-search-overlay>
<div class="min-h-screen relative">
  <router-outlet *ngIf="!helperService.showSearchOverlay"></router-outlet>
  <app-footer *ngIf="helperService.isMobileDevice"></app-footer>
</div>

<div *ngIf="!helperService.showCookieOverlay && helperService.isMobileDevice" (click)="openCookieSettings()" class="cookie-settings">
  Cookie-Einstellungen
</div>
