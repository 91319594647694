<app-background [imgPath]="'assets/img/video-world/all-features/all-features.jpg'"></app-background>

<app-menu [showSearch]="true" [logoPath]="getLogoColor()" [showBurger]="true" [showBack]="true" [showHome]="true"></app-menu>
<div
  [ngClass]="
    helperService.isLandscape
      ? 'pl-16 w-3/5 mt-24'
      : helperService.isMobile
      ? 'padding-mobile md:px-8 pb-mobile'
      : helperService.isTabletLandscape
      ? 'w-9/12 px-16 mt-16'
      : 'px-16 mt-24'
  "
>
  <div [ngClass]="helperService.isMobile ? 'grid-cols-1 gap-2 mb-2' : ' grid-cols-2 gap-3'" class="grid">
    <p *ngIf="helperService.isMobile" class="m-0 text-white text-small">Funktionen</p>
    <img class="object-cover w-full" *ngIf="helperService.isMobile" src="assets/img/video-world/all-features/all-features-mobile.jpg" alt="" />
    <div
      (click)="filterByCategory('OVENS,MICROWAVE_OVENS,STEAM_OVENS,COMBI_STEAM_OVEN')"
      style="--animation-order: 1"
      class="box-effekt-1 category-col bg-default-col-bg fade-in-box"
    >
      <span [ngClass]="{ 'text-base': helperService.isMobile }">Backöfen</span>
    </div>
    <div (click)="filterByCategory('HOBS,VENTING_HOBS')" style="--animation-order: 2" class="box-effekt-1 category-col bg-default-col-bg fade-in-box">
      <span [ngClass]="{ 'text-base': helperService.isMobile }">Kochfelder</span>
    </div>
  </div>
  <div [ngClass]="helperService.isMobile ? 'grid-cols-1 gap-2' : 'grid-cols-3 gap-3'" (click)="filterByCategory('HOODS')" class="grid">
    <div
      style="--animation-order: 3"
      class="box-effekt-1 bg-default-col-bg fade-in-box"
      [ngClass]="helperService.isMobile ? 'p-4 font-bold text-white' : ' category-col'"
    >
      <span [ngClass]="{ 'text-base': helperService.isMobile }">Dunstabzüge</span>
    </div>
    <div
      (click)="filterByCategory('FRIDGE_FREEZER_COMBINATIONS,REFRIGERATORS,FREEZERS')"
      style="--animation-order: 4"
      class="box-effekt-1 bg-default-col-bg fade-in-box"
      [ngClass]="helperService.isMobile ? 'p-4 font-bold text-white' : 'category-col'"
    >
      <span [ngClass]="{ 'text-base': helperService.isMobile }">Kühl- und Gefriergeräte</span>
    </div>
    <div
      (click)="filterByCategory('DISHWASHERS')"
      style="--animation-order: 5"
      class="box-effekt-1 bg-default-col-bg fade-in-box"
      [ngClass]="helperService.isMobile ? 'p-4 font-bold text-white' : 'category-col'"
    >
      <span [ngClass]="{ 'text-base': helperService.isMobile }">Geschirrspüler</span>
    </div>
  </div>
</div>
