// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useMockData: true,
  screensaverOn: true,
  inetCheckUrl: 'https://jsonplaceholder.typicode.com/todos/1',
  apiUrl: 'https://hpl-bsh-backend.loehr-partner.de/api/v1/',
  videoWorldUrls: {
    likeABoschUrl: 'https://www.bosch-home.com/de/likeabosch',
    sallysHighlightsUrl: 'https://www.bosch-home.com/de/bosch-erleben/sally-bosch',
    sensorTechnologieUrl: 'https://www.bosch-home.com/de/bosch-erleben/sensortechnologie',
    homeConnectUrl: 'https://www.bosch-home.com/de/bosch-erleben/homeconnect',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
