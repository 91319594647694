<app-background [imgPath]="'assets/img/background_images/sally'"></app-background>
<app-menu [logoPath]="getLogoColor()" [showBurger]="true" [showHome]="true" [showShare]="true" [showBack]="true" [showSearch]="true"></app-menu>
<div
  [ngClass]="
    showVideoOverlay
      ? 'unscrollable'
      : helperService.isLandscape
      ? 'pl-16 w-3/5 mt-24'
      : helperService.isTabletLandscape
      ? 'mt-16 px-16'
      : helperService.isMobile
      ? 'padding-mobile md:px-8 pb-mobile'
      : 'px-16 mt-24'
  "
>
  <div
    [ngClass]="[
      helperService.isMobile ? 'flex flex-col' : helperService.isTabletLandscape ? 'flex w-4/5 gap-4' : 'grid grid-cols-12',
      isBlurred ? 'bg-blur' : ''
    ]"
  >
    <div class="col-span-7">
      <p *ngIf="helperService.isMobile" class="text-small text-white mb-2">Sally und ihre Highlights</p>
      <img *ngIf="helperService.isMobile" class="object-cover w-full" src="assets/img/video-world/sally/sally-bosch.jpg" alt="" />
      <div [ngClass]="{ 'mt-2 mb-2': helperService.isMobile }" class="block p-6 bg-default-col-bg">
        <h1 [ngClass]="{ 'text-base': helperService.isMobile && !helperService.isTablet }" class="mb-2 font-normal text-white">
          Willkommen in meiner Bosch Küche.
        </h1>
        <p [ngClass]="helperService.isMobile ? 'text-xs' : 'text-xl mt-6 mb-10'" class="text-white">
          Hallo, ich bin's: eure Sally. Ich bin begeistert von meinen Bosch Geräten: Die Technik ist spitze und die Bedienung kinderleicht. Ich koche
          und backe fast täglich damit – und mir gelingt einfach alles. Wie du mit den Hausgeräten von Bosch ganz einfach zum perfekten Ergebnis
          kommt, zeige ich dir in meinen kurzen Videos. Viel Spaß!
        </p>
      </div>
    </div>

    <div [ngClass]="helperService.isMobile ? 'grid grid-cols-2' : 'grid grid-rows-4 col-start-10 col-end-13'" class="gap-2 justify-end">
      <div
        *ngIf="!showVideoOverlay"
        (click)="getSallyVideos('sally', 14)"
        class="fade-in-box"
        [ngClass]="{ 'w-4/5': helperService.isTabletLandscape }"
        style="--animation-order: 1"
      >
        <img *ngIf="helperService.isMobile" src="assets/img/video-world/sally/sally-backofen-mobile.jpg" />
        <img *ngIf="!helperService.isMobile" src="assets/img/video-world/sally/sally-backofen.webp" />
        <p
          [ngClass]="
            helperService.isMobile
              ? 'text-small mt-2'
              : helperService.isTabletLandscape
              ? 'text-xs bg-default-col-bg p-2'
              : 'bg-default-col-bg font-bold py-2 px-4 text-xs'
          "
          class="text-white m-0"
        >
          Sally erklärt die Bosch Backöfen und Dampfbacköfen
        </p>
      </div>

      <div
        *ngIf="!showVideoOverlay"
        (click)="getSallyVideos('sally', 11)"
        class="fade-in-box"
        [ngClass]="{ 'w-4/5': helperService.isTabletLandscape }"
        style="--animation-order: 2"
      >
        <img *ngIf="helperService.isMobile" src="assets/img/video-world/sally/sally-dunstabzug-mobile.jpg" />
        <img *ngIf="!helperService.isMobile" src="assets/img/video-world/sally/sally-dunstabzug.webp" />
        <p
          [ngClass]="
            helperService.isMobile
              ? 'text-small mt-2'
              : helperService.isTabletLandscape
              ? 'text-xs bg-default-col-bg p-2'
              : 'bg-default-col-bg font-bold  py-2 px-4 text-xs'
          "
          class="text-white m-0"
        >
          Sallys Highlights bei Bosch Kochfeldern und Dunstabzügen
        </p>
      </div>

      <div
        *ngIf="!showVideoOverlay"
        (click)="getSallyVideos('sally', 1)"
        class="fade-in-box"
        [ngClass]="{ 'w-4/5': helperService.isTabletLandscape }"
        style="--animation-order: 3"
      >
        <img *ngIf="helperService.isMobile" src="assets/img/video-world/sally/sally-kuehlschrank-mobile.jpg" />
        <img *ngIf="!helperService.isMobile" src="assets/img/video-world/sally/sally-kuehlschrank.webp" />
        <p
          [ngClass]="
            helperService.isMobile
              ? 'text-small mt-2'
              : helperService.isTabletLandscape
              ? 'text-xs bg-default-col-bg p-2'
              : 'bg-default-col-bg font-bold py-2 px-4 text-xs'
          "
          class="text-white m-0"
        >
          Sally stellt Bosch Geschirrspüler und Kühl- und Gefriergeräte vor
        </p>
      </div>

      <div
        *ngIf="!showVideoOverlay"
        (click)="getSallyVideos('sally', 17)"
        class="fade-in-box"
        [ngClass]="{ 'w-4/5': helperService.isTabletLandscape }"
        style="--animation-order: 4"
      >
        <img *ngIf="helperService.isMobile" src="assets/img/video-world/sally/sally-home-connect-mobile.jpg" />
        <img *ngIf="!helperService.isMobile" src="assets/img/video-world/sally/sally-home-connect.webp" />
        <p
          [ngClass]="
            helperService.isMobile
              ? 'text-small mt-2'
              : helperService.isTabletLandscape
              ? 'text-xs bg-default-col-bg p-2'
              : 'bg-default-col-bg font-bold py-2 px-4 text-xs'
          "
          class="text-white m-0"
        >
          Sally erklärt Home Connect
        </p>
      </div>
    </div>
  </div>

  <app-video-library-overlay
    *ngIf="showVideoOverlay"
    (closeVideoOverlay)="closeVideoOverlay()"
    [selectedVideo]="selectedVideo"
    [videos]="videos"
  ></app-video-library-overlay>
</div>
