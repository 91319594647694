<app-background [imgPath]="getBackgroundImageUrl()"></app-background>

<app-menu logoPath="assets/img/bosch_logo.png" [showHome]="true" [showSearch]="true"></app-menu>
<div [ngClass]="helperService.isMobile ? 'pb-mobile padding-mobile md:px-8' : 'lg:px-16 lg:mt-28'" class="mt-10">
  <div [ngClass]="{ 'lg:grid-cols-2': !helperService.isMobile }" class="grid gap-2">
    <div *ngIf="helperService.isMobile" [ngClass]="helperService.isTablet ? 'h-96' : 'h-40'">
      <img class="h-full w-full object-cover" src="assets/img/background_images/home-mobile.jpg" alt="" />
    </div>
    <a
      routerLink="/unsere-produkte"
      style="--animation-order: 0"
      class="text-white fade-in-box p-8 bg-default-col-bg shadow-md mobile-full-width"
      [ngClass]="
        helperService.isLandscape
          ? 'pb-16'
          : helperService.isMobile && !helperService.isTablet
          ? 'pb-8 h-44'
          : helperService.isTablet
          ? 'h-44'
          : helperService.isTabletLandscape
          ? 'pb-8'
          : 'pb-32'
      "
    >
      <h1 class="mb-2 font-bold">
        Premium- <br />
        Küchensortiment
      </h1>
      <p [ngClass]="helperService.isMobile ? 'text-base' : 'text-xl'" class="mt-6">Erlebe unsere accent line.</p>
    </a>
    <a
      routerLink="/bosch-entdecken"
      style="--animation-order: 1"
      class="fade-in-box p-8 text-white bg-default-col-bg shadow-md"
      [ngClass]="
        helperService.isLandscape
          ? 'pb-16'
          : helperService.isMobile && !helperService.isTablet
          ? 'pb-8 h-44'
          : helperService.isTablet
          ? 'h-44'
          : helperService.isTabletLandscape
          ? 'pb-8'
          : 'pb-32'
      "
    >
      <h1 class="mb-2 font-bold">
        Bosch <br *ngIf="!helperService.isMobile" />
        erleben
      </h1>
      <p [ngClass]="helperService.isMobile ? 'text-base' : 'text-xl'" class="mt-6">Entdecke unsere Highlights.</p>
    </a>
  </div>
</div>
