import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IOfferings } from 'src/app/models/offerings-model';

@Component({
  selector: 'app-offerings-filter',
  templateUrl: './offerings-filter.component.html',
  styleUrls: ['./offerings-filter.component.scss'],
})
export class OfferingsFilterComponent implements OnInit {
  filteredCategory!: IOfferings[];
  bgUrl: string = 'assets/img/background_images/category1.png';

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.filteredCategory = window.history.state.filteredByParentCategory;
    this.setBgUrl(this.filteredCategory[0]['parentCategory']);
  }

  /**
   * when clicked user is navigated to result page which show all products with the given sub category
   * @param cat clicked category and its data
   */
  onCategoryClick(cat: IOfferings) {
    const filteredBySpecificCategory = this.filteredCategory.filter((article) => article.category === cat.category);
    this.router.navigate(['/offerings-result'], {
      state: { filteredBySpecificCategory: filteredBySpecificCategory[0], bgUrl: this.bgUrl },
    });
  }

  /**
   * sets the backgroundUrl depending on the parent category of the article
   * @param category string parent category
   */
  setBgUrl(category: string) {
    switch (category) {
      case 'Backofen':
        this.bgUrl = 'assets/img/background_images/category1.jpg';
        break;
      case 'Einbau-Mikrowellen':
        this.bgUrl = 'assets/img/background_images/category2.jpg';
        break;
      case 'Herd':
        this.bgUrl = 'assets/img/background_images/category5.jpg';
        break;
      case 'Kochfelder':
        this.bgUrl = 'assets/img/background_images/category4.jpg';
        break;
      case 'Wandessen':
        this.bgUrl = 'assets/img/background_images/category6.jpg';
        break;
      case 'Inselessen':
        this.bgUrl = 'assets/img/background_images/category6.jpg';
        break;
      case 'Sonderzubehör':
        this.bgUrl = 'assets/img/background_images/category1.jpg';
        break;
      case 'Kühl-/ Gefrierkombination':
        this.bgUrl = 'assets/img/background_images/category7.jpg';
        break;
      case 'Kühlgeräte':
        this.bgUrl = 'assets/img/background_images/category7.jpg';
        break;
      case 'Geschirrspüler':
        this.bgUrl = 'assets/img/background_images/category9.jpg';
        break;
      default:
        this.bgUrl = 'assets/img/background_images/category1.jpg';
        break;
    }
  }
}
