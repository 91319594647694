import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IProduct } from 'src/app/models/product.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss'],
})
export class RelatedProductsComponent implements OnInit {
  selectedProduct!: IProduct;
  productSubscription!: Subscription;
  constructor(public helperService: HelperService) {}

  ngOnInit(): void {
    this.getProduct();
  }

  private getProduct() {
    this.productSubscription = this.helperService.prodSubject.subscribe((product: IProduct) => {
      this.selectedProduct = product;
    });
  }

  ngOnDestroy() {
    if (this.productSubscription) this.productSubscription.unsubscribe();
  }
}
