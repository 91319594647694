<app-background [imgPath]="bgUrl"></app-background>

<app-menu [logoPath]="'assets/img/bosch_logo_red.svg'" [showBack]="true" [showHome]="true" [showSearch]="true"></app-menu>
<div class="px-16 xl:px-0 xl:pl-16 xl:w-1/2 mt-32 xl:mt-36">
  <div class="grid grid-cols-3 xl:grid-cols-2 gap-2">
    <div
      *ngFor="let cat of filteredCategory; let i = index"
      style="--animation-order:{{ i }}"
      (click)="onCategoryClick(cat)"
      class="box-effekt-1 category-col bg-default-col-bg fade-in-box"
    >
      <div class="category-title">
        <div>{{ cat.category }}</div>
      </div>
    </div>
  </div>
</div>
