import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-choice-badge',
  templateUrl: './choice-badge.component.html',
  styleUrls: ['./choice-badge.component.scss']
})
export class ChoiceBadgeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
