import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { IAccessories } from 'src/app/models/accessories.model';
import { IProduct } from 'src/app/models/product.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-related-products-slider',
  templateUrl: './related-products-slider.component.html',
  styleUrls: ['./related-products-slider.component.scss'],
})
export class RelatedProductsSliderComponent implements OnInit {
  constructor(public helperService: HelperService) {}
  @Input() selectedProduct!: IProduct;
  showRelatedProductOverlay = false;
  selectedRelatedProduct!: IAccessories;
  isLoading: boolean = true;
  ngOnInit(): void {}

  onImageLoaded() {
    this.isLoading = false;
  }

  openRelatedProductOverlay(relatedProduct: IAccessories) {
    this.selectedRelatedProduct = relatedProduct;
    this.showRelatedProductOverlay = true;
  }

  closeRelatedProductOverlay(): void {
    this.showRelatedProductOverlay = false;
    this.helperService.updatedBackgroundBlur(false);
  }

  getPicture(accessory: IAccessories) {
    if (accessory.lowResPicture) return accessory.lowResPicture;
    else return accessory.highResPicture;
  }

  navigateToBoschHomepage(relatedProduct: IAccessories) {
    const url = 'https://www.bosch-home.com/de/shop/' + relatedProduct.vib;
    window.open(url, '_blank');
  }

  // PORTRAIT SLIDER
  relatedOption: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 8,
    navSpeed: 700,
    navText: ['<img class="arrow" src="assets/icon/icon-arrow-left.svg"/>', '<img class="arrow" src="assets/icon/icon-arrow-right.gif"/>'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 4,
      },
      940: {
        items: 4,
      },
      1280: {
        items: 4,
      },
      2000: {
        items: 4,
      },
    },
    nav: true,
  };

  // LANDSCAPE SLIDERS
  relatedOptionLarge: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 8,
    navSpeed: 700,
    navText: ['<img class="arrow" src="assets/icon/icon-arrow-left.svg"/>', '<img class="arrow" src="assets/icon/icon-arrow-right.gif"/>'],
    responsive: {
      0: {
        items: 3,
      },
      400: {
        items: 3,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
      1280: {
        items: 3,
      },
      2000: {
        items: 3,
      },
    },
    nav: true,
  };
  relatedOptionLarge2: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 8,
    startPosition: 3,
    navSpeed: 700,
    navText: ['<img class="arrow" src="assets/icon/icon-arrow-left.svg"/>', '<img class="arrow" src="assets/icon/icon-arrow-right.gif"/>'],
    responsive: {
      0: {
        items: 3,
      },
      400: {
        items: 3,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
      1280: {
        items: 3,
      },
      2000: {
        items: 3,
      },
    },
    nav: true,
  };
}
