import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IOfferings } from 'src/app/models/offerings-model';
import { MockdataService } from 'src/app/services/mockdata.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-offerings',
  templateUrl: './offerings.component.html',
  styleUrls: ['./offerings.component.scss'],
})
export class OfferingsComponent implements OnInit {
  categories: IOfferings[] = [];
  constructor(private mockService: MockdataService, private router: Router) {}

  ngOnInit(): void {
    if (environment.useMockData) {
      this.mockService.getAllOfferingsCategories().then((res) => {
        this.categories = res.offers;
      });
    }
  }

  /**
   * get all categories and creates a new array for all the parent category tabs
   * @returns array of unique categories
   */
  getParentCategories(): string[] {
    const categoryTitles = this.categories.map((x) => x.parentCategory);
    const uniqueCategories = [...new Set(categoryTitles)];
    return uniqueCategories;
  }

  /**
   * when you click on a category tab you will get to the filtered category of the parent category
   * where he can see al sub categories
   * @param cat clicked categories data
   */
  onCategoryClick(cat: string) {
    const filteredByParentCategory = this.categories.filter((article) => article.parentCategory === cat);
    this.router.navigate(['/offerings-filter'], {
      state: { filteredByParentCategory: filteredByParentCategory },
    });
  }
}
