import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalConstants } from 'src/app/global-constants';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-background',
  host: {
    '[class.page-content]': 'isImg',
    '[class.bg-blur]': 'isBlurred',
  },
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
})
export class BackgroundComponent implements OnInit {
  @Input() imgPath: string = '';
  @Input() videoPath: string = '';
  @ViewChild('videoPlayer') videoPlayer!: ElementRef | undefined;
  menuBackSub: Subscription;
  blurredSub: Subscription;
  isImg: boolean = true;
  isBlurred: boolean = false;
  windowWidth = GlobalConstants.windowWidth;

  switchBackgroundForTablets = false;

  constructor(public helperService: HelperService, public router: Router) {
    this.menuBackSub = this.helperService.screenSaverVideoIsMuted.subscribe((val) => {
      this.updateVideoMutedState(val);
    });
    // listen if background needs to be blurred or "unblurred"
    this.blurredSub = this.helperService.backgroundBlur.subscribe((blurred: boolean) => {
      this.isBlurred = blurred;
    });

    this.changTabletBackground();
  }

  ngOnInit(): void {
    if (this.videoPath !== '') {
      this.isImg = false;
    }
  }

  getImgPath() {
    // returns either landscape or portrait bg image depending on windowWidth
    if (this.imgPath.includes('.png')) {
      if (this.helperService.isLandscape) return this.imgPath.split('.')[0] + '-quer.jpg';
      else if (this.helperService.isTabletLandscape) return this.imgPath.split('.')[0] + '-tablet.jpg';
      else return this.imgPath.split('.')[0] + '.jpg';
    } else {
      if (this.helperService.isLandscape) return this.imgPath.split('.')[0] + '-quer.jpg';
      else if (this.helperService.isTabletLandscape) return this.imgPath.split('.')[0] + '-tablet.jpg';
      else return this.imgPath.split('.')[0] + '.jpg';
    }
  }

  getVideoPath() {
    if (this.helperService.isLandscape) return this.videoPath.split('_')[0] + '_quer.mp4';
    else return this.videoPath.split('_')[0] + '_hoch.mp4';
  }

  ngOnDestroy() {
    if (this.menuBackSub) this.menuBackSub.unsubscribe();
    if (this.blurredSub) this.blurredSub.unsubscribe();
  }

  changTabletBackground() {
    if (this.router.url.includes('produktdetails') && (this.helperService.isTablet4k || this.helperService.isTabletLandscape)) {
      this.switchBackgroundForTablets = true;
    }
  }

  updateVideoMutedState(currentState: boolean) {
    if (this.videoPlayer !== undefined) {
      this.videoPlayer.nativeElement.muted = currentState;
    }
  }
}
