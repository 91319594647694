import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IVideo } from 'src/app/models/video.model';
import { HelperService } from 'src/app/services/helper.service';
import { MockdataService } from 'src/app/services/mockdata.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-video-library',
  templateUrl: './video-library.component.html',
  styleUrls: ['./video-library.component.scss'],
})
export class VideoLibraryComponent implements OnInit {
  blurredSub: Subscription;
  isBlurred: boolean = false;
  showMoreVideos: boolean = false;
  showVideoOverlay: boolean = false;
  selectedVideo!: IVideo;
  videos!: IVideo[];
  video!: IVideo;

  constructor(public helperService: HelperService, private mockService: MockdataService) {
    if (environment.useMockData) {
      this.mockService.getMoreVideos().then((res) => {
        this.videos = res.videos as IVideo[];
      });
    }
    // blur background if a video is clicked
    this.blurredSub = this.helperService.backgroundBlur.subscribe((blurred: boolean) => {
      this.isBlurred = blurred;
    });
  }

  ngOnInit(): void {}

  onClickShowMoreVideos() {
    this.showMoreVideos = true;
  }

  getVideoLibraryVideos(category: string) {
    // gets the videos by category && opens overlay
    this.mockService.getVideoWorldVideos(category).then((res) => {
      this.videos = res.videos as IVideo[];
      this.selectedVideo = this.videos[0];
      this.showVideoOverlay = true;
      this.helperService.updatedBackgroundBlur(true);
    });
  }

  closeVideoOverlay(): void {
    this.showVideoOverlay = false;
    this.helperService.updatedBackgroundBlur(false);
  }

  ngOnDestroy() {
    if (this.blurredSub) this.blurredSub.unsubscribe();
  }
}
