import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { IAsset } from 'src/app/models/assets.model';
import { IVideo } from 'src/app/models/video.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-overlay-slider',
  templateUrl: './overlay-slider.component.html',
  styleUrls: ['./overlay-slider.component.scss'],
})
export class OverlaySliderComponent implements OnInit {
  @Output() mediaChanged = new EventEmitter();
  @Output() videoChanged = new EventEmitter();
  @Input() selectedMediaArray!: IAsset[];
  @Input() videos!: IVideo[];
  constructor(private helperService: HelperService) {}

  ngOnInit(): void {}

  getThumbnail(url: string) {
    return this.helperService.getThumbnail(url);
  }

  /**
   * changes media shown in media-overlay-component
   * @param media clicked media data
   */
  showThisMedia(media: IAsset) {
    this.mediaChanged.emit(media);
  }

  /**
   * changes video shown in media-overlay-component (special slider)
   * @param video clicked media data
   */
  showThisVideo(video: IVideo) {
    this.videoChanged.emit(video);
  }

  /* Media Slider Settings */
  mediaOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 8,
    navSpeed: 700,
    navText: ['<img class="arrow" src="assets/icon/icon-arrow-left.svg"/>', '<img class="arrow" src="assets/icon/icon-arrow-right.gif"/>'],
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      740: {
        items: 2,
      },
      940: {
        items: 2,
      },
    },
    nav: true,
  };
}
