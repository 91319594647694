import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { IAsset } from 'src/app/models/assets.model';
import { IProduct } from 'src/app/models/product.model';
import { ISallyHint } from 'src/app/models/sally-hint.model';
import { HelperService } from 'src/app/services/helper.service';
import { MockdataService } from 'src/app/services/mockdata.service';

@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.scss'],
})
export class ProductOverviewComponent implements OnInit {
  selectedProduct!: IProduct;
  @Input() bgUrl!: string;
  @Output() hideComponentsWhenOverlayIsOpen = new EventEmitter();
  selectedMedia!: IAsset;
  isLoading: boolean = false;
  showMediaOverlay: boolean = false;
  selectedMediaArray!: IAsset[];
  showSallyOverlay: boolean = false;
  productImgUrl: string = '';
  sallysHint?: ISallyHint;
  productSubscription!: Subscription;

  constructor(public helperService: HelperService, private mockService: MockdataService) {}

  ngOnInit(): void {
    this.getProduct();
    this.bgUrl = this.helperService.bgUrl;
  }

  private getProduct() {
    this.productSubscription = this.helperService.prodSubject.subscribe((product: IProduct) => {
      this.selectedProduct = product;
      this.getMediaData();
    });
  }

  private getMediaData() {
    if (this.selectedProduct.medResPicture) this.selectedProduct.assets[0].url = this.selectedProduct.medResPicture.replace('600x600', '1200x1200');
    const initialMedia = this.selectedProduct.assets.filter((v) => v.category.includes('product-shot') && v.type === 'picture.');
    this.selectedMedia = initialMedia[0];
    this.mockService.getSallysHints().then((res) => {
      this.getRightHint(res['sallys-hints']);
    });
  }

  getRightHint(sallysHints: Array<ISallyHint>) {
    // sort SallysHints so the prioritized Hints are listed first
    let sorted = sallysHints.sort(({ isPrioitized: a = false }, { isPrioitized: b = false }) => Number(b) - Number(a));
    // find first hint in sorted array (use VIB later when we have all VIBS)
    this.sallysHint = sorted.find((e) => e.vibs.includes(this.selectedProduct.vib));
  }

  getCurrentProductImage() {
    if (this.selectedMedia) {
      if (this.selectedMedia.category.includes('video')) return this.getThumbnail(this.selectedMedia.url);
      else return this.selectedMedia.url;
    } else if (this.selectedProduct.medResPicture) return this.selectedProduct.medResPicture;
    else return this.selectedProduct.highResPicture;
  }

  getThumbnail(url: string) {
    return this.helperService.getThumbnail(url);
  }

  getProductImages() {
    return this.selectedProduct.assets.filter(
      (v) => v.category.includes('product-shot') || (v.category.includes('product-video') && (v.type === 'picture.' || v.type === 'video.')),
    );
  }

  getFeatureIcons() {
    return this.selectedProduct.assets.filter((v) => v.category.includes('feature-icon'));
  }

  showThisMedia(media: IAsset) {
    this.selectedMedia = media;
    this.getCurrentProductImage();
  }

  openMediaOverlay(selectedMedia: IAsset) {
    this.selectedMedia = selectedMedia;
    this.selectedMediaArray = this.selectedProduct.assets;
    this.showMediaOverlay = true;
    this.helperService.updatedBackgroundBlur(true);
  }

  closeVideoOverlay(): void {
    this.showMediaOverlay = false;
    this.helperService.updatedBackgroundBlur(false);
  }

  openSallyOverlay() {
    this.showSallyOverlay = true;
    this.hideComponentsWhenOverlayIsOpen.emit();
    this.helperService.updatedBackgroundBlur(true);
  }

  closeSallyOverlay(): void {
    this.showSallyOverlay = false;
    this.hideComponentsWhenOverlayIsOpen.emit();
    this.helperService.updatedBackgroundBlur(false);
  }

  isLoadingFalse() {
    this.isLoading = false;
  }

  ngOnDestroy() {
    if (this.productSubscription) this.productSubscription.unsubscribe();
  }
}
