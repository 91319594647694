<div class="media-carousel-wrap grid grid-cols-6 gap-4">
  <div class="highlights-carousel-container-overlay" [ngClass]="helperService.isLandscape ? 'col-start-1 col-span-6' : 'col-start-2 col-span-4'">
    <owl-carousel-o [options]="mediaOptions">
      <ng-container *ngFor="let highlight of selectedHighlightArray; let i = index">
        <ng-container *ngFor="let asset of helperService.getRightAsset(highlight); let i = index">
          <ng-template carouselSlide id="slide-{{ i }}">
            <div (click)="showThisHighlight(highlight)" class="aspect-w-1 aspect-h-1 center mt-2">
              <img class="play-icon" src="assets/icon/videoPlay.png" *ngIf="asset.category.includes('feature-video')" />
              <img class="thumbnail-img" [src]="getThumbnail(asset.url)" *ngIf="asset.category.includes('feature-video')" />
              <img *ngIf="asset.category.includes('category.detail')" src="{{ asset.url }}" class="product-image" alt="Produkt-Medien" />
            </div> </ng-template
        ></ng-container>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
