<div *ngIf="selectedMediaArray" class="media-carousel-wrap grid grid-cols-6 gap-4">
  <div class="media-carousel-container col-start-2 col-span-4">
    <owl-carousel-o [options]="mediaOptions">
      <ng-container *ngFor="let media of selectedMediaArray; let i = index">
        <ng-template *ngIf="media.type === 'picture.' || media.type === 'video.'" carouselSlide id="slide-{{ i }}">
          <div class="aspect-w-1 aspect-h-1 mt-2">
            <img (click)="showThisMedia(media)" class="play-icon" src="assets/icon/videoPlay.png" *ngIf="media.type === 'video.'" />
            <img (click)="showThisMedia(media)" class="thumbnail-img" [src]="getThumbnail(media.url)" *ngIf="media.type === 'video.'" />
            <img (click)="showThisMedia(media)" *ngIf="media.type === 'picture.'" src="{{ media.url }}" class="product-image" alt="Produkt-Medien" />
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>

<div *ngIf="videos" class="media-carousel-wrap grid grid-cols-6 gap-4">
  <div class="media-carousel-container col-start-2 col-span-4">
    <owl-carousel-o [options]="mediaOptions">
      <ng-container *ngFor="let media of selectedMediaArray; let i = index">
        <ng-template *ngIf="media.type === 'picture.' || media.type === 'video.'" carouselSlide id="slide-{{ i }}">
          <div class="aspect-w-1 aspect-h-1 mt-2">
            <img (click)="showThisMedia(media)" class="play-icon" src="assets/icon/videoPlay.png" *ngIf="media.type === 'video.'" />
            <img (click)="showThisMedia(media)" class="thumbnail-img" [src]="getThumbnail(media.url)" *ngIf="media.type === 'video.'" />
            <img (click)="showThisMedia(media)" *ngIf="media.type === 'picture.'" src="{{ media.url }}" class="product-image" alt="Produkt-Medien" />
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
