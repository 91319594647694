<div class="grid grid-cols-12 gap-4">
  <div class="video-library-carousel-container col-start-1 col-end-13">
    <owl-carousel-o [options]="modernKitchenOption">
      <ng-container *ngFor="let video of videos; let i = index">
        <ng-template carouselSlide id="slide-{{ i }}">
          <div [ngClass]="{ 'flex flex-col items-center': helperService.isMobile }">
            <div
              [ngClass]="{ 'w-4/5': helperService.isMobile }"
              class="aspect-w-16 aspect-h-9 mt-2 bg-light-black fade-in-box"
              style="--animation-order:{{ i }}"
            >
              <img (click)="showThisVideo(video)" src="{{ helperService.getThumbnailFromEmbededUrl(video.youtubeLink) }}" class="thumbnail-image" />
            </div>
            <div class="description-box scrollbar-hidden fade-in-box" style="--animation-order:{{ i }}">
              <p [ngClass]="helperService.isMobile ? 'text-small mt-2 w-4/5' : 'text-xs'" class="text-white max-2-lines">{{ video.title }}</p>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
