<div [ngClass]="helperService.isMobile || helperService.isTabletLandscape ? 'full-height' : 'inset-0'" class="absolute background-blur z-10">
  <app-menu></app-menu>
  <div [ngClass]="helperService.isMobile ? 'padding-mobile md:px-8 ' : helperService.isTabletLandscape ? 'px-16 mt-12' : 'px-16 mt-24'">
    <div class="grid" [ngClass]="helperService.isLandscape ? 'grid-cols-12 gap-8' : 'grid-cols-1'">
      <div
        class="bg-default-col-bg shadow-md"
        [ngClass]="helperService.isLandscape ? 'col-start-1 col-end-7' : helperService.isTabletLandscape ? 'w-3/4' : 'col-span-12'"
      >
        <img class="close-icon py-4" src="assets/icon/icon-close.png" (click)="onClickCloseVideoOverlay()" />
        <div class="aspect-w-16 aspect-h-9">
          <app-cookie-video-overlay *ngIf="!helperService.isCollectingCookies && helperService.isMobileDevice"></app-cookie-video-overlay>
          <iframe
            *ngIf="helperService.isCollectingCookies"
            [src]="helperService.getNoCookieUrl(selectedVideo.youtubeLink) | safe: 'resourceUrl'"
            frameborder="0"
            class="z-30"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            sandbox="allow-scripts allow-same-origin"
          ></iframe>
        </div>
        <p [ngClass]="helperService.isMobile ? 'text-small' : 'py-4 px-6 text-sm'" class="text-white">
          {{ selectedVideo.title }}
        </p>
      </div>

      <h3 *ngIf="videos.length <= 1" class="text-white text-xl mt-8" [ngClass]="helperService.isLandscape ? 'col-span-6' : ''">
        Keine weiteren Videos
      </h3>
      <div *ngIf="videos.length > 1" [ngClass]="helperService.isLandscape ? 'col-start-7 col-end-13' : 'col-span-12'">
        <h3 [ngClass]="helperService.isMobile ? 'text-sm mt-12' : 'text-2xl mt-8'" class="text-white">Das könnte dich auch interessieren:</h3>
        <div class="grid grid-cols-4 gap-4">
          <p [ngClass]="{ 'text-xs': helperService.isMobile && !helperService.isTablet }" class="col-start-1 pb-4 mt-4 mb-2 text-white font-bold">
            Videos:
          </p>
        </div>

        <div [ngClass]="helperService.isMobile && !helperService.isTablet ? 'px-12' : 'px-18'">
          <app-video-library-slider (mediaChanged)="showThisVideo($event)" [videos]="videos"></app-video-library-slider>
        </div>
      </div>
    </div>
  </div>
</div>
