import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper.service';
@Component({
  selector: 'app-all-features',
  templateUrl: './all-features.component.html',
  styleUrls: ['./all-features.component.scss'],
})
export class AllFeaturesComponent implements OnInit {
  constructor(private router: Router, public helperService: HelperService) {}

  ngOnInit(): void {}

  getLogoColor(): string {
    if (this.helperService.isMobile) return 'assets/img/bosch_logo.png';
    else return 'assets/img/bosch_logo_red.svg';
  }

  filterByCategory(category: string) {
    this.router.navigate(['/funktionsauswahl', { category: category }]);
  }
}
