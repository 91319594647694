<!-- CAROUSEL LAYOUT FOR PORTRAIT LAYOUT   -->
<!-- CAROUSEL LAYOUT FOR PORTRAIT LAYOUT   -->
<!-- CAROUSEL LAYOUT FOR PORTRAIT LAYOUT   -->
<div
  [ngClass]="{ 'mt-4 px-16': helperService.isMobileDevice }"
  *ngIf="!helperService.isLandscape && selectedProduct.accessories.length > 3"
  class="grid grid-cols-12 gap-4"
>
  <div class="related-carousel-container col-start-1 col-end-13">
    <owl-carousel-o [options]="relatedOption">
      <ng-container *ngFor="let accessory of selectedProduct.accessories; let i = index">
        <ng-template *ngIf="accessory.highResPicture || accessory.lowResPicture" carouselSlide id="slide-{{ i }}">
          <div
            [ngClass]="helperService.isMobile && !helperService.isTablet ? 'mt-0 aspect-h-2 aspect-w-3' : 'mt-2 aspect-h-1 aspect-w-1'"
            class="bg-default-col-bg fade-in-box"
            style="--animation-order:{{ i }}"
          >
            <img
              (click)="helperService.isMobileDevice ? navigateToBoschHomepage(accessory) : openRelatedProductOverlay(accessory)"
              [src]="getPicture(accessory)"
              class="related-image"
            />
          </div>
          <div
            [ngClass]="{ 'h-20': helperService.isTabletLandscape }"
            class="p-2 pb-4 description-box bg-default-col-bg scrollbar-hidden mt-2 fade-in-box"
            style="--animation-order:{{ i }}"
          >
            <p [ngClass]="{ 'text-small': helperService.isMobile }" *ngIf="accessory.title" class="text-white">
              {{ accessory.title }}
            </p>
          </div>

          <div
            *ngIf="!helperService.isMobile && !helperService.isTabletLandscape && !helperService.isTablet4k"
            (click)="openRelatedProductOverlay(accessory)"
            class="share-box icon-col bg-default-col-bg mt-2 fade-in-box"
            style="--animation-order:{{ i }}"
          >
            <img class="share-icon" src="assets/menuIcons/shareIcon.svg" />
          </div>

          <div
            *ngIf="helperService.isMobile || helperService.isTabletLandscape || helperService.isTablet4k"
            class="bg-default-col-bg flex items-center mt-2 p-2 text-white"
            [ngClass]="helperService.isMobile ? 'gap-1 justify-between' : helperService.isTablet4k ? 'gap-6' : 'justify-between'"
            (click)="navigateToBoschHomepage(accessory)"
            style="--animation-order:{{ i }}"
          >
            <p class="text-small">Mehr erfahren auf Bosch Home</p>
            <img class="mobile-share-icon" src="assets/menuIcons/mobileShareIcon.svg" alt="" />
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>

<!--  (NO CAROUSEL) LAYOUT FOR PORTRAIT IF THERE ARE LESS THAN 4 RELATED PRODUCTS-->
<!--  (NO CAROUSEL) LAYOUT FOR PORTRAIT IF THERE ARE LESS THAN 4 RELATED PRODUCTS-->
<!--  (NO CAROUSEL) LAYOUT FOR PORTRAIT IF THERE ARE LESS THAN 4 RELATED PRODUCTS-->

<div
  [ngClass]="helperService.isMobile ? 'grid-cols-1 mt-4' : 'grid-cols-4'"
  *ngIf="!helperService.isLandscape && selectedProduct.accessories.length <= 3"
  class="grid gap-4"
>
  <div class="related-wrap">
    <div *ngFor="let accessory of selectedProduct.accessories; let i = index" class="related-container">
      <div class="aspect-w-1 aspect-h-1 mt-2 bg-light-black fade-in-box" style="--animation-order:{{ i }}">
        <img
          (load)="onImageLoaded()"
          (click)="helperService.isMobileDevice ? navigateToBoschHomepage(accessory) : openRelatedProductOverlay(accessory)"
          [src]="getPicture(accessory)"
          class="related-image"
        />
      </div>
      <div
        [ngClass]="{ 'h-20': helperService.isTabletLandscape }"
        class="p-2 pb-4 description-box bg-default-col-bg scrollbar-hidden mt-2 fade-in-box"
        style="--animation-order:{{ i }}"
      >
        <p [ngClass]="{ 'text-small': helperService.isMobile }" *ngIf="accessory.title" class="text-white">
          {{ accessory.title }}
        </p>
      </div>

      <div
        *ngIf="!helperService.isMobile && !helperService.isTabletLandscape"
        (click)="openRelatedProductOverlay(accessory)"
        class="share-box icon-col bg-default-col-bg mt-2 fade-in-box"
        style="--animation-order:{{ i }}"
      >
        <img class="share-icon" src="assets/menuIcons/shareIcon.svg" />
      </div>

      <div
        *ngIf="helperService.isMobile || helperService.isTabletLandscape"
        class="bg-default-col-bg flex justify-between items-center mt-2 p-2 text-white"
        (click)="navigateToBoschHomepage(accessory)"
        style="--animation-order:{{ i }}"
      >
        <p class="text-small">Mehr erfahren auf Bosch Home</p>
        <img class="mobile-share-icon" src="assets/menuIcons/mobileShareIcon.svg" alt="" />
      </div>
    </div>
  </div>
</div>

<!-- CAROUSEL LAYOUT FOR LANDSCAPE LAYOUT (2 SLIDERS DIFFERENT SETTINGS) -->
<!-- CAROUSEL LAYOUT FOR LANDSCAPE LAYOUT (2 SLIDERS DIFFERENT SETTINGS) -->
<!-- CAROUSEL LAYOUT FOR LANDSCAPE LAYOUT (2 SLIDERS DIFFERENT SETTINGS) -->

<div *ngIf="helperService.isLandscape && selectedProduct.accessories.length > 2" class="grid grid-cols-12 gap-4 mb-4">
  <div class="related-carousel-container-diagonal col-start-1 col-end-13">
    <owl-carousel-o [options]="relatedOptionLarge">
      <ng-container *ngFor="let accessory of selectedProduct.accessories; let i = index">
        <ng-template *ngIf="accessory.highResPicture || accessory.lowResPicture" carouselSlide id="slide-{{ i }}">
          <div class="aspect-w-16 aspect-h-10 mt-2 bg-default-col-bg fade-in-box" style="--animation-order:{{ i }}">
            <img (load)="onImageLoaded()" (click)="openRelatedProductOverlay(accessory)" [src]="getPicture(accessory)" class="related-image" />
          </div>
          <div
            [ngClass]="{ 'h-20': helperService.isTabletLandscape }"
            class="p-2 pb-4 description-box-small bg-default-col-bg scrollbar-hidden mt-2 fade-in-box"
            style="--animation-order:{{ i }}"
          >
            <p *ngIf="accessory.title" class="text-white text-xs">{{ accessory.title }}</p>
          </div>

          <div
            (click)="openRelatedProductOverlay(accessory)"
            class="share-box-small icon-col bg-default-col-bg mt-2 fade-in-box"
            style="--animation-order:{{ i }}"
          >
            <img class="share-icon-small" src="assets/menuIcons/shareIcon.svg" />
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>

<!-- <div *ngIf="helperService.isLandscape && selectedProduct.accessories.length > 2" class="grid grid-cols-12 gap-4 mb-4">
  <div class="related-carousel-container-diagonal col-start-1 col-end-13">
    <owl-carousel-o [options]="relatedOptionLarge2">
      <ng-container *ngFor="let accessory of selectedProduct.accessories; let i = index">
        <ng-template *ngIf="accessory.highResPicture || accessory.lowResPicture" carouselSlide id="slide-{{ i }}">
          <div class="aspect-w-16 aspect-h-10 mt-2 bg-default-col-bg fade-in-box" style="--animation-order:{{ i }}">
            <img (load)="onImageLoaded()" (click)="openRelatedProductOverlay(accessory)" [src]="getPicture(accessory)" class="related-image" />
          </div>
          <div class="p-2 pb-4 description-box-small bg-default-col-bg scrollbar-hidden mt-2 fade-in-box" style="--animation-order:{{ i }}">
            <p *ngIf="accessory.title" class="text-white text-xs">{{ accessory.title }}</p>
          </div>

          <div
            (click)="openRelatedProductOverlay(accessory)"
            class="share-box-small icon-col bg-default-col-bg mt-2 fade-in-box"
            style="--animation-order:{{ i }}"
          >
            <img class="share-icon-small" src="assets/menuIcons/shareIcon.svg" />
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div> -->

<!--  (NO CAROUSEL)  LAYOUT FOR LANDSCAPE IF THERE ARE LESS THAN 3 RELATED PRODUCTS -->
<!--  (NO CAROUSEL)  LAYOUT FOR LANDSCAPE IF THERE ARE LESS THAN 3 RELATED PRODUCTS -->
<!--  (NO CAROUSEL)  LAYOUT FOR LANDSCAPE IF THERE ARE LESS THAN 3 RELATED PRODUCTS -->

<div *ngIf="helperService.isLandscape && selectedProduct.accessories.length <= 2" class="grid grid-cols-4 gap-4">
  <div>
    <div *ngFor="let accessory of selectedProduct.accessories; let i = index" class="related-container">
      <div class="aspect-w-1 aspect-h-1 mt-2 bg-default-col-bg fade-in-box" style="--animation-order:{{ i }}">
        <img (click)="openRelatedProductOverlay(accessory)" [src]="getPicture(accessory)" class="related-image" />
      </div>
      <div class="p-2 pb-4 description-box bg-default-col-bg scrollbar-hidden mt-2 fade-in-box" style="--animation-order:{{ i }}">
        <p *ngIf="accessory.title" class="text-white">{{ accessory.title }}</p>
      </div>

      <div
        (click)="openRelatedProductOverlay(accessory)"
        class="share-box icon-col bg-default-col-bg mt-2 fade-in-box"
        style="--animation-order:{{ i }}"
      >
        <img class="share-icon" src="assets/menuIcons/shareIcon.svg" />
      </div>
    </div>
  </div>
</div>

<app-related-products-overlay
  [selectedProduct]="selectedProduct"
  [selectedRelatedProduct]="selectedRelatedProduct"
  (closeRelatedProductOverlay)="closeRelatedProductOverlay()"
  [selectedProduct]="selectedProduct"
  *ngIf="showRelatedProductOverlay && !helperService.isMobile && !helperService.isTabletLandscape"
></app-related-products-overlay>
