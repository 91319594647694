<app-background [imgPath]="'assets/img/video-world/all-features/all-features.jpg'"></app-background>

<app-menu *ngIf="!showOverlay" [logoPath]="getLogoColor()" [showBurger]="true" [showBack]="true" [showHome]="true" [showSearch]="true"></app-menu>
<app-menu
  *ngIf="showOverlay"
  [logoPath]="'assets/img/bosch_logo.png'"
  [showBurger]="true"
  [showBack]="true"
  [showHome]="true"
  [showSearch]="true"
></app-menu>

<div
  *ngIf="!isLoading"
  class=""
  [ngClass]="[
    helperService.isLandscape
      ? 'mt-16 grid grid-cols-12 px-16'
      : helperService.isMobile
      ? 'padding-mobile md:px-8 pb-mobile'
      : helperService.isTabletLandscape
      ? 'mt-16 grid grid-cols-2 px-16 pb-16 gap-4'
      : 'mt-24 px-16',
    showOverlay ? 'unscrollable' : ''
  ]"
>
  <div
    [ngClass]="
      helperService.isLandscape ? 'col-start-1 col-end-6 grid-cols-2 grid gap-2 h-fit' : helperService.isMobile ? '' : 'grid-cols-3 grid gap-2 h-fit'
    "
  >
    <p *ngIf="helperService.isMobile" class="text-white text-small m-0 mb-2">Funktionen</p>
    <ng-container *ngFor="let feature of getHighlightedFeatures(); let i = index">
      <div
        *ngIf="feature.isHighlighted && feature.title"
        (click)="onFeatureClick(feature)"
        style="--animation-order: 1"
        [ngClass]="helperService.isTablet4k || helperService.isTabletLandscape ? 'text-xl text-white font-bold tabletHeght p-3' : 'category-col-big'"
        class="box-effekt-1 bg-default-col-bg fade-in-box"
      >
        <span [ngClass]="{ 'text-base': helperService.isMobile }">{{ feature.title }}</span>
      </div>
    </ng-container>
  </div>

  <div
    class="grid h-fit"
    [ngClass]="
      helperService.isLandscape
        ? 'mt-0 col-start-7 col-end-13 grid-cols-3 gap-2'
        : helperService.isMobile
        ? 'grid-cols-2 gap-x-2 gap-y-3'
        : helperService.isTablet || helperService.isTabletLandscape
        ? 'mt-0 gap-2 grid-cols-3 '
        : 'mt-8 grid-cols-3 gap-2'
    "
  >
    <ng-container *ngFor="let feature of getHighlightedFeatures(); let i = index">
      <div
        *ngIf="!feature.isHighlighted && feature.title"
        (click)="onFeatureClick(feature)"
        style="--animation-order: 1"
        class="box-effekt-1 category-col bg-default-col-bg fade-in-box"
      >
        <span [ngClass]="helperService.isMobile ? 'text-small' : ''">{{ feature.title }}</span>
      </div>
    </ng-container>
  </div>
</div>
<app-highlights-overlay *ngIf="showOverlay" [selectedHighlight]="selectedHighlight" (closeOverlay)="closeOverlay()"></app-highlights-overlay>
