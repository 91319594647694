import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IProdsApiResponse } from 'src/app/models/apiResponse.model';
import { IProduct } from 'src/app/models/product.model';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import SwiperCore, { Lazy, Navigation, Pagination } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Navigation, Pagination, Lazy]);

@Component({
  selector: 'app-filter-result',
  templateUrl: './filter-result.component.html',
  styleUrls: ['./filter-result.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilterResultComponent implements OnInit {
  @ViewChild('swiper') swiper!: SwiperComponent;
  apiCatTitle: string = '';
  endpoint: string = '';
  bgUrl: string = '';
  isLoading: boolean = false;
  products: IProduct[] = [];
  parentId?: number;
  alignRight: boolean = false;
  imageIsLoading: boolean = true;
  apiFilterId: string = '';
  apiFilterTitle: string = '';
  filteredProdSub!: Subscription;
  prodSub!: Subscription;

  searchUrl!: string;
  searchParam!: string;

  endPointArray!: string;

  constructor(private router: Router, private apiService: ApiService, public helperService: HelperService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      // methods puts together all parameters of endpoint string in URL

      let endpoint = '';
      params.keys.forEach((routeKey) => {
        let property = params.get(routeKey)!;
        if (routeKey === 'endpoint') endpoint += property;
        if (routeKey !== 'bgUrl' && routeKey !== 'endpoint') endpoint += '&' + routeKey + '=' + property;
      });
      if (this.router.url.includes('endpoint')) {
        this.endpoint = endpoint;
      } else {
        this.endpoint = '';
      }

      let title = '';
      params.keys.forEach((routeKey) => {
        let property = params.get(routeKey)!;
        if (routeKey === 'filterId') this.apiFilterId = property;
        else if (routeKey === 'filterTitle') this.apiFilterTitle = property;
        else if (routeKey === 'title') title += property;
        else if (routeKey !== 'bgUrl' && routeKey !== 'title') title += '&' + routeKey + '=' + property;
      });

      if (this.router.url.includes('title')) {
        this.apiCatTitle = title;
      } else {
        this.apiCatTitle = '';
      }

      if (this.router.url.includes('search') || this.router.url.includes('query')) {
        params.keys.forEach((routeKey) => {
          let property = params.get(routeKey)!;
          if (routeKey === 'search') this.searchUrl = property;
          else if (routeKey !== 'search' && routeKey !== 'query') this.searchUrl += '&' + routeKey + '=' + property;
          else this.searchParam = property;
        });
      }

      // assigns background URL based on background image
      const bgNumber = params.get('bgUrl');
      if (bgNumber && bgNumber !== 'undefined') {
        this.bgUrl = `assets/img/background_images/category${bgNumber}.jpg`;
      } else {
        this.bgUrl = 'assets/img/background_images_low_res/category2.jpg';
      }

      if (this.endpoint.length > 0 || this.apiCatTitle.length > 0 || this.searchUrl || this.searchParam) {
        this.getSearchResult();
      } else {
        this.router.navigate(['unsere-produkte']);
      }
    });
  }

  ngOnInit() {}

  getSearchResult() {
    // since not every category has a clear filterId we need to use titles aswell, we need to check
    // if there is a title, an id, both or none and make an API call depending on the given information
    // any of those  functions returns all products with given search parameters
    this.isLoading = true;

    if (this.endpoint.length > 0) this.getProductsByEndpoint();
    else if (this.apiFilterId && this.apiFilterTitle) this.getProductsByIdAndTitle();
    else if (!this.apiFilterId && this.apiFilterTitle) this.getProductsByTitle();
    else if (this.searchUrl || this.searchParam) this.getProductsBySearchResult();
    else this.getProductsById();
  }

  getProductsByIdAndTitle() {
    this.apiService.getProductsByIdAndTitle(this.apiCatTitle, this.apiFilterId, this.apiFilterTitle).subscribe((res: IProdsApiResponse) => {
      this.setProducts(res);
    });
  }

  getProductsById() {
    this.alignRight = false;
    this.apiService.getProductsById(this.apiCatTitle, this.apiFilterId).subscribe((res: IProdsApiResponse) => {
      this.setProducts(res);
    });
  }

  getProductsByTitle() {
    this.apiService.getProductsByTitle(this.apiCatTitle, this.apiFilterTitle).subscribe((res: IProdsApiResponse) => {
      this.setProducts(res);
    });
  }

  getProductsByEndpoint() {
    this.apiService.getProductsByGroup(this.endpoint).subscribe((res: IProdsApiResponse) => {
      this.setProducts(res);
    });
  }

  getProductsBySearchResult() {
    this.apiService.getProductBySearchParams(this.searchParam, this.searchUrl).subscribe((res: IProdsApiResponse) => {
      this.setProducts(res);
    });
  }

  setProducts(res: IProdsApiResponse) {
    // fills products array and beautifies titles to easily display in a loop
    this.products = res.data;
    this.beautifyProdTitle();
    this.isLoading = false;
  }

  onClickMoveToProductSite(product: IProduct): void {
    const bgUrl = this.bgUrl;
    const categoryNumber = this.helperService.getBgNumber(bgUrl);
    if (this.helperService.isMobileDevice) {
      this.router.navigateByUrl(`/produktdetails?ean=${product.ean}&bg=${categoryNumber}`);
    } else {
      this.router.navigateByUrl(`/produktdetails/ueberblick?ean=${product.ean}&bg=${categoryNumber}`);
    }
  }

  beautifyProdTitle() {
    this.products.map((prod: IProduct) => {
      // product title always contains vib no. as well (redundant information)
      const titleElements = prod.title.split(' ');
      // remove last entry and create string afterwards
      titleElements.pop();
      let title = titleElements.join(' ');
      prod.title = title;
      return prod;
    });
  }

  getProductImg(product: IProduct) {
    if (product.lowResPicture) return product.lowResPicture;
    else if (product.medResPicture) return product.medResPicture;
    else return product.highResPicture;
  }

  onClickGetPreviousProduct(): void {
    this.swiper.swiperRef.slidePrev();
  }

  onClickGetNextProduct(): void {
    this.swiper.swiperRef.slideNext();
  }

  imageWasLoaded() {
    this.imageIsLoading = false;
  }

  getBackgroundImageUrl(): string {
    if (this.helperService.isMobile) return 'assets/img/background_images_low_res/category2';
    else return this.bgUrl;
  }

  ngOnDestroy() {
    if (this.prodSub) this.prodSub.unsubscribe();
    if (this.filteredProdSub) this.filteredProdSub.unsubscribe();
  }
}
