<app-background [imgPath]="getBackgroundImageUrl()"></app-background>
<app-menu [logoPath]="'assets/img/bosch_logo.png'" [showBack]="true" [showSearch]="true" [showHome]="true"></app-menu>

<div
  *ngIf="!isLoading"
  [ngClass]="
    alignRight && helperService.isLandscape
      ? 'align-right-landscape pb-16'
      : alignRight && !helperService
      ? 'align-right pb-16'
      : helperService.isLandscape
      ? ' pl-16 w-3/5 pt-20 pb-16'
      : helperService.isMobile
      ? 'padding-mobile md:px-8 pb-mobile md:pt-12 w-full'
      : helperService.isTabletLandscape
      ? 'px-16 pt-12 pb-8'
      : 'px-16 pt-28 pb-16'
  "
>
  <div class="grid grid-cols-1 text-white">
    <h3 *ngIf="products.length > 1" [ngClass]="helperService.isMobile ? ' mb-2' : 'text-2xl mb-8'">Deine {{ products.length }} Ergebnisse:</h3>
    <h3 *ngIf="products.length === 1" [ngClass]="helperService.isMobile ? ' mb-2' : 'text-2xl mb-8'">Dein Ergebnis:</h3>
    <h3 *ngIf="products.length < 1" [ngClass]="helperService.isMobile ? ' mb-2' : 'text-2xl mb-8'">Es wurde leider kein Ergebnis gefunden</h3>
  </div>
  <swiper
    #swiper
    [centeredSlides]="true"
    [slidesPerView]="1"
    [spaceBetween]="30"
    [shortSwipes]="false"
    [longSwipesMs]="50"
    [lazy]="true"
    [longSwipesRatio]="0.1"
    [loop]="true"
    [pagination]="{
      type: 'fraction'
    }"
    [ngClass]="{ 'overflow-y-visible': !helperService.isLandscape }"
  >
    <ng-template swiperSlide *ngFor="let product of products">
      <div
        class="flex md:flex-row"
        [ngClass]="{
          'flex-col gap-4 ': helperService.isMobile && !helperService.isTablet,
          'gap-8 mb-12': helperService.isTablet,
          'w-9/12': helperService.isTabletLandscape,
          'mb-16': !helperService.isMobile
        }"
      >
        <div class="prod-img-container">
          <img
            (click)="onClickMoveToProductSite(product)"
            [ngClass]="{ 'w-96': helperService.isTablet4k }"
            class="prod-img swiper-lazy"
            [src]="getProductImg(product)"
          />
        </div>
        <div class="lg:pl-16">
          <h2 [ngClass]="{ 'text-base': helperService.isMobile }" class="text-white">
            {{ product?.title }}
          </h2>
          <h2 [ngClass]="{ 'text-base': helperService.isMobile }" class="text-white font-bold">
            {{ product?.vib }}
          </h2>
          <p
            class="text-white"
            [ngClass]="helperService.isLandscape ? 'mt-12' : helperService.isMobile && !helperService.isTablet ? 'text-xs' : 'mt-8'"
          >
            {{ product?.uspMain }}
          </p>
          <div>
            <a
              (click)="onClickMoveToProductSite(product)"
              class="block w-56 px-8 bg-default-col-bg shadow-md"
              [ngClass]="[
                helperService.isLandscape ? 'mt-12' : 'mt-8',
                helperService.isMobile && !helperService.isTablet ? ' py-3 w-full sm:w-1/2 mb-8' : 'py-4'
              ]"
            >
              <p [ngClass]="helperService.isMobile ? 'text-base' : 'text-xl'" class="text-center text-white m-0">Mehr erfahren</p>
            </a>
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>
  <div *ngIf="products.length > 1" [ngClass]="helperService.isMobile ? ' bottom-12' : ' bottom-16'" class="flex relative justify-center gap-48">
    <div class="button-prev-next bg-default-col-bg" (click)="onClickGetPreviousProduct()">
      <img class="menu-icon" src="assets/icon/icon-arrow-left.svg" />
    </div>
    <div class="button-prev-next bg-default-col-bg" (click)="onClickGetNextProduct()">
      <img class="menu-icon" src="assets/icon/icon-arrow-right.gif" />
    </div>
  </div>
</div>
