<!--   LAYOUT FOR PRODUCT OVERVIEW OVERLAY    -->
<!--   LAYOUT FOR PRODUCT OVERVIEW OVERLAY    -->
<!--   LAYOUT FOR PRODUCT OVERVIEW OVERLAY    -->
<app-menu *ngIf="helperService.isMobile"></app-menu>
<div
  *ngIf="selectedMedia"
  [ngClass]="helperService.isLandscape ? 'pl-16 pt-40 w-1/2' : helperService.isMobile ? 'padding-mobile md:px-8 ' : 'px-16 pt-48'"
>
  <div class="mb-4 flex justify-end">
    <div class="close-icon-container">
      <img class="close-icon py-4" src="assets/icon/icon-close.png" (click)="onClickCloseVideoOverlay()" />
    </div>
  </div>

  <div class="grid grid-cols-12">
    <div class="col-start-1 col-end-13 aspect-w-16 aspect-h-9" [ngClass]="helperService.isLandscape ? 'mb-8' : 'mb-12'">
      <img *ngIf="selectedMedia.category.includes('picture')" class="object-contain" src="{{ getProductImage() }}" alt="" />
      <app-cookie-video-overlay *ngIf="!helperService.isCollectingCookies && helperService.isMobileDevice"></app-cookie-video-overlay>
      <iframe
        *ngIf="selectedMedia.category.includes('video') && helperService.isCollectingCookies"
        [src]="helperService.getEmbededUrl(selectedMedia.url) | safe: 'resourceUrl'"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        sandbox="allow-scripts allow-same-origin"
      ></iframe>
      <img class="object-contain" *ngIf="selectedMedia.category.includes('product-shot')" src="{{ selectedMedia.url }}" alt="" />
    </div>
  </div>

  <div class="grid grid-cols-12">
    <div class="col-start-4 col-end-10">
      <app-overlay-slider (mediaChanged)="showThisMedia($event)" [selectedMediaArray]="selectedMediaArray"></app-overlay-slider>
    </div>
  </div>
</div>

<!--     LAYOUT FOR SPECIAL SLIDER OVERLAY    -->
<!--     LAYOUT FOR SPECIAL SLIDER OVERLAY    -->
<!--     LAYOUT FOR SPECIAL SLIDER OVERLAY    -->
<div *ngIf="selectedVideo" [ngClass]="helperService.isLandscape ? 'pl-16 pt-40 w-1/2' : 'px-16 pt-64'">
  <div class="mb-4 flex justify-end">
    <div class="close-icon-container">
      <img class="close-icon py-4" src="assets/icon/icon-close.png" (click)="onClickCloseVideoOverlay()" />
    </div>
  </div>

  <div class="grid grid-cols-12">
    <div class="col-start-1 col-end-13 aspect-w-16 aspect-h-9" [ngClass]="helperService.isLandscape ? 'mb-8' : 'mb-12'">
      <app-cookie-video-overlay *ngIf="!helperService.isCollectingCookies && helperService.isMobileDevice"></app-cookie-video-overlay>
      <iframe
        *ngIf="helperService.isCollectingCookies"
        [src]="helperService.getNoCookieUrl(selectedVideo.youtubeLink) | safe: 'resourceUrl'"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        sandbox="allow-scripts allow-same-origin"
      ></iframe>
    </div>
  </div>

  <div class="grid grid-cols-12">
    <div class="col-start-4 col-end-10">
      <app-overlay-slider (mediaChanged)="showThisMedia($event)" (videoChanged)="showThisVideo($event)" [videos]="videos"></app-overlay-slider>
    </div>
  </div>
</div>
