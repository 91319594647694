import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-home-connect.component',
  templateUrl: './home-connect.component.html',
  styleUrls: ['./home-connect.component.scss'],
})
export class HomeConnectComponent implements OnInit {
  blurredSub: Subscription;
  showMoreInformation: boolean = false;
  isBlurred: boolean = false;
  showVideoOverlay: boolean = false;
  constructor(public helperService: HelperService) {
    // blur background if a video is clicked
    this.blurredSub = this.helperService.backgroundBlur.subscribe((blurred: boolean) => {
      this.isBlurred = blurred;
    });
  }

  ngOnInit(): void {}

  onClickToggleInformation(): void {
    this.showMoreInformation = !this.showMoreInformation;
  }

  showOverlay(): void {
    this.showVideoOverlay = true;
    this.helperService.updatedBackgroundBlur(true);
  }

  closeVideoOverlay(): void {
    this.showVideoOverlay = false;
    this.helperService.updatedBackgroundBlur(false);
  }

  getLogoColor(): string {
    if (this.helperService.isMobile) return 'assets/img/bosch_logo.png';
    else return 'assets/img/bosch_logo_red.svg';
  }

  ngOnDestroy() {
    if (this.blurredSub) this.blurredSub.unsubscribe();
  }
}
