import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IVideo } from 'src/app/models/video.model';
import { HelperService } from 'src/app/services/helper.service';
import { MockdataService } from 'src/app/services/mockdata.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sallys-highlights',
  templateUrl: './sallys-highlights.component.html',
  styleUrls: ['./sallys-highlights.component.scss'],
})
export class SallysHighlightsComponent implements OnInit {
  blurredSub!: Subscription;
  videos: IVideo[] = [];
  selectedVideo!: IVideo;
  showVideoOverlay: boolean = false;
  isBlurred: boolean = false;

  constructor(private mockService: MockdataService, public helperService: HelperService) {
    if (environment.useMockData) {
      this.mockService.getLikeABoschVideos().then((res) => {
        this.videos = res.videos as IVideo[];
      });
    }
    // blur background if a video is clicked
    this.blurredSub = this.helperService.backgroundBlur.subscribe((blurred: boolean) => {
      this.isBlurred = blurred;
    });
  }

  ngOnInit(): void {}

  onVideoClick(video: IVideo): void {
    this.selectedVideo = video;
    this.showVideoOverlay = true;
    this.helperService.updatedBackgroundBlur(true);
  }

  closeVideoOverlay(): void {
    this.showVideoOverlay = false;
    this.helperService.updatedBackgroundBlur(false);
  }

  getSallyVideos(category: string, i: number) {
    this.mockService.getVideoWorldVideos(category).then((res) => {
      this.videos = res.videos as IVideo[];
      this.selectedVideo = this.videos[i - 1];
      this.showVideoOverlay = true;
      this.helperService.updatedBackgroundBlur(true);
    });
  }

  getLogoColor(): string {
    if (this.helperService.isMobile) return 'assets/img/bosch_logo.png';
    else return 'assets/img/bosch_logo_red.svg';
  }

  ngOnDestroy() {
    if (this.blurredSub) this.blurredSub.unsubscribe();
  }
}
