import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { NetworkService } from './network.service';
@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private networkService: NetworkService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && err.status === 0 && this.networkService._networkState) {
          // sets network state and start the internet check interval
          this.networkService.setNetworkState(false);
          this.networkService.setCheckInterval();
        }
        return throwError(() => new Error(err));
      }),
    );
  }
}
