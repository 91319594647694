import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LocalStorageKeys } from '../enums/local-storage-keys.model';
import { GlobalConstants } from '../global-constants';
import { ICatFilter } from '../models/catFilter.model';
import { IHighlights } from '../models/highlights.model';
import { IProduct } from '../models/product.model';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  windowWidth = GlobalConstants.windowWidth;
  windowHeight = GlobalConstants.windowHeight;
  windowOrientation = GlobalConstants.screenOrientation;
  private _screenSaverVideoIsMuted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  screenSaverVideoIsMuted: Observable<boolean> = this._screenSaverVideoIsMuted.asObservable();
  isLandscape: boolean = false;
  is4k: boolean = false;
  isMobile: boolean = false;
  isTablet: boolean = false;
  isTablet4k: boolean = false;
  isTabletLandscape: boolean = false;
  isMobileDevice = false;

  prodSubject = new BehaviorSubject<IProduct>({} as IProduct);

  collectCookieSubject = new BehaviorSubject<boolean>(false);
  cookieSub!: Subscription;
  isCollectingCookies!: boolean;

  showCookieOverlay = false;
  showSearchOverlay = false;

  bgUrl!: string;

  catBackClick: EventEmitter<string> = new EventEmitter<string>();
  backgroundBlur: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    this.is4kMode();
    this.isMobileMode();
    this.isTabletMode();
    this.isTabletLandscapeMode();
    this.isLandscapeMode();
    this.isTablet4kMode();
    this.checkIfMobileDevice();

    this.getCookieStatus();
    this.cookieSubscription();
  }

  getRightAsset(highlight: IHighlights) {
    // filters out image if a highlight contains a video && an usefull image
    const filteredArr = highlight.assets.filter(
      (e) =>
        e.category.includes('feature-video') ||
        e.category.includes('category.detail') ||
        e.category.includes('illustration-drawing') ||
        e.category.includes('food.mood.styling') ||
        e.category.includes('product-image') ||
        e.category.includes('product-video'),
    );
    if (filteredArr.length > 1) {
      const sortedAsssets = filteredArr.sort((x, y) => this.sortCategoryPrio(x.category) - this.sortCategoryPrio(y.category));
      return new Array(sortedAsssets[0]);
    } else return filteredArr;
  }

  //sorted categories prioritised - return a prio for a specific kind of category
  sortCategoryPrio(assetCategory: string) {
    if (assetCategory.includes('feature-video')) return 1;
    if (assetCategory.includes('category.detail')) return 2;
    if (assetCategory.includes('product-video')) return 3;
    if (assetCategory.includes('product-image')) return 4;
    if (assetCategory.includes('illustration-drawing')) return 5;
    else return 6;
  }

  // passes whole background URL and returns just the image number to keep URL clean
  getBgNumber(bgUrlPath: string) {
    const numberRegex = /\d+/g;
    const categoryNumber = bgUrlPath.match(numberRegex);
    return categoryNumber;
  }

  checkIfMobileDevice() {
    if (this.isMobile || this.isTabletLandscape || this.isTablet4k) {
      this.isMobileDevice = true;
    }
  }

  isLandscapeMode() {
    const mql = window.matchMedia('(orientation: landscape)');
    if (mql.matches && !this.isTabletLandscape && !this.isMobile) this.isLandscape = true;
    else this.isLandscape = false;
  }

  is4kMode() {
    // updates windowWidth after resize ( fired from app-component )
    this.windowWidth = GlobalConstants.windowWidth;
    if (this.windowWidth >= 2150) this.is4k = true;
    else this.is4k = false;
  }

  isMobileMode() {
    // updates windowWidth after resize and onInit ( fired from app-component )
    this.windowWidth = GlobalConstants.windowWidth;
    if (this.windowWidth <= 1024) this.isMobile = true;
    else this.isMobile = false;
  }

  isTabletMode() {
    // updates windowWidth after resize and onInit ( fired from app-component )
    this.windowWidth = GlobalConstants.windowWidth;
    if (this.windowWidth >= 680 && this.windowWidth <= 1024) this.isTablet = true;
    else this.isTablet = false;
  }

  isTablet4kMode() {
    // updates windowWidth after resize and onInit ( fired from app-component )

    const windowWidth = GlobalConstants.windowWidth;
    const windowHeight = GlobalConstants.windowHeight;
    if (windowWidth >= 1400 && windowWidth <= 2900 && windowHeight >= 2000 && windowHeight <= 2900) this.isTablet4k = true;
    else this.isTablet4k = false;
  }

  // Method for Landscape tablets normal and high resolution
  isTabletLandscapeMode() {
    const mql = window.matchMedia('(orientation: landscape)');
    const windowHight = GlobalConstants.windowHeight;
    const windowWidth = GlobalConstants.windowWidth;
    // checks different screen resolution for normal and 4k tablets
    if (
      mql.matches &&
      ((windowHight >= 700 && windowHight <= 1024 && windowWidth > 1024) || (windowHight >= 1200 && windowWidth >= 2000 && windowWidth <= 2900))
    )
      this.isTabletLandscape = true;
    else this.isTabletLandscape = false;
  }

  getProdTitle(title: string) {
    const titleArr = title.split(',');
    titleArr.pop();
    title = [...titleArr].toString();
    return title;
  }

  updateLastCatState(catFilter: ICatFilter) {
    localStorage.setItem(LocalStorageKeys.CATDATA, JSON.stringify(catFilter));
  }

  setLastCatTitle(prevCatTitle: string) {
    localStorage.setItem(LocalStorageKeys.CATEGORY_TITLE, prevCatTitle);
  }

  getLastCatTitile(): string {
    const title = localStorage.getItem(LocalStorageKeys.CATEGORY_TITLE)!;
    return title;
  }

  // Removes share button to prevent Edge browsers to exit out of app
  getNoCookieUrl(url: string) {
    const videoUrl = url.split('.com')[0];
    const videoId = url.split('.com')[1];
    return videoUrl + '-nocookie.com' + videoId;
  }

  getThumbnail(url: string) {
    if (url.includes('youtu.be')) {
      // function for shortened youtube links
      const videoId = url.split('.be/')[1];
      const embededUrl = 'https://img.youtube.com/vi/' + videoId + '/mqdefault.jpg';
      return embededUrl;
    } else {
      const videoId = url.split('=')[1];
      const thumbnail = 'https://img.youtube.com/vi/' + videoId + '/mqdefault.jpg';
      return thumbnail;
    }
  }

  getThumbnailFromEmbededUrl(url: string) {
    const videoEnding = url.split('/embed/')[1];
    const videoId = videoEnding.split('?')[0];
    const thumbnail = 'https://img.youtube.com/vi/' + videoId + '/0.jpg';
    return thumbnail;
  }

  getEmbededUrl(url: string) {
    if (url.includes('youtu.be')) {
      // function for shortened youtube links
      const videoId = url.split('.be/')[1];
      const embededUrl = 'https://www.youtube.com/embed/' + videoId;
      return embededUrl;
    } else {
      // function for normal youtube links
      const videoId = url.split('=')[1];
      const embededUrl = 'https://www.youtube.com/embed/' + videoId;
      return embededUrl;
    }
  }

  getLastCatState(): ICatFilter {
    const catString = localStorage.getItem(LocalStorageKeys.CATDATA);
    if (catString) return JSON.parse(catString) as ICatFilter;
    else
      return {
        categories: [],
        childCat: [],
        lastParentId: 0,
        curCatLevel: 0,
        colAmount: 3,
        showVideo: true,
        showResultBtn: true,
        showSally: true,
        bgUrl: '',
        mobileImageUrl: '',
        title: '',
      };
  }

  onCatBackClick(originPage: string) {
    this.catBackClick.emit(originPage);
  }

  updateVideoMutedState(currentState: boolean) {
    this._screenSaverVideoIsMuted.next(currentState);
  }

  updatedBackgroundBlur(blurred: boolean) {
    this.backgroundBlur.emit(blurred);
  }

  cookieSubscription() {
    this.cookieSub = this.collectCookieSubject.subscribe((collect: boolean) => {
      this.isCollectingCookies = collect;
    });
  }

  setCookieStatus(collectCookies: boolean) {
    if (collectCookies) {
      this.collectCookieSubject.next(true);
      localStorage.setItem('cookieStatus', 'allow');
    } else {
      this.collectCookieSubject.next(false);
      localStorage.setItem('cookieStatus', 'deny');
    }
  }

  getCookieStatus(): void {
    const cookieStatus = localStorage.getItem('cookieStatus');
    if (!this.isMobileDevice) {
      localStorage.setItem('cookieStatus', 'allow');
      this.collectCookieSubject.next(true);
    } else if (!cookieStatus) {
      this.showCookieOverlay = true;
      this.collectCookieSubject.next(false);
    } else if (cookieStatus === 'allow') {
      this.collectCookieSubject.next(true);
    } else {
      this.collectCookieSubject.next(false);
    }
  }
}
