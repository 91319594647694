<!--   PORTRAIT LAYOUT ALL VERSIONS  -->
<!--   PORTRAIT LAYOUT ALL VERSIONS  -->
<!--   PORTRAIT LAYOUT ALL VERSIONS  -->

<div
  *ngIf="!helperService.isLandscape"
  [ngClass]="helperService.isMobileDevice ? 'full-height pb-40' : 'inset-0'"
  class="absolute background-blur justify-center items-center z-10"
>
  <app-menu></app-menu>
  <div [ngClass]="helperService.isMobile ? 'padding-mobile md:px-8' : 'px-16 mt-16'">
    <img class="close-icon py-4" src="assets/icon/icon-close.png" (click)="onClickCloseSallyOverlay()" />
    <div class="grid grid-cols-1">
      <div
        *ngIf="sallysHint && sallysHint.videos.length > 0"
        [ngClass]="{ 'w-3/5': helperService.isTabletLandscape }"
        class="bg-default-col-bg shadow-md"
      >
        <div *ngIf="sallysHint && selectedVideo" class="aspect-w-16 aspect-h-9">
          <app-cookie-video-overlay *ngIf="!helperService.isCollectingCookies && helperService.isMobileDevice"></app-cookie-video-overlay>
          <iframe
            *ngIf="helperService.isCollectingCookies"
            [src]="helperService.getEmbededUrl(selectedVideo) | safe: 'resourceUrl'"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            sandbox="allow-scripts allow-same-origin"
          ></iframe>
        </div>
      </div>
      <div
        class=""
        [ngClass]="
          sallysHint && sallysHint.videos.length <= 0 ? 'grid grid-cols-12' : helperService.isMobileDevice ? 'flex flex-col' : 'grid grid-cols-12'
        "
      >
        <h2
          *ngIf="sallysHint && sallysHint.products.length > 0 && sallysHint && sallysHint.videos.length >= 1"
          [ngClass]="helperService.isMobile ? 'text-sm' : 'text-2xl'"
          class="text-white mt-12 col-span-12"
        >
          Das könnte dich auch interessieren:
        </h2>
        <div class="col-start-1 col-end-8" *ngIf="sallysHint && sallysHint.products.length <= 0">
          <div *ngIf="sallysHint && sallysHint.videos.length > 1">
            <p class="font-bold text-white mt-8 mb-4">Videos:</p>
          </div>
          <div class="grid grid-cols-2 gap-4">
            <div *ngFor="let video of getVideoListWithoutSelectedVideo(); let i = index" style="--animation-order:{{ i + 1 }}">
              <a class="fade-in-box" (click)="onVideoClick(video)">
                <img class="" src="{{ helperService.getThumbnail(video) }}" />
              </a>
            </div>
          </div>
        </div>
        <div class="col-start-1 col-end-4" *ngIf="sallysHint && sallysHint.products.length > 0">
          <div *ngIf="sallysHint && sallysHint.videos.length > 1">
            <p class="font-bold text-white mt-4 mb-4">Videos:</p>
          </div>
          <div class="gap-4">
            <div
              [ngClass]="{ 'w-1/2': helperService.isMobile }"
              class="col-start-1 col-end-4"
              *ngFor="let video of getVideoListWithoutSelectedVideo(); let i = index"
              style="--animation-order:{{ i + 1 }}"
            >
              <a class="fade-in-box" (click)="onVideoClick(video)">
                <img [ngClass]="helperService.is4k ? 'w-full' : ''" src="{{ helperService.getThumbnail(video) }}" />
              </a>
            </div>
          </div>
        </div>
        <div
          [ngClass]="
            sallysHint && sallysHint.products.length > 0 && sallysHint && sallysHint.videos.length > 1
              ? 'col-start-5 col-end-13'
              : 'col-start-1 col-end-13'
          "
        >
          <p
            *ngIf="sallysHint && sallysHint.products.length > 0 && sallysHint && sallysHint.videos.length >= 1"
            [ngClass]="{ 'text-small': helperService.isMobile }"
            class="font-bold text-white mt-4 mb-4"
          >
            Passende Produkte:
          </p>
          <div class="col-start-5 col-end-13" *ngIf="sallysHint && sallysHint.products.length > 0" class="slider-padding cursor-pointer">
            <app-sally-slider [bgUrl]="bgUrl" [sallysHint]="sallysHint"></app-sally-slider>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--   LANDSCAPE LAYOUT ALL VERSIONS  -->
<!--   LANDSCAPE LAYOUT ALL VERSIONS  -->
<!--   LANDSCAPE LAYOUT ALL VERSIONS  -->

<div *ngIf="helperService.isLandscape" class="absolute inset-0 background-blur justify-center items-center z-10">
  <app-menu></app-menu>
  <div class="px-16 mt-4">
    <img class="close-icon py-4" src="assets/icon/icon-close.png" (click)="onClickCloseSallyOverlay()" />
    <div class="grid grid-cols-12 gap-8">
      <div *ngIf="sallysHint && sallysHint.videos.length > 0" class="bg-default-col-bg shadow-md col-start-1 col-end-7">
        <div *ngIf="sallysHint && selectedVideo" class="aspect-w-16 aspect-h-9">
          <app-cookie-video-overlay *ngIf="!helperService.isCollectingCookies && helperService.isMobileDevice"></app-cookie-video-overlay>
          <iframe
            *ngIf="helperService.isCollectingCookies"
            [src]="helperService.getEmbededUrl(selectedVideo) | safe: 'resourceUrl'"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            sandbox="allow-scripts allow-same-origin"
          ></iframe>
        </div>
      </div>
      <div [ngClass]="sallysHint && sallysHint.videos.length <= 0 ? 'col-start-1 col-end-13' : 'col-start-7 col-end-13'">
        <h2 *ngIf="sallysHint && sallysHint.products.length > 0 && sallysHint && sallysHint.videos.length >= 1" class="text-2xl text-white mt-0">
          Das könnte dich auch interessieren:
        </h2>
        <div *ngIf="sallysHint && sallysHint.products.length <= 0" class="col-start-7 col-end-13">
          <div *ngIf="sallysHint && sallysHint.videos.length > 1">
            <p class="col-start-1 font-bold text-white mt-4 mb-4">Videos:</p>
          </div>
          <div class="grid grid-cols-2 gap-4">
            <div *ngFor="let video of getVideoListWithoutSelectedVideo(); let i = index" style="--animation-order:{{ i + 1 }}">
              <a class="fade-in-box" (click)="onVideoClick(video)">
                <img class="" src="{{ helperService.getThumbnail(video) }}" />
              </a>
            </div>
          </div>
        </div>
        <p *ngIf="sallysHint && sallysHint.products.length > 0 && sallysHint && sallysHint.videos.length >= 1" class="font-bold text-white mt-8 mb-4">
          Passende Produkte:
        </p>
        <div *ngIf="sallysHint && sallysHint.products.length > 0" class="px-18 cursor-pointer">
          <app-sally-slider [bgUrl]="bgUrl" [sallysHint]="sallysHint"></app-sally-slider>
        </div>
      </div>
    </div>

    <div *ngIf="sallysHint && sallysHint.products.length > 0" class="grid grid-cols-12 gap-8">
      <div class="col-start-7 col-end-13">
        <div *ngIf="sallysHint && sallysHint.videos.length > 1">
          <p class="col-start-1 font-bold text-white mb-4">Videos:</p>
        </div>
        <div *ngIf="sallysHint && sallysHint.videos.length === 2" class="grid grid-cols-2 gap-4 px-18">
          <div *ngFor="let video of getVideoListWithoutSelectedVideo(); let i = index" style="--animation-order:{{ i + 1 }}">
            <a class="fade-in-box w-1/2" (click)="onVideoClick(video)">
              <img [ngClass]="helperService.isLandscape && helperService.is4k ? 'w-full' : ''" src="{{ helperService.getThumbnail(video) }}" />
            </a>
          </div>
        </div>
        <div *ngIf="sallysHint && sallysHint.videos.length > 2" class="gap-4 px-18">
          <app-sally-video-slider (onVideoClick)="onVideoClick($event)" [videos]="videos"></app-sally-video-slider>
        </div>
      </div>
    </div>
  </div>
</div>
