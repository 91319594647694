<app-background imgPath="{{ bgUrl }}"></app-background>
<app-menu [logoPath]="'assets/img/bosch_logo_red.svg'" [showBack]="true" [showHome]="true" [showSearch]="true"></app-menu>
<div *ngIf="selectedProduct" class="px-16 xl:w-3/5 pt-32">
  <p class="text-white text-2xl mb-8">Deine {{ products.length }} Ergebnisse:</p>

  <div class="grid grid-cols-12">
    <div class="col-span-6">
      <img class="product-img" [src]="getProductImg(selectedProduct)" />
    </div>
    <div class="col-span-6 md:pl-16">
      <h2 class="text-white">{{ selectedProduct.title }}</h2>
      <h2 class="text-white font-bold">{{ selectedProduct.vib }}</h2>
      <p class="text-white mt-8 xl:mt-12">{{ selectedProduct.uspMain }}</p>
      <a (click)="onClickMoveToProductSite(selectedProduct)" class="block w-56 py-4 mt-8 xl:mt-12 px-8 max-w bg-default-col-bg shadow-md">
        <p class="text-center text-white text-xl">Mehr erfahren</p>
      </a>
    </div>
  </div>
  <div class="grid grid-cols-6 gap-4 mt-32 xl:mt-20">
    <div class="col-start-2 col-span-4 center-content">
      <div class="flex">
        <div class="button-prev-next bg-default-col-bg" (click)="onClickGetPreviousProduct()">
          <img class="menu-icon" src="assets/icon/icon-arrow-left.svg" />
        </div>
        <div class="currentStepContainer px-16">
          <p class="text-white text-2xl">{{ currentStepNumber }} / {{ products.length }}</p>
        </div>
        <div class="button-prev-next bg-default-col-bg" (click)="onClickGetNextProduct()">
          <img class="menu-icon" src="assets/icon/icon-arrow-right.gif" />
        </div>
      </div>
    </div>
  </div>
</div>
