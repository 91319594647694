import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { IProdsApiResponse } from 'src/app/models/apiResponse.model';
import { IProduct } from 'src/app/models/product.model';
import { ISallyHint } from 'src/app/models/sally-hint.model';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-sally-slider',
  templateUrl: './sally-slider.component.html',
  styleUrls: ['./sally-slider.component.scss'],
})
export class SallySliderComponent implements OnInit {
  @Input() sallysHint!: ISallyHint;
  @Input() bgUrl!: string;
  sallysProducts: IProduct[] = [];
  isLoading: boolean = false;
  selectedRelatedProduct: IProduct = {} as IProduct;
  showRelatedProductOverlay: boolean = false;
  productSubscription!: Subscription;

  constructor(private router: Router, private apiService: ApiService, public helperService: HelperService, public activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    // creates a long string of all vibs for api call
    let vibs = this.sallysHint.products.join(',');
    this.isLoading = true;
    this.productSubscription = this.apiService.getProducts(vibs).subscribe((res: IProdsApiResponse) => {
      this.sallysProducts = res.data;
      this.beautifyProdTitle();
      this.isLoading = false;
    });
  }

  closeRelatedProductOverlay(): void {
    this.showRelatedProductOverlay = false;
    this.helperService.updatedBackgroundBlur(false);
  }

  checkProductGroup(product: IProduct) {
    if (product.group === 'ACCESSORIES') this.openRelatedProductOverlay(product);
    else this.showThisProduct(product);
  }

  openRelatedProductOverlay(product: IProduct) {
    this.selectedRelatedProduct = product;
    this.showRelatedProductOverlay = true;
  }

  showThisProduct(product: IProduct) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    const bgUrl = this.bgUrl;
    const categoryNumber = this.helperService.getBgNumber(bgUrl);
    if (this.helperService.isMobileDevice) {
      this.router.navigateByUrl(`/produktdetails?ean=${product.ean}&bg=${categoryNumber}`);
    } else {
      this.router.navigateByUrl(`/produktdetails/ueberblick?ean=${product.ean}&bg=${categoryNumber}`);
    }
  }

  getProductImg(product: IProduct) {
    if (product.lowResPicture) return product.lowResPicture;
    else return product.highResPicture;
  }

  beautifyProdTitle() {
    this.sallysProducts.map((prod: IProduct) => {
      // product title always contains vib no. as well (redundant information)
      const titleElements = prod.title.split(' ');
      // remove last entry and create string afterwards
      titleElements.pop();
      let title = titleElements.join(' ');
      prod.title = title;
      return prod;
    });
  }

  ngOnDestroy() {
    if (this.productSubscription) this.productSubscription.unsubscribe();
  }

  /* Options for the media carousel */
  sallyOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 8,
    navSpeed: 700,
    nav: true,
    navText: ['<img class="arrow" src="assets/icon/icon-arrow-left.svg"/>', '<img class="arrow" src="assets/icon/icon-arrow-right.gif"/>'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 2,
      },
      940: {
        items: 2,
      },
    },
  };
}
