import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlatformModule } from '@angular/cdk/platform';
import { QRCodeModule } from 'angularx-qrcode';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwiperModule } from 'swiper/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BackgroundComponent } from './components/background/background.component';
import { ChoiceBadgeComponent } from './components/choice-badge/choice-badge.component';
import { CookieOverlayComponent } from './components/cookie-overlay/cookie-overlay.component';
import { CookieVideoOverlayComponent } from './components/cookie-video-overlay/cookie-video-overlay.component';
import { FooterComponent } from './components/footer/footer.component';
import { HighlightsOverlayComponent } from './components/highlights-overlay/highlights-overlay.component';
import { HighlightsSliderComponent } from './components/highlights-slider/highlights-slider.component';
import { HomeConnectOverlayComponent } from './components/home-connect-overlay/home-connect-overlay.component';
import { MediaOverlayComponent } from './components/media-overlay/media-overlay.component';
import { MediaSliderComponent } from './components/media-slider/media-slider.component';
import { MenuComponent } from './components/menu/menu.component';
import { OverlaySliderComponent } from './components/overlay-slider/overlay-slider.component';
import { ProductDetailsComponent } from './components/product-page/product-details/product-details.component';
import { ProductHighlightsComponent } from './components/product-page/product-highlights/product-highlights.component';
import { ProductMenuComponent } from './components/product-page/product-menu/product-menu.component';
import { ProductOverviewComponent } from './components/product-page/product-overview/product-overview.component';
import { RelatedProductsComponent } from './components/product-page/related-products/related-products.component';
import { RelatedProductsOverlayComponent } from './components/related-products-overlay/related-products-overlay.component';
import { RelatedProductsSliderComponent } from './components/related-products-slider/related-products-slider.component';
import { SallyOverlayComponent } from './components/sally-overlay/sally-overlay.component';
import { SallySliderComponent } from './components/sally-slider/sally-slider.component';
import { SallyVideoSliderComponent } from './components/sally-video-slider/sally-video-slider.component';
import { ScreensaverNoticeComponent } from './components/screensaver-notice/screensaver-notice.component';
import { SearchOverlayComponent } from './components/search-overlay/search-overlay.component';
import { SpecialSliderComponent } from './components/special-slider/special-slider.component';
import { VideoLibraryOverlayComponent } from './components/video-library-overlay/video-library-overlay.component';
import { VideoLibrarySliderComponent } from './components/video-library-slider/video-library-slider.component';
import { VideoWorldOverlayComponent } from './components/video-world-overlay/video-world-overlay.component';
import { AllFeaturesFilterComponent } from './pages/all-features-filter/all-features-filter.component';
import { AllFeaturesComponent } from './pages/all-features/all-features.component';
import { CategoryFilterComponent } from './pages/category-filter/category-filter.component';
import { FilterResultComponent } from './pages/filter-result/filter-result.component';
import { HomeConnectComponent } from './pages/home-connect/home-connect.component';
import { HomeComponent } from './pages/home/home.component';
import { LikeABoschComponent } from './pages/like-a-bosch/like-a-bosch.component';
import { OfferingsFilterComponent } from './pages/offerings-filter/offerings-filter.component';
import { OfferingsResultComponent } from './pages/offerings-result/offerings-result.component';
import { OfferingsComponent } from './pages/offerings/offerings.component';
import { ProductPageComponent } from './pages/product-page/product-page.component';
import { ProductWorldComponent } from './pages/product-world/product-world.component';
import { SallysHighlightsComponent } from './pages/sallys-highlights/sallys-highlights.component';
import { ScreensaverComponent } from './pages/screensaver/screensaver.component';
import { SensorTechnologyComponent } from './pages/sensor-technology/sensor-technology.component';
import { VideoLibraryComponent } from './pages/video-library/video-library.component';
import { VideoWorldComponent } from './pages/video-world/video-world.component';
import { SafePipe } from './pipes/safe.pipe';
import { SplitPipe } from './pipes/split.pipe';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { StyleDirective } from './style.directive';

@NgModule({
  declarations: [
    AppComponent,
    ScreensaverComponent,
    BackgroundComponent,
    MenuComponent,
    ProductWorldComponent,
    CategoryFilterComponent,
    HomeComponent,
    LikeABoschComponent,
    VideoWorldOverlayComponent,
    SafePipe,
    StyleDirective,
    ChoiceBadgeComponent,
    SpecialSliderComponent,
    FilterResultComponent,
    ProductPageComponent,
    ProductMenuComponent,
    ProductHighlightsComponent,
    ProductDetailsComponent,
    RelatedProductsComponent,
    ProductOverviewComponent,
    ScreensaverNoticeComponent,
    MediaSliderComponent,
    RelatedProductsSliderComponent,
    MediaOverlayComponent,
    RelatedProductsOverlayComponent,
    OverlaySliderComponent,
    SallyOverlayComponent,
    SallySliderComponent,
    OfferingsComponent,
    OfferingsFilterComponent,
    OfferingsResultComponent,
    SplitPipe,
    HighlightsOverlayComponent,
    HighlightsSliderComponent,
    VideoWorldComponent,
    HomeConnectComponent,
    HomeConnectOverlayComponent,
    SensorTechnologyComponent,
    SallysHighlightsComponent,
    AllFeaturesComponent,
    VideoLibraryComponent,
    VideoLibraryOverlayComponent,
    VideoLibrarySliderComponent,
    AllFeaturesFilterComponent,
    SallyVideoSliderComponent,
    CookieOverlayComponent,
    CookieVideoOverlayComponent,
    FooterComponent,
    SearchOverlayComponent,
  ],
  imports: [
    BrowserModule,
    SwiperModule,
    AppRoutingModule,
    HttpClientModule,
    CarouselModule,
    BrowserAnimationsModule,
    QRCodeModule,
    FormsModule,
    ReactiveFormsModule,
    PlatformModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
