import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMediaContent } from 'src/app/models/mediaType.model';
import { ISallyHint } from 'src/app/models/sally-hint.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-sally-overlay',
  templateUrl: './sally-overlay.component.html',
  styleUrls: ['./sally-overlay.component.scss'],
})
export class SallyOverlayComponent implements OnInit {
  @Output() closeSallyOverlay = new EventEmitter();
  @Input() sallysHint?: ISallyHint;
  @Input() bgUrl!: string;
  selectedVideo?: string;
  videos?: string[];
  selectedMedia!: IMediaContent;

  constructor(public helperService: HelperService) {}

  ngOnInit(): void {
    if (this.sallysHint!.videos.length > 0) {
      this.selectedVideo = this.sallysHint!.videos[0];
      this.videos = this.sallysHint!.videos;
    }
  }

  onVideoClick(video: string) {
    this.selectedVideo = video;
  }

  getVideoListWithoutSelectedVideo() {
    if (this.videos && this.videos.length > 1) return this.videos.filter((x) => x !== this.selectedVideo);
    else return;
  }

  onClickCloseSallyOverlay() {
    this.closeSallyOverlay.emit();
  }

  /**
   * switches the media in the iframe
   * @param selectedMedia selected media from sally-slider-component
   */
  showThisMedia(selectedMedia: IMediaContent) {
    this.selectedMedia = selectedMedia;
  }
}
