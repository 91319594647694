import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { IHighlights } from 'src/app/models/highlights.model';
import { IProduct } from 'src/app/models/product.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-highlights-slider',
  templateUrl: './highlights-slider.component.html',
  styleUrls: ['./highlights-slider.component.scss'],
})
export class HighlightsSliderComponent implements OnInit {
  @Output() mediaChanged = new EventEmitter();
  @Input() selectedProduct!: IProduct;
  @Input() selectedHighlightArray!: IHighlights[];

  constructor(public helperService: HelperService) {}

  ngOnInit(): void {}

  // Triggers resize event to show proper amount of items for mobile versions
  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  getThumbnail(url: string) {
    return this.helperService.getThumbnail(url);
  }

  /**
   * changes highlight shown in highlight-overlay-component
   * @param highlight clicked highlight data
   */
  showThisHighlight(highlight: IHighlights) {
    this.mediaChanged.emit(highlight);
  }

  mediaOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin: 8,
    navSpeed: 700,
    navText: ['<img class="arrow" src="assets/icon/icon-arrow-left.svg"/>', '<img class="arrow" src="assets/icon/icon-arrow-right.gif"/>'],
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      740: {
        items: 2,
      },
      940: {
        items: 2,
      },
    },
    nav: true,
  };
}
