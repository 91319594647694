<!--        NORMAL PORTRAIT LAYOUT          -->
<!--        NORMAL PORTRAIT LAYOUT          -->
<!--        NORMAL PORTRAIT LAYOUT          -->
<div *ngIf="!helperService.isLandscape && !helperService.isTabletLandscape" [ngClass]="showSallyOverlay || showMediaOverlay ? 'unscrollable' : ''">
  <div
    *ngIf="!helperService.isLandscape && !helperService.isTabletLandscape"
    [ngClass]="[helperService.isMobile ? 'padding-mobile md:px-8' : 'px-16 mt-4', showSallyOverlay || showMediaOverlay ? 'unscrollable' : '']"
    class="potrait"
  >
    <div [ngClass]="helperService.isMobile ? 'pt-4 ' : 'grid grid-cols-12'">
      <div (click)="openMediaOverlay(selectedMedia)" class="img-container col-1 col-span-6 relative">
        <img class="play-icon" src="assets/icon/videoPlay.png" *ngIf="selectedMedia.type === 'video.'" />
        <img
          *ngIf="!isLoading"
          [src]="getCurrentProductImage()"
          (load)="isLoadingFalse()"
          alt="Produktbild"
          class="mb-4 product-img object-contain"
        />
      </div>
      <app-media-slider
        *ngIf="getProductImages().length > 1 && helperService.isMobile"
        (mediaChanged)="showThisMedia($event)"
        [selectedMediaArray]="getProductImages()"
        [selectedProduct]="selectedProduct"
      ></app-media-slider>
      <div [ngClass]="helperService.isMobile ? 'mt-8' : 'mt-4 ml-16'" class="col-2 col-span-6">
        <h3
          [ngClass]="{ 'pb-2': !helperService.isMobile && !helperService.isTablet, 'text-xs': helperService.isMobile }"
          class="text-white font-bold"
        >
          {{ selectedProduct.vib }}
        </h3>
        <div
          [ngClass]="helperService.isMobile && !helperService.isTablet ? 'leading-3' : 'pb-2'"
          *ngFor="let feature of selectedProduct.usps"
          class="inline-block"
        >
          <h3 [ngClass]="{ 'text-xs': helperService.isMobile }" class="text-white font-bold inline">
            {{ feature | split: ':':0 }}
          </h3>
          <p [ngClass]="{ 'text-xs': helperService.isMobile }" class="text-white inline">: {{ feature | split: ':':1 }}</p>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="!helperService.isLandscape && !helperService.isTabletLandscape"
    [ngClass]="helperService.isMobile ? 'padding-mobile md:px-8' : helperService.isTabletLandscape ? 'px-16 pb-16 mt-8' : 'px-20 mt-8'"
  >
    <div [ngClass]="{ 'grid grid-cols-12': !helperService.isMobile }">
      <div class="col-1 col-span-6 mt-3">
        <app-media-slider
          *ngIf="getProductImages().length > 1 && !helperService.isMobile"
          (mediaChanged)="showThisMedia($event)"
          [selectedMediaArray]="getProductImages()"
          [selectedProduct]="selectedProduct"
        ></app-media-slider>

        <div class="features-wrap col-start-2 col-end-5">
          <ng-container *ngFor="let feature of getFeatureIcons()">
            <div
              [ngClass]="(helperService.isMobile && !helperService.isTablet) || helperService.isTablet ? 'mt-4' : 'w-1/4 mt-8'"
              *ngIf="!feature.asset_id.includes('ENERGYLABEL')"
              class="col-1 col-span-6 feature icon-container"
            >
              <img class="feature-icon" src="{{ feature.url }}" alt="feature-icon" />
            </div>
          </ng-container>
        </div>
        <div class="features-wrap col-start-2 col-end-5">
          <ng-container *ngFor="let feature of getFeatureIcons()">
            <div
              [ngClass]="{ 'w-1/4 mt-2': helperService.isMobile && !helperService.isTablet }"
              *ngIf="feature.asset_id.includes('ENERGYLABEL')"
              class="col-1 col-span-6 feature icon-container"
            >
              <img class="feature-icon object-contain" src="{{ feature.url }}" alt="feature-icon" />
            </div>
          </ng-container>
        </div>
      </div>

      <div [ngClass]="{ 'lg:pl-16': !helperService.isTablet }" (click)="openSallyOverlay()" *ngIf="sallysHint" class="col-2 col-span-6">
        <div [ngClass]="{ 'mt-8': helperService.isMobile && !helperService.isTablet, 'mt-12': helperService.isTablet }">
          <div class="sally-wrap">
            <div class="sally-container">
              <div [ngClass]="{ 'pt-20': !helperService.isMobile && !helperService.isTablet }" class="icon-container">
                <img class="sally-tipp" src="assets/img/sally/sallysTipp.png" alt="sally-tipp" />
                <div *ngIf="sallysHint.videos.length > 0" class="sally-play-icon-container">
                  <img class="sally-play-icon" src="assets/img/sally/sallyVideo.svg" alt="sally-play" />
                </div>
                <div *ngIf="sallysHint.videos.length === 0" class="sally-plus-icon-container">
                  <img class="sally-plus-icon" src="assets/img/sally/sallyPlus.svg" alt="sally-plus" />
                </div>
              </div>
              <h4 [ngClass]="helperService.isTablet4k ? 'text-base' : 'text-xs'" class="text-white font-bold pt-2 pb-2">
                {{ sallysHint.hint }}
              </h4>
            </div>
            <div class="sallys-img-container">
              <img class="sallys-img" src="assets/img/sally/sally.png" alt="sallys-bild" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--  LANDSCAPE LAYOUT  -->
<!--  LANDSCAPE LAYOUT  -->
<!--  LANDSCAPE LAYOUT  -->
<div
  *ngIf="helperService.isLandscape || helperService.isTabletLandscape"
  [ngClass]="showSallyOverlay || showMediaOverlay ? 'unscrollable' : ''"
  class="px-16"
>
  <div
    [ngClass]="
      helperService.isTabletLandscape
        ? 'grid grid-cols-8'
        : helperService.isTabletLandscape && showSallyOverlay
        ? 'unscrollable'
        : 'grid grid-cols-12'
    "
  >
    <div class="col-start-1 col-span-3">
      <div class="relative">
        <img (click)="openMediaOverlay(selectedMedia)" class="play-icon" src="assets/icon/videoPlay.png" *ngIf="selectedMedia.type === 'video.'" />
        <img
          (click)="openMediaOverlay(selectedMedia)"
          *ngIf="!isLoading"
          [src]="getCurrentProductImage()"
          (load)="isLoadingFalse()"
          alt="Produktbild"
          class="object-contain product-img mb-8"
        />
      </div>

      <app-media-slider
        (mediaChanged)="showThisMedia($event)"
        [selectedMediaArray]="getProductImages()"
        [selectedProduct]="selectedProduct"
      ></app-media-slider>
    </div>

    <div class="col-start-5 col-end-8 mt-2 ml-0">
      <h3 class="text-white pb-2 font-bold">{{ selectedProduct.vib }}</h3>
      <div *ngFor="let feature of selectedProduct.usps" class="inline-block pb-2">
        <h3 class="text-white font-bold inline">{{ feature | split: ':':0 }}</h3>
        <p class="text-white inline">: {{ feature | split: ':':1 }}</p>
      </div>

      <div class="mt-8 icon-container gap-2">
        <ng-container *ngFor="let feature of getFeatureIcons()">
          <div *ngIf="!feature.asset_id.includes('ENERGYLABEL')" class="col-2 feature">
            <img class="feature-icon-diagonal" src="{{ feature.url }}" alt="feature-icon" />
          </div>
        </ng-container>
      </div>
      <div class="mt-2 icon-container gap-2">
        <ng-container *ngFor="let feature of getFeatureIcons()">
          <div *ngIf="feature.asset_id.includes('ENERGYLABEL')" class="col-2 feature">
            <img class="feature-icon-diagonal object-contain" src="{{ feature.url }}" alt="feature-icon" />
          </div>
        </ng-container>
      </div>
    </div>

    <div
      [ngClass]="helperService.isTabletLandscape ? 'col-start-5' : 'col-start-9'"
      class="col-end-12"
      *ngIf="sallysHint"
      (click)="openSallyOverlay()"
    >
      <div class="sally-wrap-diagonal">
        <div class="sally-container">
          <div [ngClass]="helperService.isTabletLandscape ? 'pt-12' : 'pt-16'" class="icon-container">
            <img class="sally-tipp" src="assets/img/sally/sallysTipp.png" alt="sally-tipp" />
            <div *ngIf="sallysHint.videos.length > 0" class="sally-play-icon-container">
              <img class="sally-play-icon" src="assets/img/sally/sallyVideo.svg" alt="sally-play" />
            </div>
            <div *ngIf="sallysHint.videos.length === 0" class="sally-plus-icon-container">
              <img class="sally-plus-icon" src="assets/img/sally/sallyPlus.svg" alt="sally-plus" />
            </div>
          </div>
          <h4 class="text-sm text-white font-bold pt-2 pb-2">{{ sallysHint.hint }}</h4>
        </div>
        <div class="sallys-img-container">
          <img class="sallys-img" src="assets/img/sally/sally.png" alt="sallys-bild" />
        </div>
      </div>
    </div>
  </div>
</div>
<app-sally-overlay *ngIf="showSallyOverlay" [sallysHint]="sallysHint" [bgUrl]="bgUrl" (closeSallyOverlay)="closeSallyOverlay()"></app-sally-overlay>

<app-media-overlay
  [selectedMedia]="selectedMedia"
  [selectedMediaArray]="getProductImages()"
  (closeVideoOverlay)="closeVideoOverlay()"
  [selectedProduct]="selectedProduct"
  *ngIf="showMediaOverlay"
></app-media-overlay>
