<app-background [imgPath]="getBackgroundImageUrl()"></app-background>

<app-menu [logoPath]="getLogoColor()" [showBack]="true" [showHome]="true" [showSearch]="true"></app-menu>
<div
  class="container-type-inline"
  [ngClass]="[
    helperService.isLandscape
      ? 'xl:w-3/5 px-16 mt-28'
      : helperService.isTabletLandscape
      ? 'w-4/5 px-16 mt-28'
      : helperService.isMobile
      ? 'padding-mobile md:px-8 pb-mobile mt-0'
      : 'px-16 mt-28 pb-2'
  ]"
>
  <div class="grid auto-rows-fr gap-2 text-white" [ngClass]="helperService.isMobile ? 'grid-cols-2' : 'grid-cols-3'">
    <div
      *ngFor="let cat of getVisibleCat(); let i = index"
      style="--animation-order:{{ i }}"
      (click)="onCategoryClick(cat)"
      class="box-effekt-1 bg-default-col-bg fade-in-box min-h-box"
      [ngClass]="helperService.isMobile ? 'p-0 pb-2' : helperService.isTablet4k ? 'p-4 h-36' : 'p-4 pb-10'"
    >
      <img *ngIf="helperService.isMobile" class="object-cover w-full" src="assets/img/background_images/category{{ i + 1 }}-mobile.jpg " alt="" />

      <div [ngClass]="helperService.isMobile && !helperService.isTablet ? 'p-2 pt-3' : helperService.isTablet ? 'p-4 pt-3 pb-8' : 'p-0'">
        <p class="font-bold product-world-title">{{ cat.title }}</p>
      </div>
    </div>
  </div>
</div>
