import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-cookie-overlay',
  templateUrl: './cookie-overlay.component.html',
  styleUrls: ['./cookie-overlay.component.scss'],
})
export class CookieOverlayComponent implements OnInit {
  @Output() closeCookieOverlay = new EventEmitter();

  constructor(public helperService: HelperService) {}

  ngOnInit(): void {}

  allowCookies() {
    this.helperService.setCookieStatus(true);
    this.closeCookieOverlay.emit();
  }

  denyCookies() {
    this.helperService.setCookieStatus(false);
    this.closeCookieOverlay.emit();
    window.location.reload();
  }
}
