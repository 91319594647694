<app-background [imgPath]="'assets/img/video-world/home-connect/home-connect.jpg'"></app-background>
<app-menu [logoPath]="getLogoColor()" [showSearch]="true" [showHome]="true" [showShare]="true" [showBack]="true" [showBurger]="true"></app-menu>
<div
  [ngClass]="
    helperService.isLandscape
      ? 'pl-16 w-3/5 mt-24'
      : helperService.isMobile
      ? 'padding-mobile md:px-8 pb-mobile'
      : helperService.isTabletLandscape
      ? 'mt-12 px-16'
      : 'px-16 mt-24'
  "
>
  <div [ngClass]="{ 'bg-blur': isBlurred, 'grid grid-cols-12 gap-x-4': !helperService.isMobile, 'w-9/12': helperService.isTabletLandscape }">
    <div class="col-span-7 xl:col-span-8">
      <p *ngIf="helperService.isMobile" class="text-small m-0 mb-2 text-white">Home Connect</p>
      <img *ngIf="helperService.isMobile" class="object-cover w-full" src="assets/img/video-world/home-connect/home-connect-mobile.jpg" alt="" />
      <div [ngClass]="helperService.isMobile ? 'p-4 mt-2' : 'p-8'" *ngIf="!showMoreInformation" class="block bg-default-col-bg">
        <h1 [ngClass]="{ 'text-base': helperService.isMobile && !helperService.isTablet }" class="mb-2 font-normal text-white">
          Smart today. <br *ngIf="!helperService.isMobile" />
          Smart tomorrow.
        </h1>
        <p [ngClass]="helperService.isMobile ? 'text-xs' : 'text-xl mt-4 mb-10'" class="text-white">
          Wir glauben, dass sich ein smartes Zuhause an dich anpassen sollte. Mit Home Connect kannst du die Bosch Hausgeräte über deine mobilen
          Endgeräte steuern und überwachen – ganz gleich, wo du dich befindest. Darüber hinaus schließen wir fortwährend neue Partnerschaften und
          ergänzen neue, hilfreiche Funktionen sowie Services. Mit Home Connect sind deine Hausgeräte für eine smarte Zukunft bestens ausgestattet –
          Smart today. Smart tomorrow.
        </p>
      </div>

      <div *ngIf="showMoreInformation" [ngClass]="helperService.isMobile ? 'mb-2 mt-2 p-4' : 'p-8'" class="block bg-default-col-bg">
        <div *ngIf="showMoreInformation && helperService.isMobile" class="mt-2 close-button bg-default-col-bg" (click)="onClickToggleInformation()">
          <img class="" src="assets/icon/icon-close.png" />
        </div>
        <h1 [ngClass]="helperService.isMobile ? 'text-xs' : ''" class="text-white font-medium">Deine Vorteile mit Home Connect:</h1>
        <ul style="list-style-type: square" class="pl-22-px">
          <li [ngClass]="helperService.isMobile ? 'text-small' : 'text-xl'" class="text-white mt-4">Fernsteuerung deiner Hausgeräte</li>
          <li [ngClass]="helperService.isMobile ? 'text-small' : 'text-xl'" class="text-white mt-2">
            Riesige Rezeptauswahl über die Home Connect App
          </li>
          <li [ngClass]="helperService.isMobile ? 'text-small' : 'text-xl'" class="text-white mt-2">
            Rezepteinstellungen aus Partner Apps direkt an den Backofen senden (Sallys Welt, Simply Yummy)
          </li>
          <li [ngClass]="helperService.isMobile ? 'text-small' : 'text-xl'" class="text-white mt-2">Sprachsteuerung</li>
          <li [ngClass]="helperService.isMobile ? 'text-small' : 'text-xl'" class="text-white mt-2">
            Einstellbare Push-Nachrichten (Zustand des Dunstabzugsfilters, Menge der Geschirrspüler-Tabs etc.)
          </li>
          <li [ngClass]="helperService.isMobile ? 'text-small' : 'text-xl'" class="text-white mt-2">
            Persönlich abgestimmt (Favoritenprogramme, Speichern von Lieblings-Kaffeespezialitäten)
          </li>
          <li [ngClass]="helperService.isMobile ? 'text-small' : 'text-xl'" class="text-white mt-2">Service und Ferndiagnose</li>
          <li [ngClass]="helperService.isMobile ? 'text-small' : 'text-xl'" class="text-white mt-2">Steuerung des Dunstabzugs über das Kochfeld</li>
        </ul>
        <p [ngClass]="helperService.isMobile ? 'text-xs' : 'text-xl'" class="text-white mt-4">und viele weitere vernetzte Funktionen</p>

        <!-- <p class="text-white mt-4">
          Du willst den Backofen vorheizen, während du auf dem Nachhauseweg bist? Mit der Home Connect App kannst du all deine Bosch Hausgeräte bequem
          von unterwegs steuern und überprüfen.
        </p>
        <h2 class="mt-4 font-bold text-white">Dank Fernüberwachung unbekümmert unterwegs</h2>
        <p class="text-white mt-4">
          Diese Frage haben wir uns alle schon mal gestellt: Habe ich den Backofen ausgemacht? Dank Home Connect gehören Fragen wie diese der
          Vergangenheit an, denn du kannst stets beruhigt sein. Überprüfe den Status deiner Hausgeräte einfach mit dem Smartphone und schalte sie mit
          der Home Connect App je nach Bedarf ein oder aus.
        </p>
        <h2 class="mt-4 font-bold text-white">Eine App sagt mehr als tausend Worte.</h2>
        <p class="text-white mt-4">
          Die Home Connect App ist das Kontrollzentrum für all deine Bosch Hausgeräte. Sie lässt sich einfach per Smartphone oder Tablet bedienen, ist
          stets auf dem Laufenden und wird kontinuierlich durch neue Funktionen verbessert. Downloade die App noch heute kostenlos und probiere die
          Demo-Version aus, um das volle Potenzial von Home Connect zu erleben.
        </p> -->
      </div>

      <a
        *ngIf="!showMoreInformation"
        (click)="onClickToggleInformation()"
        [ngClass]="helperService.isMobile ? 'p-3 mt-2 mb-2' : ' py-4 mt-2 px-8'"
        class="block bg-default-col-bg shadow-md"
      >
        <p [ngClass]="helperService.isMobile ? 'text-xs m-0' : 'text-xl'" class="text-center text-white">
          Erfahre mehr über unsere vernetzten Hausgeräte.
        </p>
      </a>

      <div *ngIf="showMoreInformation && !helperService.isMobile" class="mt-2 close-button bg-default-col-bg" (click)="onClickToggleInformation()">
        <img class="" src="assets/icon/icon-close.png" />
      </div>
    </div>

    <div [ngClass]="{ 'justify-end': !helperService.isMobile }" class="col-span-5 xl:col-span-4 grid gap-4">
      <a [ngClass]="{ 'w-2/4': helperService.isMobile }" class="fade-in-box" (click)="showOverlay()">
        <img
          [ngClass]="helperService.is4k && helperService.isLandscape ? 'w-full' : ''"
          src="assets/img/video-world/home-connect/home-connect-video.jpg"
        />
        <p [ngClass]="helperService.isMobile ? 'text-small pt-2' : 'text-sm p-3 bg-default-col-bg shadow-md'" class="text-white">
          <b>HOME CONNECT</b> - HEUTE SMART, MORGEN SMART
        </p>
      </a>
    </div>
  </div>
</div>

<app-home-connect-overlay *ngIf="showVideoOverlay" (closeVideoOverlay)="closeVideoOverlay()"></app-home-connect-overlay>
