<div [ngClass]="helperService.isMobile || helperService.isTabletLandscape ? 'full-height' : 'inset-0'" class="absolute background-blur z-10">
  <app-menu></app-menu>
  <div [ngClass]="helperService.isMobile ? 'padding-mobile md:px-8 pb-mobile' : helperService.isTabletLandscape ? 'px-16 mt-16 pb-8' : 'px-16 mt-32'">
    <div class="grid" [ngClass]="helperService.isLandscape ? 'grid-cols-12 gap-8' : 'grid-cols-1'">
      <div
        class="bg-default-col-bg shadow-md"
        [ngClass]="helperService.isLandscape ? 'col-start-1 col-end-7' : helperService.isTabletLandscape ? 'w-3/4' : 'col-span-12'"
      >
        <img class="close-icon py-4" src="assets/icon/icon-close.png" (click)="onClickCloseVideoOverlay()" />
        <div class="aspect-w-16 aspect-h-9">
          <app-cookie-video-overlay *ngIf="!helperService.isCollectingCookies && helperService.isMobileDevice"></app-cookie-video-overlay>
          <iframe
            *ngIf="helperService.isCollectingCookies"
            src="https://www.youtube.com/embed/pziZNqF_G_Y"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            sandbox="allow-scripts allow-same-origin"
          ></iframe>
        </div>
        <p [ngClass]="helperService.isMobile && !helperService.isTablet ? 'text-small mt-2' : 'py-4 pl-6 text-sm'" class="text-white">
          So smart sind deine Geräte dank Home Connect verknüpft | Bosch Neuheiten mit Sally
        </p>
      </div>

      <div [ngClass]="helperService.isLandscape ? 'col-start-7 col-end-13' : ''">
        <h3
          class="text-white"
          [ngClass]="
            helperService.isLandscape
              ? 'mt-4 mb-4 text-2xl'
              : helperService.isMobile && !helperService.isTablet
              ? 'text-xs mt-12'
              : 'mt-16 mb-8 text-2xl'
          "
        >
          Zu den Produkten mit Home Connect:
        </h3>

        <p [ngClass]="{ 'text-xs': helperService.isMobile && !helperService.isTablet }" class="text-white font-bold">Produkte:</p>

        <div [ngClass]="helperService.isMobile && !helperService.isTablet ? 'grid-cols-2 gap-2 mt-2' : 'grid-cols-3 gap-2 mt-4 mb-2'" class="grid">
          <div
            (click)="
              onProductClick(
                'program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=OVENS&steam_functions:not=ADDEDSTEAM,HS_HOTAIR_STEAM&start_date:gt=2022-12-31&feature_ids=10613801&per_page=100'
              )
            "
            style="--animation-order: 1"
            class="product-box fade-in-box"
          >
            <p
              [ngClass]="helperService.isMobile && !helperService.isTablet ? 'text-xs h-32' : 'h-44'"
              class="p-5 text-white font-bold bg-default-col-bg backoefen shadow-md"
            >
              Backöfen mit <br />
              Home Connect Funktion
            </p>
          </div>
          <div
            (click)="
              onProductClick(
                'program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=HOBS&cooker_hob_type=COOKER_HOB_TYPE.INDUCTION&feature_ids:and=10613941'
              )
            "
            style="--animation-order: 2"
            class="product-box fade-in-box"
          >
            <p
              [ngClass]="helperService.isMobile && !helperService.isTablet ? 'text-xs h-32' : 'h-44'"
              class="p-5 text-white font-bold bg-default-col-bg kochfelder"
            >
              Kochfelder und Dunstabzüge mit Home Connect Funktion
            </p>
          </div>
          <div
            (click)="onProductClick('program=accent | line,accent | line carbon black&group=BUILTIN_CM&start_date:gt=2022-12-31')"
            style="--animation-order: 3"
            class="product-box fade-in-box"
          >
            <p
              [ngClass]="helperService.isMobile && !helperService.isTablet ? 'text-xs h-32' : 'h-44'"
              class="p-5 text-white font-bold bg-default-col-bg kaffeevollautomaten"
            >
              Kaffeevollautomaten mit Home Connect Funktion
            </p>
          </div>
          <div
            (click)="
              onProductClick(
                'program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=REFRIGERATORS,FREEZERS,FRIDGE_FREEZER_COMBINATIONS&per_page=100&feature_ids=%2010613806'
              )
            "
            style="--animation-order: 4"
            class="product-box fade-in-box"
          >
            <p
              [ngClass]="helperService.isMobile && !helperService.isTablet ? 'text-xs h-32' : 'h-44'"
              class="p-5 text-white font-bold bg-default-col-bg kuehlschraenke"
            >
              Kühlschränke mit Home Connect Funktion
            </p>
          </div>
          <div
            (click)="
              onProductClick(
                'program=accent%20|%20line,accent%20|%20line%20carbon%20black&group=DISHWASHERS&feature_ids=13418821,10613809,13417875,10613937,11630428'
              )
            "
            style="--animation-order: 5"
            class="product-box fade-in-box"
          >
            <p
              [ngClass]="helperService.isMobile && !helperService.isTablet ? 'text-xs h-32' : 'h-44'"
              class="p-5 text-white font-bold bg-default-col-bg geschirrspueler"
            >
              Geschirrspüler mit Home Connect Funktion
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
