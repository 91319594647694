import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IVideo } from 'src/app/models/video.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-video-world-overlay',
  templateUrl: './video-world-overlay.component.html',
  styleUrls: ['./video-world-overlay.component.scss'],
})
export class VideoWorldOverlayComponent implements OnInit {
  @Output() closeVideoOverlay = new EventEmitter();
  @Input() selectedVideo!: IVideo;
  @Input() videos!: IVideo[];
  @Input() video!: IVideo;

  cookieSub: Subscription;

  collectCookies!: boolean;
  bgUrl: string = 'assets/img/background_images/category1.png';
  constructor(public router: Router, public helperService: HelperService) {
    this.cookieSub = this.helperService.collectCookieSubject.subscribe((collect: boolean) => {
      this.collectCookies = collect;
    });
  }

  ngOnInit(): void {}

  onClickCloseVideoOverlay() {
    this.closeVideoOverlay.emit();
  }

  onVideoClick(video: IVideo): void {
    this.selectedVideo = video;
  }

  getVideoLink(imageLink: string) {
    if (this.helperService.isMobile) {
      return imageLink.split('.')[0] + '-mobile.jpg';
    } else {
      return imageLink;
    }
  }

  getProductViaEndpoint(endpoint: string) {
    this.getBgUrl(endpoint);

    const bgURL = this.helperService.getBgNumber(this.bgUrl);
    this.router.navigateByUrl(`/suchergebnisse?endpoint=${endpoint}&bgUrl=${bgURL}`);
  }

  getVideoListWithoutSelectedVideo(): IVideo[] {
    return this.videos.filter((x) => x.id !== this.selectedVideo.id);
  }

  getBgUrl(endpoint: string) {
    if (endpoint.includes('OVENS')) this.bgUrl = 'assets/img/background_images/category1.jpg';
    else if (endpoint.includes('VENTING_HOBS')) this.bgUrl = 'assets/img/background_images/category4.jpg';
    else if (endpoint.includes('HOBS')) this.bgUrl = 'assets/img/background_images/category5.jpg';
    else if (endpoint.includes('BUILTIN_CM')) this.bgUrl = 'assets/img/background_images/category3.jpg';
    else if (endpoint.includes('FRIDGE_FREEZER_COMBINATIONS')) this.bgUrl = 'assets/img/background_images/category7.jpg';
    else if (endpoint.includes('DISHWASHERS')) this.bgUrl = 'assets/img/background_images/category9.jpg';
    else if (endpoint.includes('TYPOLOGY.DOWH')) this.bgUrl = 'assets/img/background_images/category6.jpg';
    else this.bgUrl = 'assets/img/background_images/category1.jpg';
  }

  ngOnDestroy() {
    if (this.cookieSub) this.cookieSub.unsubscribe();
  }
}
