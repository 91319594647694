import { Component, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { IAsset } from 'src/app/models/assets.model';
import { IHighlights } from 'src/app/models/highlights.model';
import { IProduct } from 'src/app/models/product.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-product-highlights',
  templateUrl: './product-highlights.component.html',
  styleUrls: ['./product-highlights.component.scss'],
})
export class ProductHighlightsComponent {
  @Output() hideComponents = new EventEmitter();
  selectedProduct!: IProduct;
  showOverlay = false;
  selectedHighlight!: IHighlights;
  products: IProduct[] = [];
  selectedMediaArray!: IAsset[];
  highlights: IHighlights[] = [];
  cookieStatus!: string;
  productSubscription!: Subscription;

  constructor(public helperService: HelperService) {}

  ngOnInit(): void {
    this.getProduct();
  }

  private getProduct() {
    this.productSubscription = this.helperService.prodSubject.subscribe((product: IProduct) => {
      this.selectedProduct = product;
    });
  }

  getHighlightText(highlight: IHighlights) {
    if (highlight.key_benefit_text) return highlight.key_benefit_text;
    if (highlight.short_text) return highlight.short_text;
    else return highlight.title;
  }

  getHighlights() {
    // gets alls highlights that contains either a video or a relevant image
    return this.selectedProduct.highlights.filter((e) => {
      return e.assets.filter((v) => v.category.includes('feature-video') || v.category.includes('category.detail')).length > 0;
    });
  }

  openOverlay(highlight: IHighlights) {
    this.hideComponents.emit();
    this.selectedHighlight = highlight;
    this.showOverlay = true;
    this.helperService.updatedBackgroundBlur(true);
  }

  closeOverlay(): void {
    this.showOverlay = false;
    this.hideComponents.emit();
    this.helperService.updatedBackgroundBlur(false);
  }

  ngOnDestroy() {
    if (this.productSubscription) this.productSubscription.unsubscribe();
  }
}
