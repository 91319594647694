import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { ICategory } from 'src/app/models/category.model';
import { IHighlights } from 'src/app/models/highlights.model';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-special-slider',
  templateUrl: './special-slider.component.html',
  styleUrls: ['./special-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('activeSlide', [
      state(
        'active',
        style({
          transform: 'scale(1.3) translateY(-20px)',
        }),
      ),
      state(
        'inActive',
        style({
          transform: 'scale(0.65)',
        }),
      ),
      transition('active => inActive', [animate('0.25s')]),
      transition('inActive => active', [animate('0.25s')]),
    ]),
  ],
})
export class SpecialSliderComponent implements OnInit {
  // @Input() alignRight!: boolean;
  // @Input() allFeatures!: IHighlights[];
  // @Input() filteredForOverlay: IHighlights[] = [];
  // isLoading: boolean = false;
  // showMediaOverlay: boolean = false;
  // selectedHighlight!: IHighlights;
  // selectedHighlightArray!: IHighlights[];

  // @ViewChild(CarouselComponent)
  // private Carousel!: CarouselComponent;

  // constructor(public helperService: HelperService) {}

  // convertNumberToString(id: number) {
  //   return id.toString();
  // }

  ngOnInit(): void {}

  // getHighlights() {
  //   // gets alls highlights that contains either a video or a relevant image
  //   return this.allFeatures.filter((e) => {
  //     return e.assets.filter((v) => v.category.includes('feature-video') || v.category.includes('category.detail')).length > 0;
  //   });
  // }

  // openMediaOverlay(feature: IHighlights) {
  //   this.selectedHighlight = feature;
  //   this.showMediaOverlay = true;
  // }

  // closeOverlay(): void {
  //   this.showMediaOverlay = false;
  //   this.helperService.updatedBackgroundBlur(false);
  // }

  // getThumbnail(url: string) {
  //   return this.helperService.getThumbnail(url);
  // }

  // goToSlide(cat: ICategory) {
  //   //searches the right feature depending on the clicked category and goes to that slide
  //   // TODO Search for title aswell and go to that slide
  //   if (cat.endpointFilterId && cat.endpointFilterId.includes(',')) {
  //     let stringArr: Array<string> = cat.endpointFilterId.split(',');
  //     stringArr.forEach((el) => {
  //       let slide = this.allFeatures.find((e) => this.convertNumberToString(e.id) === el);
  //       if (slide) this.Carousel.to(this.convertNumberToString(slide.id));
  //     });
  //   } else if (cat.endpointFilterId) {
  //     let slide = this.allFeatures.find((e) => this.convertNumberToString(e.id) === cat.endpointFilterId);
  //     if (slide) this.Carousel.to(this.convertNumberToString(slide.id));
  //   }
  // }

  // customOptions: OwlOptions = {
  //   loop: true,
  //   mouseDrag: true,
  //   touchDrag: true,
  //   pullDrag: true,
  //   dots: false,
  //   margin: 10,
  //   items: 3,
  //   navSpeed: 700,
  //   center: true,
  //   navText: ['<img class="arrow" src="assets/icon/icon-arrow-left.svg"/>', '<img class="arrow" src="assets/icon/icon-arrow-right.gif"/>'],
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     400: {
  //       items: 2,
  //     },
  //     740: {
  //       items: 3,
  //     },
  //     940: {
  //       items: 3,
  //     },
  //   },
  //   nav: true,
  // };
}
