import { Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';
import { NetworkService } from 'src/app/services/network.service';
import SwiperCore, { Autoplay, EffectFade, Lazy, Navigation } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Lazy, EffectFade, Autoplay, Navigation]);

@Component({
  selector: 'app-screensaver',
  templateUrl: './screensaver.component.html',
  styleUrls: ['./screensaver.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScreensaverComponent implements OnDestroy {
  @ViewChild('screensaverSwiper') swiper!: SwiperComponent;
  @ViewChild('videoPlayer') videoPlayer!: ElementRef | undefined;
  public currentSlide = 0;
  public showVolumeState = false;
  public videoPlayerIsMuted = true;

  isOnline!: boolean;
  isOnlineSubscription: Subscription;
  timer: any;
  showConnectionError = false;

  // swiper lengths with unlimited-8-aktion    swiperLengths = [92_800, 10_000, 15_000, 36_000, 10_000, 15_000, 20_000, 10_000, 15_000, 36_000, 10_000, 15_000];
  swiperLengths = [92_800, 15_000, 36_000, 15_000, 20_000, 15_000, 36_000, 15_000];

  constructor(private router: Router, public helperService: HelperService, private networkService: NetworkService) {
    this.isOnlineSubscription = this.networkService.networkState.subscribe((isOnline: boolean) => (this.isOnline = isOnline));
  }

  onSwiper(): void {
    this.swiper.config = {
      allowTouchMove: false,
      effect: 'fade',
      autoplay: {
        delay: this.swiperLengths[0],
        disableOnInteraction: false,
      },
      speed: 1,
    };
    // Start autoplay
    this.swiper.swiperRef.autoplay.start();
  }

  onSlideChange(): void {
    this.currentSlide = this.swiper.swiperRef.activeIndex;
    // changes the time of the autoplay everytime a slide changes to match the lengths of individual videos
    this.swiper.config = {
      autoplay: {
        delay: this.swiperLengths[this.currentSlide],
      },
    };

    // if video is unmuted this will mute the video at each swipe change iand inform the user
    if (!this.videoPlayerIsMuted) {
      this.showVolumeState = true;
      this.videoPlayerIsMuted = true;
      if (this.videoPlayer) {
        this.videoPlayer.nativeElement.muted = this.videoPlayerIsMuted;
      }
      this.helperService.updateVideoMutedState(this.videoPlayerIsMuted);
      setTimeout(() => (this.showVolumeState = false), 1000);
    }
  }

  // use following function if there is 0 ... 10 slides like in unlimited 8 aktion
  // const cs = this.currentSlide + 1;
  // return cs % 3 === 0;
  isNotEvenSlide(): boolean {
    return this.currentSlide % 2 !== 0;
  }

  ngOnDestroy(): void {
    if (this.isOnlineSubscription) {
      this.isOnlineSubscription.unsubscribe();
    }
  }

  onClickCloseAnlockScreen(): void {
    if (this.isOnline) this.router.navigate(['/startseite']);
    else {
      clearTimeout(this.timer);
      this.showConnectionError = true;
      this.timer = setTimeout(() => {
        this.showConnectionError = false;
      }, 2000);
    }
  }

  onClickTurnSoundOnOff(): void {
    this.showVolumeState = true;
    this.videoPlayerIsMuted = !this.videoPlayerIsMuted;
    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.muted = this.videoPlayerIsMuted;
    }
    this.helperService.updateVideoMutedState(this.videoPlayerIsMuted);
    setTimeout(() => (this.showVolumeState = false), 1000);
  }
}
