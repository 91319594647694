import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { debounce } from 'lodash';
import { Subscription } from 'rxjs';
import Keyboard from 'simple-keyboard';
import { ICatApiResponse } from 'src/app/models/apiResponse.model';
import { ICategory } from 'src/app/models/category.model';
import { IProduct } from 'src/app/models/product.model';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-search-overlay',
  templateUrl: './search-overlay.component.html',
  styleUrls: ['./search-overlay.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchOverlayComponent {
  public categories: ICategory[] = [];

  public isSelectOpen = false;

  public keyboard!: Keyboard;
  public searchParam = '';
  public selectedCategory!: ICategory | null;
  public productsLength!: number;
  public isLoading = false;

  public products!: IProduct[];

  private subscriptions: Subscription = new Subscription();

  constructor(private router: Router, public helperService: HelperService, private apiService: ApiService, private cdRef: ChangeDetectorRef) {
    this.onInput = debounce(this.onInput, 1000);
    this.fetchCategories();
  }

  /**
   * Creates the virtual Keyboard and its layout + style
   */
  ngAfterViewInit() {
    if (!this.helperService.isMobileDevice) this.initKeyboard();

    const searchParams = JSON.parse(localStorage.getItem('search')!);
    if (searchParams && searchParams !== null) {
      this.searchParam = searchParams.searchParam;
      if (!this.helperService.isMobileDevice) this.keyboard.setInput(this.searchParam);
      this.selectedCategory = searchParams.category;
      this.getProductsBySearch();
    }
    this.cdRef.detectChanges();
  }

  public onInput(): void {
    if (this.searchParam.length > 2 || this.searchParam.length === 0) this.getProductsBySearch();
  }

  public toggleSelect() {
    this.isSelectOpen = !this.isSelectOpen;
  }

  public onSelectCategoryClick(category: ICategory) {
    this.isSelectOpen = !this.isSelectOpen;
    this.selectedCategory = category;
    this.getProductsBySearch();
  }

  private getProductsBySearch() {
    let searchUrl;
    if (this.selectedCategory) searchUrl = this.selectedCategory.searchUrl;

    this.subscriptions.add(
      this.apiService.getProductBySearchParams(this.searchParam, searchUrl).subscribe((res: ICatApiResponse) => {
        this.productsLength = res.data.length;
        this.products = res.data;
      }),
    );
  }

  private fetchCategories() {
    fetch('assets/data/categories.json')
      .then((response) => response.json())
      .then((data) => {
        const categories = data.categories;
        this.categories = categories.filter((category: { level: number }) => category.level === 0);
      })
      .catch((error) => console.error(error));
  }

  public getRotationStyle() {
    if (this.isSelectOpen) {
      return { transform: 'rotate(270deg)' };
    } else {
      return { transform: 'rotate(90deg)' };
    }
  }

  public onCloseSearchClick() {
    localStorage.removeItem('search');
    this.searchParam = '';
    this.helperService.showSearchOverlay = false;
  }

  public onShowResultsClick() {
    let bgUrl;
    let searchUrl;
    if (this.productsLength > 0) {
      const searchParams = {
        searchParam: this.searchParam,
        category: this.selectedCategory,
      };
      if (this.selectedCategory) searchUrl = this.selectedCategory.searchUrl;
      localStorage.setItem('search', JSON.stringify(searchParams));
      this.helperService.showSearchOverlay = false;
      if (this.selectedCategory) bgUrl = this.selectedCategory.id;

      this.router.navigateByUrl(`/suchergebnisse?search=${searchUrl}&query=${this.searchParam}&bgUrl=${bgUrl}`);
    }
  }

  public onShowAllProductsClick() {
    this.isSelectOpen = !this.isSelectOpen;
    this.selectedCategory = null;
    this.getProductsBySearch();
  }

  public resetInputs() {
    // resets all inputfields
    let checkbox = document.getElementById('check-box') as HTMLInputElement;
    this.searchParam = '';
    checkbox.classList.remove('error');
    checkbox.checked = false;

    document.getElementById('user-input')?.classList.remove('error-input');
  }

  /**
   * adds the typed charactes to the value variable
   * @param input of virtual keyboard
   */
  private onChange = (input: string) => {
    this.searchParam = input;
    this.onInput();
  };

  /**
   * If you want to handle the shift and caps lock buttons maybe later on
   * @param button pressed button
   */
  private onKeyPress = (button: string) => {
    if (button === '{shift}' || button === '{lock}') this.handleShift();
    /*     else if (button === '{bksp}' && this.resetInput) this.searchParam = '';
    else this.resetInput = false; */
  };

  /**
   * When the Input is changed by the virtual keyboard
   * @param event when the input changes
   */
  public onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.value);
    this.searchParam = event.target.value;
  };

  /**
   * Handles the Shift button interaction
   */
  private handleShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';
    this.keyboard.setOptions({
      layoutName: shiftToggle,
    });
  };

  initKeyboard() {
    this.keyboard = new Keyboard({
      onChange: (input) => this.onChange(input),
      onKeyPress: (button: any) => this.onKeyPress(button),
      theme: 'hg-theme-default myTheme1',
      layout: {
        default: [
          '^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}',
          '{tab} q w e r t z u i o p \u00FC +',
          '{lock} a s d f g h j k l \u00F6 \u00E4 # {enter}',
          '{shift} < y x c v b n m , . - {shift}',
          '.com @ {space}',
        ],
        shift: [
          '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
          '{tab} Q W E R T Z U I O P \u00DC *',
          "{lock} A S D F G H J K L \u00D6 \u00C4 ' {enter}",
          '{shift} > Y X C V B N M ; : _ {shift}',
          '.com @ {space}',
        ],
      },

      buttonTheme: [
        {
          class: 'bg-darker',
          buttons: '{tab} {lock} {shift} + {enter} {bksp} * .com @',
        },
        {
          class: 'shift',
          buttons: '{shift}',
        },
        {
          class: 'enter',
          buttons: '{enter}',
        },
        {
          class: 'caps',
          buttons: '{lock}',
        },
        {
          class: 'delete',
          buttons: '{bksp}',
        },
        {
          class: 'right-tab',
          buttons: '{tab}',
        },
      ],
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
