<app-menu [logoPath]="'assets/img/bosch_logo.png'"></app-menu>
<div
  [ngClass]="
    helperService.isLandscape
      ? 'pl-16 pt-32 w-3/5'
      : helperService.isMobile && !helperService.isTablet
      ? 'z-20 padding-mobile md:px-8 pt-8'
      : helperService.isTablet
      ? 'px-12'
      : helperService.isTabletLandscape
      ? 'px-16 pt-16'
      : 'px-16 pt-64'
  "
>
  <div class="scrollbar-hidden" [ngClass]="helperService.isLandscape ? 'feature-list-landscape' : 'feature-list'">
    <div *ngIf="!helperService.isMobile || helperService.isTabletLandscape || helperService.isTablet4k" class="mb-4 flex justify-end">
      <div class="close-icon-container">
        <img class="close-icon py-4" src="assets/icon/icon-close.png" (click)="onClickCloseOverlay()" />
      </div>
    </div>
    <div class="grid" *ngIf="selectedHighlight === undefined">
      <h3 [ngClass]="helperService.isMobile ? 'text-xs' : 'text-2xl'" class="text-white font-bold mb-10">
        Aktuell sind keine Daten zu deiner Auswahl vorhanden
      </h3>
    </div>
    <div [ngClass]="helperService.isMobile ? 'flex flex-col' : 'grid grid-cols-12'" *ngIf="selectedHighlight != undefined">
      <div class="col-start-1 col-end-4">
        <h3
          [ngClass]="helperService.isMobile ? 'text-xs mb-4' : helperService.isTablet ? 'text-base' : 'text-2xl mb-10'"
          class="text-white font-bold"
        >
          {{ selectedHighlight.headline }}
        </h3>
        <p [ngClass]="{ 'text-small mb-4': helperService.isMobile }" class="text-white">
          {{ getHighlightText() }}
        </p>
        <img class="feature-icon" [src]="getFeatureIcon()" />
      </div>
      <div *ngIf="helperService.isMobile" class="flex justify-end">
        <div class="close-icon-container">
          <img class="close-icon py-4" src="assets/icon/icon-close.png" (click)="onClickCloseOverlay()" />
        </div>
      </div>
      <ng-container *ngFor="let asset of helperService.getRightAsset(selectedHighlight)">
        <div
          *ngIf="asset.category.includes('video') || asset.category.includes('product-video')"
          [ngClass]="helperService.isTablet ? 'aspect-w-15 aspect-h-8' : 'aspect-w-16 aspect-h-9'"
          class="mb-6 col-start-5 col-end-13"
        >
          <app-cookie-video-overlay *ngIf="!helperService.isCollectingCookies && helperService.isMobileDevice"></app-cookie-video-overlay>
          <iframe
            *ngIf="helperService.isCollectingCookies"
            [src]="getEmbededUrl(asset.url) | safe: 'resourceUrl'"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            sandbox="allow-scripts allow-same-origin"
          ></iframe>
        </div>

        <div
          *ngIf="
            asset.category.includes('category.detail') ||
            asset.category.includes('product-image') ||
            asset.category.includes('illustration-drawing') ||
            asset.category.includes('food.mood.styling')
          "
          class="mb-6 col-start-5 col-end-13 aspect-w-16 aspect-h-9"
        >
          <img class="product-shot object-contain" src="{{ asset.url }}" alt="" />
        </div>
      </ng-container>
    </div>
    <div
      [ngClass]="helperService.isMobile ? 'grid-cols-1' : helperService.isTablet ? 'grid-cols-4' : 'grid-cols-12'"
      *ngIf="selectedHighlightArray != undefined && selectedHighlightArray.length > 1"
      class="grid"
    >
      <div
        [ngClass]="
          helperService.isLandscape
            ? 'col-start-7 col-end-11 mt-2'
            : helperService.isMobile && !helperService.isTablet
            ? 'col-start-2'
            : 'col-start-6 col-end-12'
        "
      >
        <app-highlights-slider
          (mediaChanged)="showThisMedia($event)"
          [selectedHighlightArray]="selectedHighlightArray"
          [selectedProduct]="selectedProduct"
        ></app-highlights-slider>
      </div>
    </div>
  </div>
</div>
