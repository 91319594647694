import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-video-world',
  templateUrl: './video-world.component.html',
  styleUrls: ['./video-world.component.scss'],
})
export class VideoWorldComponent implements OnInit {
  constructor(public helperService: HelperService) {}

  ngOnInit(): void {}
}
