import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IProduct } from 'src/app/models/product.model';
import { HelperService } from 'src/app/services/helper.service';
import { NetworkService } from 'src/app/services/network.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() logoPath: string = 'assets/img/bosch_logo.png';
  @Input() showBurger: boolean = false;
  @Input() showSearch: boolean = false;
  @Input() showShare: boolean = false;
  @Input() showBack: boolean = false;
  @Input() showHome: boolean = false;
  @Input() qrCodeLink: string = 'https://www.bosch-home.com/de/shop/';
  @Input() selectedProduct!: IProduct;
  blurredSub: Subscription;
  isBlurred: boolean = false;
  showRelatedProductOverlay: boolean = false;
  url: string = '';
  showPageList: boolean = false;
  isOnline!: boolean;
  isOnlineSub: Subscription;

  constructor(private networkService: NetworkService, public router: Router, private location: Location, public helperService: HelperService) {
    // blur background if a video is clicked
    this.blurredSub = this.helperService.backgroundBlur.subscribe((blurred: boolean) => {
      this.isBlurred = blurred;
    });
    this.isOnlineSub = this.networkService.networkState.subscribe((state) => (this.isOnline = state));
  }

  ngOnInit(): void {}

  onBurgerClick() {
    this.showPageList = !this.showPageList;
  }

  onHomeClick() {
    this.router.navigateByUrl('/startseite');
  }

  onBackClick() {
    if (this.router.url.includes('produktauswahl')) this.helperService.onCatBackClick('');
    else if (this.router.url.includes('bosch-entdecken')) {
      this.router.navigateByUrl('startseite');
    } else if (
      this.router.url.includes('sensortechnologie') ||
      this.router.url.includes('like-a-bosch') ||
      this.router.url.includes('sallys-highlights') ||
      this.router.url.includes('video-library')
    ) {
      this.router.navigateByUrl('bosch-entdecken');
    } else if (this.router.url.includes('suchergebnisse')) {
      const search = localStorage.getItem('search');
      if (search !== null) this.helperService.showSearchOverlay = true;
      else this.location.back();
    } else this.location.back();
  }

  onShareClick() {
    if (this.selectedProduct) this.url = 'https://www.bosch-home.com/de/produktliste/' + this.selectedProduct.vib;
    else if (!this.selectedProduct) this.url = this.getRoute();
    this.showRelatedProductOverlay = true;
    this.helperService.updatedBackgroundBlur(true);
  }

  navigateToShop() {
    if (this.selectedProduct) {
      const url = 'https://www.bosch-home.com/de/produktliste/' + this.selectedProduct.vib;
      window.open(url, '_blank');
    } else if (!this.selectedProduct) {
      const url = this.getRoute();
      window.open(url, '_blank');
    }
  }

  getRoute() {
    if (this.router.url === '/like-a-bosch') return environment.videoWorldUrls.likeABoschUrl;
    else if (this.router.url === '/sallys-highlights') return environment.videoWorldUrls.sallysHighlightsUrl;
    else if (this.router.url === '/vernetztes-zuhause') return environment.videoWorldUrls.homeConnectUrl;
    else if (this.router.url === '/sensortechnologie') return environment.videoWorldUrls.sensorTechnologieUrl;
    else return 'https://www.bosch-home.com/de/shop/';
  }

  closeRelatedProductOverlay(): void {
    this.showRelatedProductOverlay = false;
    this.helperService.updatedBackgroundBlur(false);
  }

  getEmptyMenuCols() {
    return Number(!this.showBurger) + Number(!this.showSearch) + Number(!this.showShare) + Number(!this.showBack) + Number(!this.showHome);
  }

  openSearchOverlay() {
    this.helperService.showSearchOverlay = true;
  }

  ngOnDestroy() {
    if (this.blurredSub) this.blurredSub.unsubscribe();
    if (this.isOnlineSub) this.isOnlineSub.unsubscribe();
  }
}
