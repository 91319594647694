<div
  [ngClass]="
    helperService.isMobile && !helperService.isTablet
      ? 'flex justify-center w-screen padding-mobile  pt-2'
      : helperService.isTablet
      ? 'padding-mobile md:px-8'
      : 'px-16 pt-6'
  "
>
  <div
    [ngClass]="helperService.isMobile && !helperService.isTablet ? 'flex-row relative w-full' : 'flex-row justify-end max-height-menu-row'"
    class="flex"
  >
    <div
      [ngClass]="{ 'mr-2': helperService.isMobile && !helperService.isTablet }"
      *ngIf="(!helperService.isMobile && !helperService.isTablet && !showMenuList) || helperService.isMobile || helperService.isTabletLandscape"
      class="current-menu-item-text bg-black position-relative center-content"
    >
      <p [ngClass]="helperService.isMobile ? 'text-base' : 'text-2xl'" class="text-white">{{ selectedMenuEntry }}</p>
    </div>
    <div
      *ngIf="showMenuList"
      [ngClass]="
        helperService.isMobile && !helperService.isTablet ? 'absolute w-full top-12' : helperService.isTabletLandscape ? 'absolute right-36' : ''
      "
      class="z-12 menu-list"
    >
      <ul class="bg-black relative z-12 text-white text-2xl productMenuList">
        <li *ngFor="let entry of menuList" (click)="navigateToSubpage(entry.route, entry.title)">
          <p
            [ngClass]="{ 'font-bold': activatedRoute.snapshot.routeConfig?.path?.includes(entry.route) }"
            class="block current-menu-item-text center-content"
          >
            {{ entry.title }}
          </p>
        </li>
      </ul>
    </div>
    <div
      [ngClass]="{ 'z-10 absolute right-0': helperService.isMobile && !helperService.isTablet }"
      class="icon-col bg-default-col-bg items-center justify-center"
      (click)="onClickShowMenu()"
    >
      <img
        *ngIf="showMenuList && (helperService.isMobile || helperService.isTablet4k || helperService.isTabletLandscape)"
        class="h-6 w-6"
        src="assets/icon/icon-close.png"
      />
      <img
        *ngIf="
          (!showMenuList && (helperService.isMobile || helperService.isTablet4k || helperService.isTabletLandscape)) ||
          (!helperService.isMobile && !helperService.isTablet4k && !helperService.isTabletLandscape)
        "
        class="menu-icon"
        src="assets/menuIcons/burgerMenu.svg"
      />
    </div>
  </div>
</div>
