import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import Keyboard from 'simple-keyboard';
import { IAccessories } from 'src/app/models/accessories.model';
import { IProduct } from 'src/app/models/product.model';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-related-products-overlay',
  templateUrl: './related-products-overlay.component.html',
  styleUrls: ['./related-products-overlay.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RelatedProductsOverlayComponent implements OnInit {
  @Output() closeRelatedProductOverlay = new EventEmitter();
  @Input() selectedProduct!: IProduct;
  @Input() selectedRelatedProduct!: IAccessories;
  @Input() url!: string;
  userMailInput: string = '';
  keyboard!: Keyboard;
  showKeyboard: boolean = false;
  isChecked: boolean = false;
  showError: boolean = false;
  showSuccess: boolean = false;
  qrCode: string = '';

  constructor(public router: Router, private apiService: ApiService, public helperService: HelperService) {}

  ngOnInit(): void {
    this.getQrCode();
  }

  toggleCheckbox() {
    this.isChecked = !this.isChecked;
  }

  sendLinkAsEmail() {
    // checks required fields and adds error highlighting if needed
    this.checkFields();

    if (this.isChecked && this.userMailInput.includes('@'))
      this.apiService.sendMail(this.userMailInput, this.qrCode).subscribe({
        next: () => {
          this.showSuccessMessage();
          this.resetInputs();
        },
        error: (err) => {
          console.log(err);
          this.resetInputs();
          this.showErrorMessage();
        },
      });
  }

  resetInputs() {
    // resets all inputfields
    let checkbox = document.getElementById('check-box') as HTMLInputElement;
    this.userMailInput = '';
    checkbox.classList.remove('error');
    checkbox.checked = false;
    this.isChecked = false;
    document.getElementById('user-input')?.classList.remove('error-input');
  }

  checkFields() {
    if (!this.isChecked) document.getElementById('check-box')?.classList.add('error');
    if (!this.userMailInput || !this.userMailInput.includes('@')) document.getElementById('user-input')?.classList.add('error-input');
    if (this.isChecked) document.getElementById('check-box')?.classList.remove('error');
    if (this.userMailInput && this.userMailInput.includes('@')) document.getElementById('user-input')?.classList.remove('error-input');
  }

  showSuccessMessage() {
    this.showSuccess = true;
    setTimeout(() => {
      this.showSuccess = false;
    }, 6000);
  }

  showErrorMessage() {
    this.showError = true;
    setTimeout(() => {
      this.showError = false;
    }, 6000);
  }

  onClickCloseRelatedOverlay() {
    this.closeRelatedProductOverlay.emit();
  }

  getQrCode() {
    // sets the link for the qr code
    if (this.selectedRelatedProduct && !this.url) this.qrCode = 'https://www.bosch-home.com/de/shop/' + this.selectedRelatedProduct.vib;
    else {
      switch (this.url) {
        // cases if user is on videoworld subpages
        case environment.videoWorldUrls.likeABoschUrl:
          this.qrCode = environment.videoWorldUrls.likeABoschUrl;
          break;
        case environment.videoWorldUrls.sallysHighlightsUrl:
          this.qrCode = environment.videoWorldUrls.sallysHighlightsUrl;
          break;
        case environment.videoWorldUrls.sensorTechnologieUrl:
          this.qrCode = environment.videoWorldUrls.sensorTechnologieUrl;
          break;
        case environment.videoWorldUrls.homeConnectUrl:
          this.qrCode = environment.videoWorldUrls.homeConnectUrl;
          break;
        default:
          // default return (link to selected Product) if user is on product-overview page
          this.qrCode = 'https://e-pos.bosch-home.com' + this.router.url;
      }
    }
  }

  /**
   * Creates the virtual Keyboard and its layout + style
   */
  ngAfterViewInit() {
    this.keyboard = new Keyboard({
      onChange: (input) => this.onChange(input),
      onKeyPress: (button: any) => this.onKeyPress(button),
      theme: 'hg-theme-default myTheme1',
      layout: {
        default: [
          '^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}',
          '{tab} q w e r t z u i o p \u00FC +',
          '{lock} a s d f g h j k l \u00F6 \u00E4 # {enter}',
          '{shift} < y x c v b n m , . - {shift}',
          '.com @ {space}',
        ],
        shift: [
          '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
          '{tab} Q W E R T Z U I O P \u00DC *',
          "{lock} A S D F G H J K L \u00D6 \u00C4 ' {enter}",
          '{shift} > Y X C V B N M ; : _ {shift}',
          '.com @ {space}',
        ],
      },

      buttonTheme: [
        {
          class: 'bg-darker',
          buttons: '{tab} {lock} {shift} + {enter} {bksp} * .com @',
        },
        {
          class: 'shift',
          buttons: '{shift}',
        },
        {
          class: 'enter',
          buttons: '{enter}',
        },
        {
          class: 'caps',
          buttons: '{lock}',
        },
        {
          class: 'delete',
          buttons: '{bksp}',
        },
        {
          class: 'right-tab',
          buttons: '{tab}',
        },
      ],
    });
  }

  /**
   * adds the typed charactes to the value variable
   * @param input of virtual keyboard
   */
  onChange = (input: string) => {
    this.userMailInput = input;
  };

  /**
   * If you want to handle the shift and caps lock buttons maybe later on
   * @param button pressed button
   */
  onKeyPress = (button: string) => {
    if (button === '{shift}' || button === '{lock}') this.handleShift();
  };

  /**
   * When the Input is changed by the virtual keyboard
   * @param event when the input changes
   */
  onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.value);
    this.userMailInput = event.target.value;
  };

  /**
   * Handles the Shift button interaction
   */
  handleShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';
    this.keyboard.setOptions({
      layoutName: shiftToggle,
    });
  };
}
