<div class="px-16 absolute inset-0 background-blur z-3" [ngClass]="helperService.isLandscape ? 'pt-20' : 'pt-64'">
  <div class="grid grid-cols-12 mt-4">
    <div class="close-icon-container" [ngClass]="helperService.isLandscape ? 'col-start-8' : 'col-start-13'">
      <img class="close-icon py-4" src="assets/icon/icon-close.png" (click)="onClickCloseRelatedOverlay()" />
    </div>
  </div>

  <div *ngIf="selectedRelatedProduct">
    <h3 class="text-white text-2xl mb-0">{{ selectedRelatedProduct.title }}</h3>
    <h3 class="text-white text-2xl font-bold" [ngClass]="helperService.isLandscape ? 'mb-8' : 'mb-16'">{{ selectedRelatedProduct.vib }}</h3>
  </div>

  <div class="grid grid-cols-12">
    <div class="col-start-1" [ngClass]="helperService.isLandscape ? 'col-end-4' : 'col-end-5'">
      <h2 class="text-white pb-16">QR-Code scannen</h2>
      <qrcode *ngIf="!helperService.is4k" [allowEmptyString]="true" [qrdata]="qrCode" [width]="250" [errorCorrectionLevel]="'M'"></qrcode>
      <qrcode *ngIf="helperService.is4k" [allowEmptyString]="true" [qrdata]="qrCode" [width]="500" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
    <div [ngClass]="helperService.isLandscape ? 'col-start-4 col-end-4' : 'col-start-6 col-end-8'">
      <h2 class="text-white pb-8">oder</h2>
    </div>
    <div [ngClass]="helperService.isLandscape ? 'col-start-5 col-end-8' : 'col-start-8 col-end-13'">
      <h2 class="text-white pb-8">per E-Mail versenden</h2>
      <input
        (click)="showKeyboard = !showKeyboard"
        (input)="onInputChange($event)"
        value="{{ userMailInput }}"
        id="user-input"
        type="email"
        name="noac"
        autocomplete="new-noac"
        placeholder="E-Mail-Adresse"
        class="input email-input mb-8 py-2 text-white bg-transparent focus:outline-none block w-full form-control"
      />

      <div class="flex">
        <div class="checkbox-wrap">
          <input (click)="toggleCheckbox()" class="h-8 width-full" type="checkbox" id="check-box" value="" />
        </div>
        <label class="form-check-label text-xs inline-block text-white pr-4" for="flexCheckDefault">
          Wir benötigen deine E-Mail-Adresse zum Versand der von dir gewünschten Produktinformationen. Deine E-Mail-Adresse wird ausschließlich zum
          Versand des Produkt-Links verwendet. Anschließend wird die E-Mail-Adresse unverzüglich aus unserem System gelöscht. Die Datenverarbeitung
          ist zur primären Erbringung des Dienstes erforderlich und rechtfertigt sich daher durch Art. 6 Abs. 1, S. 1 b) DSGVO.
        </label>
      </div>
      <div class="flex justify-center mt-8">
        <a class="bg-default-col-bg px-16 py-5 text-2xl text-white decoration-none" (click)="sendLinkAsEmail()">Link versenden</a>
      </div>
      <div *ngIf="showError" class="text-white text-xl p-4 mt-4 text-center">Beim Senden des Links ist ein Fehler aufgetreten.</div>
      <div *ngIf="showSuccess" class="text-white text-xl p-4 mt-4 text-center">
        Der Link wurde erfolgreich per <br />
        E-Mail geschickt.
      </div>
    </div>
  </div>

  <div [ngClass]="showKeyboard ? 'show' : 'hide'">
    <div [ngClass]="helperService.isLandscape ? 'mt-8 w-3/5' : 'mt-32'">
      <div class="simple-keyboard"></div>
    </div>
  </div>
</div>
