import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-screensaver-notice',
  templateUrl: './screensaver-notice.component.html',
  styleUrls: ['./screensaver-notice.component.scss'],
})
export class ScreensaverNoticeComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {}
}
